import * as React from "react";
import * as style from "./reportModalBody.module.scss";
import { CompetencyScore } from "src/components/CompetencyScore/CompetencyScore";
import { ProgressBarDescription } from "src/components/ProgressBarDescription/ProgressBarDescription";
import { useSpring, animated } from "react-spring";
import { observer } from "mobx-react";
import { useState } from "react";

interface Competency {
  competency_name: string;
  competency_score: number;
  competency_description: string;
}

interface CompetencyGroup {
  competency_group_name: string;
  competency_group_score?: number;
  competencies: Competency[];
}

interface ReportModalBodyProps {
  data: CompetencyGroup[];
  isOpen: boolean;
  onClick: () => void;
}

export const ReportModalBody: React.FC<ReportModalBodyProps> = observer(
  props => {
    const [activeScore, setActiveScore] = useState<number>();

    return (
      <div className={`${style.body}`}>
        <div className={`${style.title} pb-2 d-flex justify-content-center`}>
          {!props.isOpen && `Detail Scores`}
        </div>
        <div className={`d-flex justify-content-around`}>
          {props.data &&
            props.data.map((item: CompetencyGroup, key) => {
              return (
                <CompetencyScore
                  key={key}
                  name={item.competency_group_name}
                  score={item.competency_group_score}
                  active={activeScore === key}
                  onClick={() => {
                    props.onClick();
                    setActiveScore(props.isOpen ? undefined : key);
                  }}
                />
              );
            })}
        </div>
        <div className={`pt-3`}>
          {props.data &&
            props.data.map(item => {
              return (
                item.competencies &&
                item.competencies.map((competency: Competency, key: number) => {
                  return (
                    <animated.div className={style.description} key={key}>
                      <ProgressBarDescription
                        title={competency.competency_name}
                        primaryNumber={competency.competency_score}
                        secondaryNumber={10}
                      >
                        {competency.competency_description}
                      </ProgressBarDescription>
                    </animated.div>
                  );
                })
              );
            })}
        </div>
      </div>
    );
  }
);
