import * as React from "react";
import * as style from "./businessUserPageBase.module.scss";
import { Switch } from "react-router-dom";
import { PageBase } from "src/components/PageBase/PageBase";
import {Route, RouteInstance} from "src/components/WithRouterStore/Route";
import { NavigationMenuData } from "src/components/SideNavigation/NavigationMenu";
import { isCurrentRouteWindow } from "src/utils";
import {
  CANDIDATE_DASHBOARD,
  CANDIDATE_TEST,
  CANDIDATE_REGISTER
} from "src/container/Candidate/constants/candidateRoutes";
import { CandidateRegistration } from "src/container/Candidate/components/RegistrationPage/CandidateRegistration";
import { ProtectedRoutes } from "src/components/Login/ProtectedRoutes";
import { CandidateDashboardPage } from "src/container/Candidate/components/CandidateDashboardPage/CandidateDashboardPage";
import { MyTestPage } from "src/container/Candidate/components/MyTestPage/MyTestPage";
import { permissionApiStore } from "src/components/Login/stores/permissionApiStore";
import { commonNavigation } from "src/constants/commonNavigation";
import { permissionStore } from "src/stores/PermissionStore";
import { routerStore } from "src/stores/RouterStore";
import * as _ from "lodash";
import { observer } from "mobx-react";
import { BUSINESS_USER_DASHBOARD } from "src/container/BusinessUser/constants/businessUserRoutes";

interface BusinessUserPageBaseProps {}

export const CandidatePageBase: React.FC<BusinessUserPageBaseProps> = observer(
  props => {
    const menuItems: NavigationMenuData[] = [
      {
        title: `My Test`,
        path: CANDIDATE_DASHBOARD
      }
    ];

    const bottomItems: NavigationMenuData[] = [
      (permissionStore.isBusinessUser || permissionStore.isSuperAdmin) && {
        title: `Switch to Business`,
        onClick: () => routerStore.push(BUSINESS_USER_DASHBOARD)
      }
    ].filter(item => item);

    return (
      <PageBase
        menuItems={menuItems}
        showSidebar={!isCurrentRouteWindow([CANDIDATE_REGISTER])}
        profileData={{
          name: _.get(permissionApiStore.data, "user.full_name", ""),
          imageUrl: _.get(
            permissionApiStore.data,
            "user.candidate_profile.profile_image_url",
            undefined
          )
        }}
        bottomMenuItems={bottomItems.concat(commonNavigation)}
        onLogoClick={permissionStore.redirectAccordingToRole}
      >
        <Switch>
          <Route
            path={[CANDIDATE_REGISTER]}
            component={CandidateRegistration}
            exact
          />
          <ProtectedRoutes>
            <RouteInstance
              path={[CANDIDATE_DASHBOARD]}
              component={CandidateDashboardPage}
              exact
            />
            <RouteInstance path={[CANDIDATE_TEST]} component={MyTestPage} exact />
          </ProtectedRoutes>
        </Switch>
      </PageBase>
    );
  }
);
