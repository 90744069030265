import * as React from "react";
import * as style from "./competencyScore.module.scss";
import { Button } from "src/components/Button/Button";

export interface CompetencyScoreProps {
  active: boolean;
  score: number;
  name: string;
  onClick?: () => void;
}

export const CompetencyScore: React.FC<CompetencyScoreProps> = props => {
  const { active, score, name, onClick } = props;
  return (
    <div className={`d-flex`}>
      <div className={style.competencyScore}>
        <div className={`d-flex justify-content-center`}>
          <div
            className={
              active ? `${style.score} ${style.redBorder}` : style.score
            }
          >
            <span className={style.scoreNumber}>{score ? score : "-"}</span>
          </div>
        </div>
        <div className={`py-2 ${style.name}`}>{name}</div>
        <div
          className={`d-flex justify-content-center ${style.buttonContainer}`}
        >
          <Button
            disabled={score <= 0}
            className={style.button}
            onClick={onClick}
          >
            {active ? "View Less" : "View More"}
          </Button>
        </div>
      </div>
    </div>
  );
};
