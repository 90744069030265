import * as React from "react";
import * as style from "./reportModalBase.module.scss";
import {
  Modal as RsModal,
  ModalBody,
  ModalProps as RsModalProps
} from "reactstrap";
import { useSpring, animated } from "react-spring";
import { observer } from "mobx-react";
import { useRef } from "react";
import { ResponsiveSize, useResolution } from "src/utils/responsive";

interface ReportModalBaseProps extends RsModalProps {
  viewMore: boolean;
}

export const ReportModalBase: React.FC<ReportModalBaseProps> = observer(
  props => {
    const {
      viewMore,
      toggle,
      header,
      footer,
      children,
      cancelBtnLabel = "Cancel",
      actionBtnLabel = "Save",
      actionBtnOnClick,
      actionBtnProps,
      cancelBtnOnClick,
      ...modalProps
    } = props;


    const openAnimation = useSpring({
      transform: viewMore
        ? `translateY(calc(0 - -45px))`
        : `translateY(calc(90vh - 220px))`,
      overflow: viewMore ? "auto" : "hidden"
    });

    return (
      <RsModal
        {...modalProps}
        toggle={toggle}
        modalClassName={`${style.reportModal} ${props.className}`}
      >
        <div className={style.modalHeader}>
          <div className={style.headerContent}>{header}</div>
        </div>
        <animated.div style={openAnimation} className={style.modalBody}>
          <ModalBody>{children}</ModalBody>
        </animated.div>
      </RsModal>
    );
  }
);
