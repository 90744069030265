import { FormStore } from "src/stores/FormStore";
import { SelectOption } from "src/_declare/select";

interface CompanyDetailsFormStoreData {
  name: string;
  company_type: SelectOption;
  industry: SelectOption;
  website_url: string;
  company_size: string;
  address_1: string;
  address_2: string;
  city: SelectOption;
  state: SelectOption;
  country: SelectOption;
  postcode: string;
}

export const companyDetailsFormStore = new FormStore<
  CompanyDetailsFormStoreData
>(
  {
    name: {
      placeholder: "Registered Name",
      rules: "string|required",
      bindings: "extended"
    },
    company_type: {
      placeholder: "Company Type",
      rules: "",
      bindings: "extended"
    },
    industry: {
      placeholder: "Industry",
      rules: "",
      bindings: "extended"
    },
    website_url: {
      placeholder: "Website URL",
      rules: "",
      bindings: "extended"
    },
    company_size: {
      placeholder: "Company Size",
      rules: "numeric",
      value: 100,
      bindings: "extended"
    },
    address_1: {
      placeholder: "Unit & Building",
      rules: "string",
      bindings: "extended"
    },
    address_2: {
      placeholder: "Street Line",
      rules: "string",
      bindings: "extended"
    },
    city: {
      placeholder: "Town/City",
      bindings: "extended"
    },
    state: {
      placeholder: "State",
      rules: "",
      bindings: "extended"
    },
    country: {
      placeholder: "Country",
      rules: "",
      bindings: "extended"
    },
    postcode: {
      placeholder: "Postcode",
      rules: "numeric",
      type: "tel",
      bindings: "extended"
    }
  },
  {
    options: {
      validateOnChange: true
    },
    hooks: {
      onSuccess: async (form: FormStore) => {
        const values: CompanyDetailsFormStoreData = form.values();
        try {
          form.reInit();
        } catch (e) {
          console.error(`CompanyDetailsFormStore Api Failed: `, e);
          return Promise.reject(`Error calling api`);
        }

        return Promise.resolve();
      },
      onError: form => {
        return Promise.reject(`Incorrect form information`);
      }
    }
  }
);
