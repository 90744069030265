import * as React from "react";
import * as style from "./componentsHomepage.module.scss";
import { map as lodashMap } from "lodash";
import { useEffect, useRef, useState } from "react";
import { Button } from "src/components/Button/Button";
import { ComponentPreview } from "src/components/ComponentPreview/ComponentPreview";
import { CheckBox } from "src/components/CheckBox/CheckBox";
import { SelectExample } from "src/components/Select/SelectDemo";
import { Modal } from "src/components/Modal/Modal";
import { Input } from "src/components/Input/Input";
import { PageBase } from "src/components/PageBase/PageBase";
import { Card } from "src/components/Card/Card";
import { Slider } from "src/components/Slider/Slider";
import { SideNavigationUsage } from "src/components/SideNavigation/SideNavigationUsage";
import { ToggleSwitchExample } from "src/components/ToggleSwitch/ToggleSwitchExample";
import { WaveProgressBar } from "src/components/WaveProgressBar/WaveProgressBar";
import { CardWithProgressBar } from "src/components/Card/CardWithProgressBar";
import { TableDemo } from "src/components/Table/TableDemo";
import { DropdownDemo } from "src/components/Dropdown/DropdownDemo";
import { UploadImageSample } from "src/components/UploadImage/UploadImageSample/UploadImageSample";
import { Message } from "src/components/Message/Message";
import { CandidateProgressBar } from "src/components/CandidateProgressBar/CandidateProgressBar";
import { BreadcrumbDemo } from "src/components/Breadcrumb/BreadcrumbDemo";
import { TotalScore } from "src/components/TotalScore/TotalScore";
import { ProgressBarDescription } from "src/components/ProgressBarDescription/ProgressBarDescription";
import { CompetencyScore } from "src/components/CompetencyScore/CompetencyScore";
import {ReportModal} from "src/components/ReportModal/ReportModal";

export const ComponentsHomepage: React.FC = () => {
  const homePageContainerRef = useRef() as any;
  const [sidebarContentElement, setSidebarContentElement] = useState();
  const [modalIsOpen, toggleModalIsOpen] = React.useState(false);
  const [reportModalIsOpen, toggleReportModalIsOpen] = React.useState(false);

  useEffect(() => {
    setSidebarContentElement(
      lodashMap(
        homePageContainerRef.current.children,
        (child: HTMLElement | any) => {
          if (child.tagName === "SPAN" && child.id) {
            return (
              <div className={style.leftNameItem}>
                <a href={`#${child.id}`}>{child.outerText}</a>
              </div>
            );
          }
        }
      )
    );
  }, [homePageContainerRef.current]);

  const candidateStatus = [
    { status: "New", complete: true },
    { status: "InProgress", complete: true },
    { status: "Completed", complete: false }
  ];


  return (
    <PageBase showSidebar={false}>
      <div className={style.homepageContainer}>
        <div
          className={`${style.homepageContent} container`}
          ref={homePageContainerRef}
        >
          {/**** Report Modal ****/}
          <ComponentPreview
            title={"Report Modal"}
            code={"p-common/src/components/ReportModal/ReportModal.tsx"}
            id={"reportModal"}
          >
            <Button
              className={`mx-1`}
              color={"primary"}
              onClick={() => toggleReportModalIsOpen(true)}
            >
              Click to open Report Modal
            </Button>
            <ReportModal candidate_id={1} isOpen={reportModalIsOpen} toggle={() => toggleReportModalIsOpen(false)} />
          </ComponentPreview>

          {/**** Competency Score ****/}
          <ComponentPreview
            title={"Competency Score"}
            code={"p-common/src/components/CompetencyScore/CompetencyScore.tsx"}
            id={"competencyScore"}
          >
            <CompetencyScore
              active={true}
              score={8}
              name={"Role Personality Fit"}
            />
          </ComponentPreview>

          {/**** ProgressBar Description ****/}
          <ComponentPreview
            title={"ProgressBar Description"}
            code={
              "p-common/src/components/ProgressBar/ProgressBarDescription.tsx"
            }
            id={"progressBarDescription"}
          >
            <ProgressBarDescription
              title={"Testing Progress"}
              primaryNumber={7}
              secondaryNumber={10}
            >
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna
            </ProgressBarDescription>
          </ComponentPreview>

          {/**** Total Score ****/}
          <ComponentPreview
            title={"Total Score"}
            code={"p-common/src/components/TotalScore/TotalScore.tsx"}
            id={"totalScore"}
          >
            <TotalScore primaryNumber={8} secondaryNumber={10} />
          </ComponentPreview>

          {/**** Breadcrumb ****/}
          <ComponentPreview
            title={"Breadcrumb"}
            code={"p-common/src/components/Breadcrumb/Breadcrumb.tsx"}
            id={"breadcrumb"}
            cardClassName={style.darkBg}
          >
            <BreadcrumbDemo />
          </ComponentPreview>

          {/**** Upload Image ****/}
          <ComponentPreview
            title={"Upload Image"}
            code={"p-common/src/components/UploadImage/UploadImage.tsx"}
            id={"uploadImage"}
          >
            <UploadImageSample />
          </ComponentPreview>

          {/**** Card With Progress Bar ****/}
          <ComponentPreview
            title={"Card With Progress Bar"}
            code={"p-common/src/components/Card/CardWithProgressBar.tsx"}
            id={"cardWithProgressBar"}
            showCard={false}
          >
            <CardWithProgressBar
              progressValue={25}
              progressText={`Step 2 of 4`}
            >
              Here goes the rest of the content
            </CardWithProgressBar>
          </ComponentPreview>

          {/**** Wave Progress Bar ****/}
          <ComponentPreview
            title={"Wave Progress Bar"}
            code={"p-common/src/components/WaveProgressBar/WaveProgressBar.tsx"}
            id={"waveProgressBar"}
          >
            <WaveProgressBar
              activeNumber={10}
              secondaryNumber={20}
              size={140}
            />
            <WaveProgressBar
              activeNumber={10}
              secondaryNumber={30}
              size={120}
              color={"secondary"}
            />
          </ComponentPreview>

          {/**** Buttons ****/}
          <ComponentPreview
            title={"Buttons"}
            code={"p-common/src/components/Button/Button.tsx"}
            id={"buttons"}
          >
            <div className={`d-flex`}>
              <Button className={`mx-1`} color={"primary"}>
                Button Text
              </Button>
              <Button className={`mx-1`} color={"secondary"}>
                Button Text
              </Button>
              <Button className={`mx-1`} color={"transparent"}>
                Button Text
              </Button>
            </div>
          </ComponentPreview>

          {/**** CheckBox ****/}
          <ComponentPreview
            title={"CheckBox"}
            code={"p-common/src/components/CheckBox/CheckBox.tsx"}
            id={"checkbox"}
          >
            <CheckBox label={"CheckBox"} />
          </ComponentPreview>

          {/**** Select ****/}
          <ComponentPreview
            title={"Select"}
            code={"p-common/src/components/Select/Select.tsx"}
            id={"select"}
          >
            <SelectExample />
          </ComponentPreview>

          {/**** Input ****/}
          <ComponentPreview
            title={"Input"}
            code={"p-common/src/components/Input/Input.tsx"}
            id={"input"}
          >
            <Input label={"Input Label"} placeholder={"Input Label"} />
            <Input
              label={"Input Type Password"}
              placeholder={"Input Label Password"}
              type={"password"}
              rightAppendAddon={<span>Forget Password</span>}
            />
          </ComponentPreview>
          {/**** Modal ****/}
          <ComponentPreview
            title={"Modal"}
            code={"p-common/src/components/Modal/Modal.tsx"}
            id={"modal"}
          >
            <Button
              className={`mx-1`}
              color={"primary"}
              onClick={() => toggleModalIsOpen(true)}
            >
              Click to open Modal
            </Button>
            <Modal
              header={"Modal Testing"}
              footer
              isOpen={modalIsOpen}
              toggle={() => toggleModalIsOpen(false)}
            >
              <p>Just some random text</p>
            </Modal>
          </ComponentPreview>

          {/**** Message ****/}
          <ComponentPreview
            title={"Message"}
            code={"p-common/src/components/Message/Message.tsx"}
            id={"message"}
          >
            <Button
              className={`mx-1`}
              color={"primary"}
              onClick={() => Message.success("Success Message")}
            >
              Success Status
            </Button>
            <Button
              className={`mx-1`}
              color={"primary"}
              onClick={() => Message.info("Info Message")}
            >
              Info Status
            </Button>
            <Button
              className={`mx-1`}
              color={"primary"}
              onClick={() => Message.error("Error Message")}
            >
              Error Status
            </Button>
            <Button
              className={`mx-1`}
              color={"primary"}
              onClick={() => Message.warning("Warning Message")}
            >
              Warning Status
            </Button>
          </ComponentPreview>

          {/**** Slider ****/}
          <ComponentPreview
            title={"Slider"}
            code={"p-common/src/components/Slider/Slider.tsx"}
            id={"slider"}
          >
            <Slider domain={[0, 12]} values={[12]} showHandleValue={true} />
          </ComponentPreview>

          {/**** Side Navigation ****/}
          <ComponentPreview
            title={"Side Navigation"}
            code={"p-common/src/components/SideNavigation/SideNavigation.tsx"}
            id={"sideNavigation"}
            showCard={false}
          >
            <SideNavigationUsage />
          </ComponentPreview>

          {/**** Toggle Switch ****/}
          <ComponentPreview
            title={"Toggle Switch"}
            code={"p-common/src/components/ToggleSwitch/ToggleSwitch.tsx"}
            id={"toggleSwitch"}
          >
            <ToggleSwitchExample />
          </ComponentPreview>

          {/**** DropDown ****/}
          <ComponentPreview
            title={"Dropdown"}
            code={"p-common/src/components/Dropdown/DropdownDemo.tsx"}
            id={"dropdown"}
          >
            <DropdownDemo />
          </ComponentPreview>

          {/**** Table ****/}
          <ComponentPreview
            title={"Table"}
            code={"p-common/src/components/Table/TableDemo.tsx"}
            id={"table"}
          >
            <TableDemo />
          </ComponentPreview>

          {/**** Candidate Progress ****/}
          <ComponentPreview
            title={"Candidate Progress"}
            code={"p-common/src/components/Progress/CandidateProgress.tsx"}
            id={"candidateProgress"}
          >
            <CandidateProgressBar candidateStatus={candidateStatus} />
          </ComponentPreview>
        </div>

        {/**** Right  Nav ****/}
        <div className={style.leftNav}>
          <span className={style.title}>Components</span>
          <Card className={`flex-column mt-2`}>{sidebarContentElement}</Card>
        </div>
      </div>
    </PageBase>
  );
};
