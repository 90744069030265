import * as React from "react";
import * as style from "./previewBulkCandidateTable.module.scss";
import * as _ from "lodash";
import { observer } from "mobx-react-lite";
import { TableBase, TableProps } from "src/components/Table/TableBase";
import { neutralizeWord } from "src/utils";
import { TextOverflowTooltip } from "src/components/TextOverflowTooltip/TextOverflowTooltip";
import { uploadCSVFormStore } from "src/container/BusinessUser/components/CandidatePage/stores/uploadCSVFormStore";

interface PreviewBulkCandidateTableProps extends Partial<TableProps> {}

export enum PreviewBulkCandidateTableAccessor {
  FIRST_NAME = "first_name",
  LAST_NAME = "last_name",
  EMAIL = "email"
}

export const PreviewBulkCandidateTable: React.FC<PreviewBulkCandidateTableProps> = observer(
  props => {
    const mappedColumns = _.map(
      Object.values(PreviewBulkCandidateTableAccessor),
      (value, key) => {
        return {
          Header: neutralizeWord(value),
          accessor: value,
          minWidth: 80,
          Cell: row => {
            return (
              <TextOverflowTooltip>{row.value || "-"}</TextOverflowTooltip>
            );
          }
        };
      }
    ) as any[];

    const previewCandidateTableColumns = [
      {
        Header: "No",
        Cell: ({ index }) => index + 1,
        minWidth: 30,
        sortable: true,
        resizable: false
      }
    ].concat(mappedColumns);

    const tableData = uploadCSVFormStore.getParsedContentData as PreviewBulkCandidateTableAccessor[];
    const { ...otherProps } = props;
    return (
      <div>
        <TableBase
          data={tableData}
          columns={previewCandidateTableColumns}
          tableName={"Candidate"}
          containerClassName={style.tableStyle}
          tableTotalNumber={tableData.length}
          pageSize={tableData.length <= 20 ? tableData.length : 20}
          {...otherProps}
        />
      </div>
    );
  }
);
