import { FormStore } from "src/stores/FormStore";

interface ResumeUploadFormStoreData {
  resume: any;
}

export const resumeUploadFormStore = new FormStore<ResumeUploadFormStoreData>(
  {
    resume: {
      rules: "",
      bindings: "extended"
    }
  },
  {
    options: {
      validateOnChange: true
    },
    hooks: {
      onSuccess: async (form: FormStore) => {
        const values: ResumeUploadFormStoreData = form.values();
        try {
          form.reInit();
        } catch (e) {
          console.error(`Resume Upload Api Failed: `, e);
          return Promise.reject(`Error calling api`);
        }

        return Promise.resolve();
      },
      onError: form => {
        return Promise.reject(`Incorrect form information`);
      }
    }
  }
);
