import * as React from "react";
import * as style from "./componentPreview.module.scss";
import { Card } from "src/components/Card/Card";

interface ComponentPreviewProps {
  title: string;
  code: string;
  id: string;
  showCard?: boolean;
  cardClassName?: string;
}

export const ComponentPreview: React.FC<ComponentPreviewProps> = props => {
  const { title, code, id, children, cardClassName, showCard = true } = props;
  return (
    <>
      <span className={style.title} id={id}>
        {title}
      </span>
      <code className={style.code}>{code}</code>
      <div className={style.panel}>
        {showCard ? (
          <Card className={`${style.card} ${cardClassName}`}>{children}</Card>
        ) : (
          children
        )}
      </div>
    </>
  );
};
