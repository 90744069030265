import * as React from "react";
import * as style from "./previewBulkCandidateUploadModal.module.scss";
import * as _ from "lodash";
import { observer } from "mobx-react-lite";
import { Modal, ModalProps } from "src/components/Modal/Modal";
import { Message } from "src/components/Message/Message";
import {
  PreviewBulkCandidateTable,
  PreviewBulkCandidateTableAccessor
} from "src/container/BusinessUser/components/CandidatePage/PreviewBulkCSVModal/PreviewBulkCandidateTable";
import { uploadCSVFormStore } from "src/container/BusinessUser/components/CandidatePage/stores/uploadCSVFormStore";
import { uploadCsvCandidateBulkApiStore } from "src/container/BusinessUser/components/CandidatePage/AddCandidate/stores/uploadCSVCandidateBulkApiStore";

interface PreviewBulkCandidateUploadModalProps extends ModalProps {
  apiStore?: () => void;
  addCandidateSideBarIsOpen?: () => void;
}

export const PreviewBulkCandidateUploadModal: React.FC<PreviewBulkCandidateUploadModalProps> = observer(
  props => {
    const { apiStore, addCandidateSideBarIsOpen, ...otherProps } = props;

    const onClickUpload = async () => {
      try {
        await uploadCSVFormStore.submit();
        Message.success(
          `Successfully uploaded ${
            (uploadCSVFormStore.getParsedContentData as any[]).length
          } candidates`
        );
        props.toggle();
        apiStore();
        addCandidateSideBarIsOpen();
      } catch (e) {
        Message.error(e);
      }
    };
    const tableData = uploadCSVFormStore.getParsedContentData as PreviewBulkCandidateTableAccessor[];

    return (
      <div>
        <Modal
          {...otherProps}
          header={`Candidate Bulk Upload Preview`}
          footer={true}
          className={style.modalStyle}
          actionBtnOnClick={onClickUpload}
          actionBtnLabel={"Upload"}
          actionBtnProps={{
            disabled:
              uploadCsvCandidateBulkApiStore.isLoading ||
              tableData.length === 0,
            loading: uploadCsvCandidateBulkApiStore.isLoading
          }}
          style={{
            minWidth: "50vw"
          }}
        >
          <div className={style.previewBulkCandidateModal}>
            <div>{`The below are the preview of the candidates that will be uploaded.`}</div>
            <div>
              <PreviewBulkCandidateTable />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
);
