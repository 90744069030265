import * as React from "react";
import * as _ from "lodash";
import { useEffect, useState } from "react";
import * as style from "./tableBase.module.scss";
import { TableBase, TableProps } from "src/components/Table/TableBase";

export interface TableWithCheckboxProps extends TableProps {
  checkAccessor?: string;
  getCheckedValues?: (checkedValues: any[]) => void;
}

export const TableWithCheckboxProps = undefined; // Haxx https://stackoverflow.com/a/52260432/6319503

export const TableWithCheckbox: React.FC<TableWithCheckboxProps> = props => {
  const [selected, setSelected] = useState<any[]>([]);
  const [selectAll, setSelectAll] = useState<number>(0);

  useEffect(() => {
    if (_.isEmpty(selected)) {
      setSelectAll(0);
    } else if (
      (props.tableTotalNumber ? props.tableTotalNumber : props.data.length) ===
      selected.length
    ) {
      setSelectAll(1);
    } else {
      setSelectAll(2);
    }

    if (props.checkAccessor) {
      const selectedOriginalData = _.filter(props.data, item => {
        return selected.includes(item[props.checkAccessor]);
      });
      props.getCheckedValues(selectedOriginalData);
    }
  }, [selected]);

  const toggleRow = original => {
    let newSelected = [...selected];

    if (selected.includes(original[props.checkAccessor])) {
      newSelected = _.remove(newSelected, item => {
        return item !== original[props.checkAccessor];
      });
    } else {
      newSelected.push(original[props.checkAccessor]);
    }
    setSelected(newSelected);
    setSelectAll(2);
  };

  const toggleSelectAll = () => {
    let newSelected = [];

    if (selectAll === 0) {
      props.data.forEach(x => {
        newSelected.push(x[props.checkAccessor]);
      });
    } else {
      const dataAccessor = props.data.map(item => item[props.checkAccessor]);

      const isEqual = dataAccessor.every(v => selected.includes(v));

      if (!isEqual) {
        newSelected = [...selected];
        props.data.forEach(x => {
          newSelected.push(x[props.checkAccessor]);
        });
      } else {
        const difference = _.difference(selected, dataAccessor);
        newSelected = difference;
      }
    }
    setSelected(newSelected);
  };

  const { checkAccessor, getCheckedValues, ...otherProps } = props;
  return (
    <TableBase
      {...otherProps}
      showCheckbox={!!(props.checkAccessor && props.getCheckedValues)}
      checkboxRowChecked={original =>
        selected.includes(original[props.checkAccessor])
      }
      checkboxRowOnChange={original => toggleRow(original)}
      checkboxSelectAllChecked={selectAll === 1}
      checkboxSelectAllOnChange={() => toggleSelectAll()}
      checkboxSelectAllIndeterminate={selectAll === 2}
      checkedCount={selected.length}
      containerClassName={`${!props.resizable &&
        style.checkboxTableResizable} ${style.checkboxTable}`}
    />
  );
};
