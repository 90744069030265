import * as React from "react";
import * as style from "./pagination.module.scss";
import { TableProps } from "src/components/Table/TableBase";
import { Pagination } from "src/components/Pagination/Pagination";

interface PaginationWithFooterProps extends TableProps {}

export const PaginationWithFooter: React.FC<PaginationWithFooterProps> = props => {
  const renderSelectedText = () => {
    if (props.checkedCount) {
      return (
        <div className={`d-flex`}>
          <div className={`${style.selectedText}`}>Selected:</div>
          <div className={`${style.titleStyle} ml-2`}>{props.checkedCount}</div>
        </div>
      );
    }
  };
  return (
    <div>
      <div
        className={`${style.paginationContainer} justify-content-between align-items-end w-100`}
      >
        <div className={`d-flex ${style.paginationTotal} align-items-center ml-2`}>
          <div className={`d-flex`}>
            <div>{`Total ${props.tableName || ""}:`}</div>
            <div className={`${style.titleStyle} ml-2`}>
              {props.tableTotalNumber || props.data.length}
            </div>
          </div>
          {renderSelectedText()}
        </div>
        <div className={`d-flex ${style.tableFooterStyle}`}>
          {props.tableFooter}
        </div>
        <div>
          <Pagination
            {...props}
            pages={props.pages}
            page={props.page}
            onPageChange={props.onPageChange}
            className={`mx-0 my-0`}
          />
        </div>
      </div>
    </div>
  );
};
