import * as React from "react";
import * as style from "./modal.module.scss";
import {
  Modal as RsModal,
  ModalBody,
  ModalProps as RsModalProps
} from "reactstrap";
import { Button, ButtonProps } from "src/components/Button/Button";

export interface ModalProps extends RsModalProps {
  isOpen: boolean;
  header?: React.ReactNode | string;
  footer?: boolean | React.ReactNode;
  cancelBtnLabel?: string;
  actionBtnLabel?: string;
  actionBtnOnClick?: (e) => void;
  actionBtnProps?: ButtonProps;
  cancelBtnOnClick?: (e) => void;
  className?: string;
  toggle?: () => void;
}

export const Modal: React.FC<ModalProps> = props => {
  const {
    toggle,
    header,
    footer,
    children,
    cancelBtnLabel = "Cancel",
    actionBtnLabel = "Save",
    actionBtnOnClick,
    actionBtnProps,
    cancelBtnOnClick,
    ...modalProps
  } = props;

  const renderDefaultFooter = (): React.ReactNode => (
    <>
      <Button
        onClick={e => {
          cancelBtnOnClick && cancelBtnOnClick(e);
          toggle && toggle();
        }}
        color={"secondary"}
        className={style.footerBtn}
        buttonLabelClass={`w-100`}
      >
        {cancelBtnLabel}
      </Button>
      <Button
        color={"primary"}
        onClick={actionBtnOnClick}
        className={`ml-2 ${style.footerBtn}`}
        buttonLabelClass={`w-100`}
        {...actionBtnProps}
      >
        {actionBtnLabel}
      </Button>
    </>
  );

  return (
    <RsModal
      {...modalProps}
      toggle={toggle}
      modalClassName={`${style.modal} ${props.className}`}
    >
      {header && (
        <div className={style.modalHeader}>
          <div className={style.headerContent}>{header}</div>
        </div>
      )}
      <ModalBody>{children}</ModalBody>
      {footer && (
        <div className={style.modalFooter}>
          {React.isValidElement(footer) ? footer : renderDefaultFooter()}
        </div>
      )}
    </RsModal>
  );
};
