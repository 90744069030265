import * as React from 'react';
import * as style from './headerWithSubHeader.module.scss';

interface HeaderWithSubHeaderProps {
  title?: string;
  subTitle?: string;
  className?: string;
}

export const HeaderWithSubHeader: React.FC<HeaderWithSubHeaderProps> = (props) => {
  return (
   <div className={`${style.headerWithSubHeader} ${props.className}`}>
      <div className={style.header}>
        {props.title}
      </div>
     <div className={style.subHeader}>
       {props.subTitle}
     </div>
   </div>
  );
};
