import * as React from 'react';
import { routerStore } from 'src/stores/RouterStore';
import { match } from 'react-router';
import { History, Location } from 'history';

interface WithRouterStoreInterface {
  location: Location;
  match: match;
  history: History;
}

/**
 * This wrapper component is used to update the routerStore with the current location,history
 * and match params that React Router provides
 * @param WrappedComponent
 */
export const withRouterStore = WrappedComponent => {
  return class extends React.Component<WithRouterStoreInterface> {
    render() {
      routerStore.setRoute(this.props.location, this.props.match, this.props.history);
      return <WrappedComponent {...this.props} />;
    }
  };
};

export const withRouterStoreReturnInstance = (WrappedComponent, props: any) => {
  routerStore.setRoute(props.location, props.match, props.history);
  return <WrappedComponent {...props} />;
};
