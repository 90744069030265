import * as React from "react";
import * as style from "./select.module.scss";
import { Select } from "src/components/Select/Select";
import ReactSelect from "react-select";

interface SelectExampleProps {}

const Checkbox = props => <input type="checkbox" {...props} />;

export const SelectExample: React.FC<SelectExampleProps> = props => {
  const [isClearable, setIsClearable] = React.useState<boolean>(true);
  const [isSearchable, setIsSearchable] = React.useState<boolean>(true);
  const [isDisabled, setIsDisabled] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isMulti, setIsMulti] = React.useState<boolean>(false);
  const [value, setValue] = React.useState(null);
  const [fixedValue, setFixedValue] = React.useState(
    orderOptions([colourOptions[0], colourOptions[1], colourOptions[3]])
  );

  const handleSelectChange = e => {
    setValue(e);
  };

  // this handles all checkbox change
  const handleCheckboxChange = val => {
    switch (val) {
      case "clear":
        setIsClearable(t => !t);
        break;
      case "search":
        setIsSearchable(t => !t);
        break;
      case "disable":
        setIsDisabled(t => !t);
        break;
      case "loading":
        setIsLoading(t => !t);
        break;
      case "multi":
        if (isMulti) {
          setValue(null);
        }
        setIsMulti(t => !t);
        break;
      default:
        console.log("nothing to change");
    }
  };

  const onFixChange = (value: any, { action }) => {
    switch (action) {
      case "remove-value":
      case "pop-value":
        break;
      case "clear":
        value = colourOptions.filter(v => v.isFixed);
        break;
    }

    value = orderOptions(value);
    setFixedValue(value);
  };

  return (
    <div>
      <Select
        label={"Select with Normal Options"}
        labelClass={style.subTitle}
        isDisabled={isDisabled}
        iszLoading={isLoading}
        isClearable={isClearable}
        isSearchable={isSearchable}
        isMulti={isMulti}
        options={flavourOptions}
        onChange={handleSelectChange}
        value={value}
      />
      <div className={"mt-2"}>
        <span className={"mr-3"}>
          <Checkbox
            checked={isDisabled}
            onChange={() => handleCheckboxChange("disable")}
          />
          Disable
        </span>
        <span className={"mr-3"}>
          <Checkbox
            checked={isLoading}
            onChange={() => handleCheckboxChange("loading")}
          />
          Loading
        </span>
        <span className={"mr-3"}>
          <Checkbox
            checked={isClearable}
            onChange={() => handleCheckboxChange("clear")}
          />
          Clearable
        </span>
        <span className={"mr-3"}>
          <Checkbox
            checked={isSearchable}
            onChange={() => handleCheckboxChange("search")}
          />
          Searchable
        </span>
        <span className={"mr-3"}>
          <Checkbox
            checked={isMulti}
            onChange={() => handleCheckboxChange("multi")}
          />
          Multi Select
        </span>
      </div>
      <div className={"mt-4"}>
        <Select
          labelClass={style.subTitle}
          label={"Select with Grouped Options"}
          options={groupedOptions as any}
          isMulti={true}
          onChange={handleSelectChange}
          formatGroupLabel={formatGroupLabel}
        />
      </div>
      <div className={"mt-4"}>
        <label className={style.subTitle}>Select with Fixed Options</label>
        <Select
          value={fixedValue}
          options={colourOptions}
          isMulti={true}
          styles={styles}
          onChange={onFixChange}
        />
      </div>
      <div className={"mt-4"}>
        <Select
          borderLess={true}
          labelClass={style.subTitle}
          label={"Select with no border"}
          options={groupedOptions as any}
          isMulti={true}
          onChange={handleSelectChange}
          formatGroupLabel={formatGroupLabel}
        />
      </div>
      <div>
        For further documentation about Select{" "}
        <a target="_blank" href="https://react-select.com/home">
          Click here
        </a>
      </div>
    </div>
  );
};

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between"
};
const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center"
};

const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles as any}>{data.options.length}</span>
  </div>
);

const orderOptions = values => values.sort(a => (a.isFixed ? -1 : 0));

const styles = {
  multiValue: (base, state) => {
    return state.data.isFixed ? { ...base, backgroundColor: "gray" } : base;
  },
  multiValueLabel: (base, state) => {
    return state.data.isFixed
      ? { ...base, fontWeight: "bold", color: "white", paddingRight: 6 }
      : base;
  },
  multiValueRemove: (base, state) => {
    return state.data.isFixed ? { ...base, display: "none" } : base;
  }
};

const colourOptions = [
  { value: "ocean", label: "Ocean", color: "#00B8D9", isFixed: true },
  { value: "blue", label: "Blue", color: "#0052CC", isDisabled: true },
  { value: "purple", label: "Purple", color: "#5243AA" },
  { value: "red", label: "Red", color: "#FF5630", isFixed: true },
  { value: "orange", label: "Orange", color: "#FF8B00" },
  { value: "yellow", label: "Yellow", color: "#FFC400" },
  { value: "green", label: "Green", color: "#36B37E" },
  { value: "forest", label: "Forest", color: "#00875A" },
  { value: "slate", label: "Slate", color: "#253858" },
  { value: "silver", label: "Silver", color: "#666666" }
];

const flavourOptions = [
  { value: "vanilla", label: "Vanilla", rating: "safe" },
  { value: "chocolate", label: "Chocolate", rating: "good" },
  { value: "strawberry", label: "Strawberry", rating: "wild" },
  { value: "salted-caramel", label: "Salted Caramel", rating: "crazy" }
];

const groupedOptions = [
  {
    label: "Colours",
    options: colourOptions
  },
  {
    label: "Flavours",
    options: flavourOptions
  }
];
