import { ApiStore } from "src/stores/ApiStore";

interface ProjectCandidatesApiStorePayload {
  company_project_id: number | string;
  search?: string;
  sort_by?: string;
  sort_order?: string;
}

export interface ProjectCandidatesApiStoreData {
  company_project_id: number;
  project_name: string;
  candidate_id: number;
  first_name: string;
  last_name: string;
  candidate_status: string;
  overall_score: number
  is_report_generated: boolean
  last_seen: string;
}

class ProjectCandidatesApiStore extends ApiStore<
  ProjectCandidatesApiStorePayload,
  ProjectCandidatesApiStoreData[]
> {
  constructor() {
    super("api/projectCandidates");
  }
}

export const projectCandidatesApiStore = new ProjectCandidatesApiStore();
