import * as React from 'react';
import * as style from './hamburgerIcon.module.scss';

interface HamburgerIconProps {
  active: boolean;
}

export const HamburgerIcon: React.FC<HamburgerIconProps> = (props) => {
  return (
    <div className={`${style.navIcon} ${props.active && style.active}`}>
      <div>
      </div>
    </div>
  );
};
