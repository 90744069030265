import {ApiStore} from 'src/stores/ApiStore';

interface IsCompletedApiStorePayload {
  token: string;
}

interface IsCompletedApiStoreData {
  is_completed: boolean;
}

class IsCompletedApiStore extends ApiStore<
  IsCompletedApiStorePayload,
  IsCompletedApiStoreData
  > {
  constructor() {
    super(
      'api/auth/isCompleted'
    );
  }
}

export const isCompletedApiStore = new IsCompletedApiStore();
