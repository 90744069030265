import * as React from "react";
import * as style from "./header.module.scss";
import { Logo } from "src/components/Logo/Logo";
import { ProfileName } from "src/components/ProfileName/ProfileName";
import { HamburgerIcon } from "src/components/Header/HamburgerIcon";
import {
  DropdownBase,
  DropdownItemData
} from "src/components/Dropdown/DropdownBase";

export interface HeaderProps {
  name?: string;
  onLogoClick?: () => void;
  onClickMenu?: () => void;
  menuActive?: boolean;
  showSidebar?: boolean;
  dropdownData?: DropdownItemData[];
}

export const Header: React.FC<HeaderProps> = props => {
  const { onLogoClick, showSidebar = true } = props;

  return (
    <div className={`${style.header}`}>
      <div className={`${style.logo} container align-items-center`}>
        {showSidebar && (
          <div className={style.hamburgerSection} onClick={props.onClickMenu}>
            <HamburgerIcon active={props.menuActive} />
          </div>
        )}
        <Logo onClick={onLogoClick} />
      </div>
    </div>
  );
};
