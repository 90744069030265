import { FormStore } from 'src/stores/FormStore';
import {authLoginApiStore} from "src/components/Login/stores/authLoginApiStore";
import {authStore} from "src/stores/AuthStore";
import {permissionApiStore} from "src/components/Login/stores/permissionApiStore";

interface LoginFormStoreData {

}

export const loginFormStore = new FormStore<LoginFormStoreData>(
  {
    email: {
      label: 'Email',
      placeholder: 'Email address',
      rules: 'required|email',
      type: 'email',
      bindings: 'extended'
    },
    password: {
      label: 'Password',
      placeholder: 'Password',
      rules: 'required|string',
      type: 'password',
      bindings: 'extended'
    },
  },
  {
    options: {
      validateOnChange: true
    },
    hooks: {
      onSuccess: async (form: FormStore) => {
        const values: LoginFormStoreData = form.values();
        try {
          await authLoginApiStore.callApi({
            ...form.values()
          });
          authStore.setToken(authLoginApiStore.data.token);
          form.reInit();
        } catch (e) {
          console.error(`LoginFormStore Api Failed: `, e);
          return Promise.reject(`Error calling api`);
        }

        return Promise.resolve();
      },
      onError: form => {
        return Promise.reject(`Incorrect form information`);
      }
    }
  }
);
