import * as React from "react";
import * as style from "./sideNavigation.module.scss";
import {Card} from "src/components/Card/Card";
import {
  NavigationProfileSection,
  NavigationProfileSectionProps
} from "src/components/SideNavigation/NavigationProfileSection";
import {NavigationMenu, NavigationMenuData} from "src/components/SideNavigation/NavigationMenu";
import {animated, useSpring} from "react-spring";
import { ResponsiveSize, useResolution} from "src/utils/responsive";

interface SideNavigationProps {
  menuItems: NavigationMenuData[];
  bottomMenuItems?: NavigationMenuData[];
  mobileActive?: boolean;
  profileData?: NavigationProfileSectionProps;
}

export const SideNavigation: React.FC<SideNavigationProps> = props => {

  const isPhone = useResolution(ResponsiveSize.PHONE_ONLY);

  const openAnimation = useSpring({
    transform:
      !isPhone || props.mobileActive
        ? `translateX(0)`
        : `translateX(-100vw)`
  });

  return (
    <animated.div style={openAnimation} className={style.animatedDiv}>
      <Card
        className={`d-flex flex-column ${
          style.sideNavigation
        } ${!props.mobileActive && style.mobileActive} `}
      >
        <div className={`${style.topSection} d-flex align-items-center`}>
          <NavigationProfileSection {...props.profileData} />
        </div>
        <div className={style.middleSection}>
          <NavigationMenu data={props.menuItems} />
        </div>
        <div
          className={`${style.bottomSection} d-flex flex-column justify-content-end`}
        >
          <NavigationMenu data={props.bottomMenuItems} />
        </div>
      </Card>
    </animated.div>

  );
};
