import { RestMethods } from "src/stores/api/baseApi";
import { ApiStore } from "src/stores/ApiStore";

export interface AddIndividualCandidateApiStorePayload {
  company_project_id: number | string;
  first_name: string
  last_name: string
  email: string
}

interface AddIndividualCandidateApiStoreData {}

class AddIndividualCandidateApiStore extends ApiStore<
  AddIndividualCandidateApiStorePayload,
  AddIndividualCandidateApiStoreData
> {
  constructor() {
    super(
      "api/project/:company_project_id/candidateManagement/createIndividualCandidate",
      {
        requestType: RestMethods.POST
      }
    );
  }
}

export const addIndividualCandidateApiStore = new AddIndividualCandidateApiStore();
