import * as React from "react";
import { ReportModalHeader } from "src/components/ReportModal/ReportModalHeader";
import { ReportModalBody } from "src/components/ReportModal/ReportModalBody";
import { ReportModalBase } from "src/components/ReportModal/ReportModalBase";
import { useEffectAsync } from "src/utils/hooks";
import { reportModalApiStore } from "src/components/ReportModal/stores/reportModalApiStore";
import { routerStore } from "src/stores/RouterStore";
import * as _ from "lodash";
import { observer } from "mobx-react";

interface ReportModalProps {
  isOpen: boolean;
  toggle: () => void;
  candidate_id: number;
}

export const ReportModal: React.FC<ReportModalProps> = observer(props => {
  const [viewMoreIsOpen, toggleViewMoreIsOpen] = React.useState(false);

  useEffectAsync(async () => {
    props.candidate_id &&
      (await reportModalApiStore.callApi({
        candidate_id: props.candidate_id
      }));
  }, [props.candidate_id]);

  useEffectAsync(async () => {
    props.isOpen && toggleViewMoreIsOpen(false);
  }, [props.isOpen]);

  return (
    <ReportModalBase
      viewMore={viewMoreIsOpen}
      isOpen={props.isOpen}
      toggle={props.toggle}
      header={
        <ReportModalHeader
          name={_.get(reportModalApiStore.data, "candidate.candidate_name")}
          primaryNumber={_.get(reportModalApiStore.data, "overall_score")}
          secondaryNumber={10}
          isOpen={viewMoreIsOpen}
          profileIcon={{
            imageUrl: _.get(reportModalApiStore.data, "candidate.profile_image")
          }}
          toggle={props.toggle}
        />
      }
    >
      <ReportModalBody
        data={_.get(reportModalApiStore.data, "competency_groups")}
        isOpen={viewMoreIsOpen}
        onClick={() => toggleViewMoreIsOpen(!viewMoreIsOpen)}
      />
    </ReportModalBase>
  );
});
