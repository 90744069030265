import { ApiStore } from "src/stores/ApiStore";
import { RestMethods } from "src/stores/api/baseApi";

interface AuthLoginApiStorePayload {
  email: string;
  password: string;
}

interface AuthLoginApiStoreData {
  token: string;
}

class AuthLoginApiStore extends ApiStore<
  AuthLoginApiStorePayload,
  AuthLoginApiStoreData
> {
  constructor() {
    super("/api/auth/login", {
      requestType: RestMethods.POST
    });
  }
}

export const authLoginApiStore = new AuthLoginApiStore();
