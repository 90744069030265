import { useMediaQuery } from "react-responsive";

export enum ResponsiveSize {
  PHONE_ONLY = 549
}

export function useResolution(size: ResponsiveSize) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return  useMediaQuery({
    maxDeviceWidth: size
  });
}


