import * as React from "react";
import * as style from "./myTestPage.module.scss";
import { Breadcrumb } from "src/components/Breadcrumb/Breadcrumb";
import { Card } from "src/components/Card/Card";
import { SectionHeader } from "src/components/SectionHeader/SectionHeader";
import { SectionHeaderLarge } from "src/components/SectionHeader/SectionHeaderLarge";
import { TestListTable } from "src/components/TestListTable/TestListTable";
import { Tabs } from "src/components/Tabs/Tabs";
import { ResponsiveSize, useResolution } from "src/utils/responsive";
import { ProjectOverviewCarousel } from "src/container/BusinessUser/components/CandidatePage/ProjectOverviewCarousel/ProjectOverviewCarousel";
import {
  SetupSection,
  SetupSectionData
} from "src/container/BusinessUser/components/CandidatePage/SetupPage/SetupSection";
import { observer } from "mobx-react";
import Confetti from "react-dom-confetti";
import { useEffectAsync } from "src/utils/hooks";
import {
  candidateProjectOverviewApiStore,
  CandidateToolStatus
} from "src/container/Candidate/components/MyTestPage/stores/candidateProjectOverviewApiStore";
import { routerStore } from "src/stores/RouterStore";
import Moment from "react-moment";
import {
  CANDIDATE_DASHBOARD,
  CANDIDATE_TEST
} from "src/container/Candidate/constants/candidateRoutes";
import { TestListData } from "src/components/TestListTable/TestList";
import { candidateTestStore } from "src/container/stores/candidateTestStore";
import { updateCandidateToolToInProgressApiStore } from "src/container/Candidate/components/MyTestPage/stores/updateCandidateToolToInProgressApiStore";
import { neutralizeWord } from "src/utils";

interface MyTestPageProps {}

export const MyTestPage: React.FC<MyTestPageProps> = observer(props => {
  const [activeTab, setActiveTab] = React.useState("overview");
  const [confettiState, setConfettiState] = React.useState(false);

  const config = {
    angle: 90,
    spread: "95",
    startVelocity: 45,
    elementCount: "97",
    dragFriction: 0.1,
    duration: 3000,
    stagger: 0,
    width: "10px",
    height: "10px"
  } as any;

  useEffectAsync(async () => {
    await candidateProjectOverviewApiStore.callApi({
      ...routerStore.params
    });
    if (routerStore.queryString.completed) {
      setConfettiState(true);
      routerStore.push(CANDIDATE_TEST, {
        ...routerStore.params
      });
    }
  }, []);

  const handleOnClick = key => {
    setActiveTab(key);
  };

  const isPhone = useResolution(ResponsiveSize.PHONE_ONLY);

  const setupStatus: SetupSectionData[] = [
    {
      title: "Received",
      status: candidateProjectOverviewApiStore.hasList ? (
        <Moment fromNow={true}>
          {candidateProjectOverviewApiStore.data.invited_date}
        </Moment>
      ) : (
        "-"
      )
    },
    {
      title: "Overall",
      status: candidateProjectOverviewApiStore.hasList
        ? `${candidateProjectOverviewApiStore.data.overall_estimation} mins`
        : "-"
    },
    {
      title: "Deadline",
      status: candidateProjectOverviewApiStore.hasList ? (
        <Moment format={`DD/MM/YYYY`}>
          {candidateProjectOverviewApiStore.data.deadline}
        </Moment>
      ) : (
        "-"
      )
    }
  ];

  const projectOverview = (
    <ProjectOverviewCarousel
      header={`Test Status`}
      title={candidateProjectOverviewApiStore.data.project_name}
      activeNumber={candidateProjectOverviewApiStore.data.completed_count}
      secondaryNumber={
        candidateProjectOverviewApiStore.data.project_tools_count
      }
      subtitle={`by ${candidateProjectOverviewApiStore.data.company_name}`}
      showHeader={!isPhone}
      className={style.overview}
      isLoading={candidateProjectOverviewApiStore.isLoading}
    />
  );

  const tabItems = [
    {
      key: "overview",
      tabTitle: "Overview",
      tabContent: projectOverview
    },
    {
      key: "setup",
      tabTitle: "Setup",
      tabContent: <SetupSection showTitle={false} data={setupStatus} />
    }
  ];

  const getButtonText = (status: CandidateToolStatus) => {
    switch (status) {
      case CandidateToolStatus.IN_PROGRESS:
        return "RESUME";
      case CandidateToolStatus.COMPLETED:
        return "COMPLETED";
      case CandidateToolStatus.NEW:
        return "START";
      default:
        break;
    }
  };

  const getButtonColor = (status: CandidateToolStatus) => {
    switch (status) {
      case CandidateToolStatus.IN_PROGRESS:
        return "secondary";
      case CandidateToolStatus.COMPLETED:
        return "primary";
      case CandidateToolStatus.NEW:
        return "primary";
      default:
        break;
    }
  };

  const toolsData: TestListData[] | any = () =>
    candidateProjectOverviewApiStore.hasList &&
    candidateProjectOverviewApiStore.data.candidate_tools.map(item => ({
      title: item.tool_name,
      status: neutralizeWord(item.candidate_tool_status),
      buttonText: getButtonText(item.candidate_tool_status),
      estimation_time: `${item.estimation} mins`,
      buttonProps: {
        disabled: item.candidate_tool_status === CandidateToolStatus.COMPLETED,
        color: getButtonColor(item.candidate_tool_status),
        onClick: async () => {
          candidateTestStore.setToken(
            item.candidate_tool_id,
            routerStore.location.pathname
          );
          await updateCandidateToolToInProgressApiStore.callApi({
            candidate_tool_id: item.candidate_tool_id
          });
          window.location.replace(item.tool_url);
        }
      },
      isCompleted: item.candidate_tool_status === CandidateToolStatus.COMPLETED
    }));

  return (
    <>
      <div>
        <div className={style.header}>
          {projectOverview}
          <SetupSection
            className={style.setup}
            data={setupStatus}
            header={"Test Info"}
          />
        </div>
        <div className={style.tabs}>
          <Tabs
            data={tabItems}
            activeTab={activeTab}
            onNavClick={handleOnClick}
          />
        </div>
        <Breadcrumb
          contents={[
            {
              text: `My Test`,
              onClick: () => routerStore.push(CANDIDATE_DASHBOARD)
            },
            {
              text: `${candidateProjectOverviewApiStore.data.company_name}`
            },
            {
              text: `${candidateProjectOverviewApiStore.data.project_name}`
            }
          ]}
        />
        <Card className={style.card}>
          <div className={`w-100 ${style.contentSection}`}>
            <div className={style.testOverview}>
              <SectionHeaderLarge
                title={candidateProjectOverviewApiStore.data.project_name}
                titleClassName={style.headerText}
              >
                {candidateProjectOverviewApiStore.data.description}
              </SectionHeaderLarge>
            </div>
            <div className={style.testList}>
              <SectionHeader
                title={`Test List`}
                titleClassName={style.headerText}
              >
                <TestListTable className={style.testTable} data={toolsData()} />
              </SectionHeader>
            </div>
          </div>
        </Card>
      </div>
      <div className={style.confetti}>
        <Confetti active={confettiState} config={config} />
      </div>
    </>
  );
});
