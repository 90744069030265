import { ApiStore } from "src/stores/ApiStore";

interface ReportModalApiStorePayload {
  candidate_id: number;
}

interface CompetencyGroup {
  competency_group_name: string;
  competencies: Competency[];
  competency_group_score?: number;
}

interface Competency {
  competency_name: string;
  competency_code: string;
  competency_score: number;
  competency_description: string;
}

interface Candidate {
  candidate_name: string;
  profile_image?: any;
}

export interface ReportModalApiStoreData {
  candidate: Candidate;
  competency_groups: CompetencyGroup[];
  overall_score: number;
}

class ReportModalApiStore extends ApiStore<
  ReportModalApiStorePayload,
  ReportModalApiStoreData[]
> {
  constructor() {
    super("/api/resultManagement/getReportData");
  }
}

export const reportModalApiStore = new ReportModalApiStore();
