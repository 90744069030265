import * as React from "react";
import * as style from "./pageBase.module.scss";
import { observer } from "mobx-react-lite";
import { Header, HeaderProps } from "src/components/Header/Header";
import { useEffectAsync, useWindowSize } from "src/utils/hooks";
import { Wave } from "src/components/PageBase/Wave";
import { SideNavigation } from "src/components/SideNavigation/SideNavigation";
import { NavigationMenuData } from "src/components/SideNavigation/NavigationMenu";
import { useState } from "react";
import { NavigationProfileSectionProps } from "src/components/SideNavigation/NavigationProfileSection";
import { routerStore } from "src/stores/RouterStore";
import { APP_LOGIN } from "src/constants/routes";

interface PageBaseProps extends HeaderProps {
  waveBottom?: boolean;
  menuItems?: NavigationMenuData[];
  bottomMenuItems?: NavigationMenuData[];
  profileData?: NavigationProfileSectionProps;
  showSidebar?: boolean;
}

export const PageBase: React.FC<PageBaseProps> = observer(props => {
  const {
    waveBottom,
    profileData,
    menuItems,
    bottomMenuItems,
    showSidebar = true,
    ...otherProps
  } = props;
  const [menuActiveMobile, setMenuActiveMobile] = useState<boolean>(false);

  const size = useWindowSize();

  useEffectAsync(async () => {
    // This is to set a css variable for mobile viewpoints height
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, [size]);

  return (
    <div className={`w-100 h-100 ${style.pagebase}`}>
      <div className={`container`}>
        <Header
          onLogoClick={() => routerStore.push(APP_LOGIN)}
          {...otherProps}
          menuActive={menuActiveMobile}
          onClickMenu={() => setMenuActiveMobile(!menuActiveMobile)}
          showSidebar={showSidebar}
        />
        {showSidebar && (
          <SideNavigation
            menuItems={menuItems}
            bottomMenuItems={bottomMenuItems}
            mobileActive={menuActiveMobile}
            profileData={profileData}
          />
        )}
        <div
          className={`${style.pagebaseContainer} ${!props.showSidebar &&
            style.noSidebar}`}
        >
          <div className={`d-flex flex-row w-100`}>
            <div className={`${style.pagebaseContent}`}>{props.children}</div>
          </div>
        </div>
      </div>
      <Wave waveBottom={waveBottom} />
    </div>
  );
});
