import * as React from "react";
import * as style from "../../../../components/DashboardLayout/dashboardLayout.module.scss";
import * as candidateStyle from "./candidateDashboardPage.module.scss";
import { CountColumnProps } from "src/components/MultiCountColumn/CountColumn";
import { DashboardLayout } from "src/components/DashboardLayout/DashboardLayout";
import { SectionHeaderLarge } from "src/components/SectionHeader/SectionHeaderLarge";
import { MultiCountColumns } from "src/components/MultiCountColumn/MultiCountColumns";
import { PromoSection } from "src/components/PromoSection/PromoSection";
import { Select } from "src/components/Select/Select";
import { ProjectCard } from "src/components/ProjectCard/ProjectCard";
import { GridLayout } from "src/components/GridLayout/GridLayout";
import { observer } from "mobx-react";
import { useEffectAsync } from "src/utils/hooks";
import { candidateOverviewApiStore } from "src/container/Candidate/components/CandidateDashboardPage/stores/candidateOverviewApiStore";
import { candidateCompaniesApiStore } from "src/container/Candidate/components/CandidateDashboardPage/stores/candidateCompaniesApiStore";
import { SelectOption } from "src/_declare/select";
import { candidateCompanyProjectsApiStore } from "src/container/Candidate/components/CandidateDashboardPage/stores/candidateCompanyProjectsApiStore";
import { Loading } from "src/components/Loading/Loading";
import Moment from "react-moment";
import { routerStore } from "src/stores/RouterStore";
import { CANDIDATE_TEST } from "src/container/Candidate/constants/candidateRoutes";
import { NoData } from "src/components/NoData/NoData";
import * as _ from "lodash";

interface CandidateDashboardPageProps {}

export const CandidateDashboardPage: React.FC<CandidateDashboardPageProps> = observer(
  props => {
    useEffectAsync(async () => {
      await candidateOverviewApiStore.callApi();
      await candidateCompaniesApiStore.callApi();
      await candidateCompanyProjectsApiStore.callApi();
    }, []);

    const overviewColumn: CountColumnProps[] = [
      {
        title: `Total Test`,
        count: candidateOverviewApiStore.data.total_test || 0
      },
      {
        title: `New`,
        count: candidateOverviewApiStore.data.new || 0
      },
      {
        title: `In Progress`,
        count: candidateOverviewApiStore.data.in_progress || 0
      },
      {
        title: `Completed`,
        count: candidateOverviewApiStore.data.completed || 0
      }
    ];

    const clientList: SelectOption[] | any = () =>
      candidateCompaniesApiStore.hasList
        ? _.uniqBy(
            candidateCompaniesApiStore.data.map(item => ({
              value: item.company_id,
              label: item.company_name
            })),
            item => {
              return item.value;
            }
          )
        : [];

    const onChangeCompany = selectedCompany => {
      candidateCompanyProjectsApiStore.callApi({
        company_id: selectedCompany ? Number(selectedCompany.value) : undefined
      });
    };

    return (
      <DashboardLayout>
        <div className={`w-100 d-flex justify-content-between`}>
          <SectionHeaderLarge
            title={`Test Overview`}
            className={style.overviewLeftSection}
          >
            <MultiCountColumns data={overviewColumn} />
          </SectionHeaderLarge>
          <SectionHeaderLarge
            title={`What's New`}
            className={style.overviewRightSection}
          >
            <PromoSection
              promoImageSource={`https://yingcomms.com/wp-content/uploads/2019/03/0dada2ef-shutterstock_506137132.jpg`}
            />
          </SectionHeaderLarge>
        </div>
        <div className={`mt-4`}>
          <SectionHeaderLarge
            title={`My Test`}
            rightSection={
              <Select
                options={clientList()}
                onChange={onChangeCompany}
                isClearable={true}
                isLoading={candidateCompaniesApiStore.isLoading}
                className={candidateStyle.select}
              />
            }
          >
            <Loading isLoading={candidateCompanyProjectsApiStore.isLoading}>
              {candidateCompanyProjectsApiStore.isSuccess &&
                candidateCompaniesApiStore.hasList && (
                  <GridLayout>
                    {candidateCompanyProjectsApiStore.data.map(item => (
                      <ProjectCard
                        title={item.project_name}
                        subtitle={`by ${item.company_name}`}
                        secondaryWaveDetails={{
                          totalNumber: item.project_tools_count,
                          activeNumber: item.completed_count
                        }}
                        secondaryButtonText={`Open Test`}
                        onClickSecondaryButton={() =>
                          routerStore.push(CANDIDATE_TEST, {
                            company_project_id: item.company_project_id,
                            project_candidate_id: item.project_candidate_id
                          })
                        }
                        hideUserSection={true}
                        additionalContent={
                          <div className={candidateStyle.additionalContent}>
                            Received
                            <div>
                              <Moment fromNow={true}>
                                {item.invited_date}
                              </Moment>
                            </div>
                          </div>
                        }
                      />
                    ))}
                  </GridLayout>
                )}
              {candidateCompanyProjectsApiStore.isSuccess &&
                !candidateCompaniesApiStore.hasList && (
                  <NoData className={candidateStyle.noData} showCard={true} />
                )}
            </Loading>
          </SectionHeaderLarge>
        </div>
      </DashboardLayout>
    );
  }
);
