import * as React from 'react';
import * as style from './sectionHeaderLarge.module.scss';
import {SectionHeader, SectionHeaderProps} from "src/components/SectionHeader/SectionHeader";

interface SectionHeaderLargeProps extends SectionHeaderProps{

}

export const SectionHeaderLarge: React.FC<SectionHeaderLargeProps> = (props) => {
  return (
    <SectionHeader titleClassName={style.header} {...props}>
      {props.children}
    </SectionHeader>
  );
};
