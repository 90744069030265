import { ApiStore } from "src/stores/ApiStore";

interface CandidateOverviewApiStorePayload {}

interface CandidateOverviewApiStoreData {
  total_test: number;
  new: number;
  in_progress: number;
  completed: number;
}

class CandidateOverviewApiStore extends ApiStore<
  CandidateOverviewApiStorePayload,
  CandidateOverviewApiStoreData
> {
  constructor() {
    super("api/candidateManagement/candidateOverview");
  }
}

export const candidateOverviewApiStore = new CandidateOverviewApiStore();
