import * as React from "react";
import * as style from "./candidatePage.module.scss";
import { Search } from "src/components/Search/Search";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { AddCandidate } from "src/container/BusinessUser/components/CandidatePage/AddCandidate/AddCandidate";
import { Tabs } from "src/components/Tabs/Tabs";
import { observer } from "mobx-react";
import { ResponsiveSize, useResolution } from "src/utils/responsive";
import {
  SetupSection,
  SetupSectionData
} from "src/container/BusinessUser/components/CandidatePage/SetupPage/SetupSection";
import { ProjectOverviewCarousel } from "src/container/BusinessUser/components/CandidatePage/ProjectOverviewCarousel/ProjectOverviewCarousel";
import { CandidateTable } from "src/container/BusinessUser/components/CandidatePage/CandidateTable/CandidateTable";
import { useEffectAsync } from "src/utils/hooks";
import { candidateProjectOverviewApiStore } from "src/container/BusinessUser/components/CandidatePage/stores/candidateProjectOverviewApiStore";
import { routerStore } from "src/stores/RouterStore";
import { DropdownSplitButton } from "src/components/Dropdown/DropdownSplitButton";
import { DropdownItemData } from "src/components/Dropdown/DropdownBase";
import * as _ from "lodash";
import { useEffect } from "react";
import { candidateStore } from "src/container/BusinessUser/components/CandidatePage/stores/candidateStore";

interface CandidatePageProps {}

export const CandidatePage: React.FC<CandidatePageProps> = observer(props => {
  const [isOpen, toggleIsOpen] = React.useState(false);
  const [isMobileSearch, toggleIsMobileSearch] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState("overview");
  const [searchText, setSearchText] = React.useState(
    routerStore.queryString.search || ""
  );

  const isPhone = useResolution(ResponsiveSize.PHONE_ONLY);

  useEffectAsync(async () => {
    await candidateProjectOverviewApiStore.callApi({ ...routerStore.params });
  }, []);

  useEffect(() => {
    routerStore.pushWithQueryString(routerStore.match.path, {
      params: { ...routerStore.params },
      queryString: {
        ...routerStore.queryString,
        page: 1,
        search: searchText
      }
    });
  }, [searchText]);

  const handleOnClick = key => {
    setActiveTab(key);
  };

  const setupStatus: SetupSectionData[] = [
    {
      title: "Auto Reminder",
      status: `Every ${candidateProjectOverviewApiStore.data.auto_remind_days} days`
    },
    {
      title: "Completion Deadline",
      status: `${candidateProjectOverviewApiStore.data.deadline_days} days from invitation`
    },
    {
      title: "Extension",
      status: `${
        candidateProjectOverviewApiStore.data.extension_enabled
          ? "Enable"
          : "Disable"
      }`
    }
  ];

  const projectOverview = (
    <ProjectOverviewCarousel
      header={`Overview`}
      title={candidateProjectOverviewApiStore.data.name}
      activeNumber={candidateProjectOverviewApiStore.data.completed_candidates}
      secondaryNumber={candidateProjectOverviewApiStore.data.invited_candidates}
      showHeader={!isPhone}
      className={style.overview}
      isLoading={candidateProjectOverviewApiStore.isLoading}
    />
  );

  const tabItems = [
    {
      key: "overview",
      tabTitle: "Overview",
      tabContent: projectOverview
    },
    {
      key: "setup",
      tabTitle: "Setup",
      tabContent: <SetupSection showTitle={false} data={setupStatus} />
    }
  ];

  const bulkActionDropDown: DropdownItemData[] | any = [
    {
      name: "Bulk Invite Candidate",
      onClick: candidateStore.inviteMultipleCandidate,
      disabled: _.isEmpty(candidateStore.candidateChecked)
    }
  ];

  const handleSearch = _.debounce(text => {
    setSearchText(text);
  }, 250);

  return (
    <div>
      <div>
        <div className={style.header}>
          {projectOverview}
          <SetupSection
            className={style.setup}
            data={setupStatus}
            header={"Setup"}
          />
        </div>
        <div className={style.tabs}>
          <Tabs
            data={tabItems}
            activeTab={activeTab}
            onNavClick={handleOnClick}
          />
        </div>
        <div className={`${style.functionBar} d-flex`}>
          <span className={`${style.title}`}>Candidate</span>
          <div className={`d-flex`}>
            <Search
              placeholder={!isMobileSearch && isPhone ? "" : undefined}
              className={`${style.search} ${isMobileSearch &&
                style.mobileSearch}`}
              onClickIcon={() => toggleIsMobileSearch(true)}
              onBlur={() => toggleIsMobileSearch(false)}
              isOpen={isMobileSearch}
              onChange={e => handleSearch(e.target.value)}
              defaultValue={searchText}
            />
            <DropdownSplitButton
              dropdownItem={bulkActionDropDown}
              buttonProps={{
                className: style.phoneButton,
                onClick: () => toggleIsOpen(true)
              }}
              classNameDropdown={style.dropdownButton}
            >
              <FontAwesomeIcon icon={faPlus} /> Candidate
            </DropdownSplitButton>
          </div>
        </div>
        <CandidateTable />
      </div>
      <AddCandidate isOpen={isOpen} backOnClick={() => toggleIsOpen(false)} />
    </div>
  );
});
