import { action, computed } from "mobx";
import { routerStore } from "src/stores/RouterStore";

export enum CANDIDATE {
  CANDIDATE_TOOL_ID = "CANDIDATE_TOOL_ID",
  REDIRECT_URL = "COMPANY_PROJECT_ID"
}

class CandidateTestStore {
  @computed
  get redirectUrl() {
    return window.localStorage.getItem(CANDIDATE.REDIRECT_URL);
  }

  @computed
  get candidateToolId() {
    return window.localStorage.getItem(CANDIDATE.CANDIDATE_TOOL_ID);
  }

  clearToken = () => {
    window.localStorage.removeItem(CANDIDATE.CANDIDATE_TOOL_ID);
    window.localStorage.removeItem(CANDIDATE.REDIRECT_URL);
  };

  @action setToken = (
    candidate_tool_id: number | string,
    redirect_url?: string
  ) => {
    window.localStorage.setItem(
      CANDIDATE.CANDIDATE_TOOL_ID,
      String(candidate_tool_id)
    );
    if (redirect_url) {
      window.localStorage.setItem(CANDIDATE.REDIRECT_URL, String(redirect_url));
    }
  };

  clearDataAndRedirect = (
    isCompleted: boolean,
    redirectUrl = this.redirectUrl
  ) => {
    routerStore.pushWithQueryString(redirectUrl, {
      queryString: {
        completed: isCompleted
      }
    });
    this.clearToken();
  };
}

export const candidateTestStore = new CandidateTestStore();
