import { FormStore } from "src/stores/FormStore";
import { resetPasswordApiStore } from "src/components/Login/stores/resetPasswordApiStore";
import { compactFormValues } from "src/utils/form";

interface NewPasswordFormStoreData {
  password: string;
  password_confirmation: string;
}

export const newPasswordFormStore = new FormStore<NewPasswordFormStoreData>(
  {
    token: {},
    password: {
      label: "Password",
      placeholder: "Password",
      rules: "required|string|min:6",
      type: "password",
      bindings: "extended"
    },
    password_confirmation: {
      label: "Re-enter Password",
      placeholder: "Re-enter Password",
      rules: "required|string|same:password|min:6",
      type: "password",
      bindings: "extended"
    }
  },
  {
    options: {
      validateOnChange: true
    },
    hooks: {
      onSuccess: async (form: FormStore) => {
        const values: NewPasswordFormStoreData = form.values();
        try {
          await resetPasswordApiStore.callApi(compactFormValues({ ...values }));
          form.reInit();
        } catch (e) {
          console.error(`NewPasswordFormStore Api Failed: `, e);
          return Promise.reject(`Error calling api`);
        }

        return Promise.resolve();
      },
      onError: form => {
        return Promise.reject(`Incorrect form information`);
      }
    }
  }
);
