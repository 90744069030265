import * as React from 'react';

import * as style from './pagination.module.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';

interface PaginationProps {
  pages: number;
  page: number;
  onPageChange: (page) => void;
  className?: string;
}

export const Pagination: React.FC<PaginationProps> = props => {
  const [visiblePages, setVisiblePages] = React.useState<number[]>([]);

  React.useEffect(() => {
    setVisiblePages(getVisiblePages(props.page + 1, props.page));
  }, []);

  React.useEffect(() => {
    setVisiblePages(getVisiblePages(props.page, props.pages));
    changePage(props.page + 1);
  }, [props.pages]);

  const changePage = (page: number) => e => {
    const activePage = props.page + 1;

    if (page === activePage) {
      return;
    }
    setVisiblePages(filterPages(getVisiblePages(page, props.pages), props.pages));
    props.onPageChange(page - 1);
  };

  const filterPages = (visiblePages, totalPages) => {
    return visiblePages.filter(page => page <= totalPages);
  };

  const getVisiblePages = (page, total) => {
    if (total < 6) {
      return filterPages([1, 2, 3, 4, 5], total);
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        return [1, page - 1, page, page + 1, total];
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [1, total - 3, total - 2, total - 1, total];
      } else {
        return [1, 2, 3, 4, 5, total];
      }
    }
  };

  const activePage = props.page + 1;
  return (
    <div className={`${style.paginationContainer} ${props.className}`}>
      <div>
        <div>Page:</div>
      </div>

      <div className={`d-flex ${style.wrapperSection}`}>
        <div>
          <button
            className={`${style.paginationNavBtn} ${
              props.page === 0 ? style.hidden : ''
            }`}
            onClick={changePage(activePage - 1)}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
        </div>
        <div className={style.wrapperCenter}>
          {visiblePages.map((pageNum, index, array) => (
            <React.Fragment key={`page-${pageNum}`}>
              {array[index - 1] + 1 < pageNum && '...'}
              <button
                className={`${style.pageNumbersBtn} ${
                  pageNum === activePage ? style.active : ''
                }`}
                onClick={changePage(pageNum)}
              >
                {pageNum}
              </button>
            </React.Fragment>
          ))}
        </div>

        <div>
          <button
            className={`${style.paginationNavBtn} ${
              props.page === props.pages - 1 ? style.hidden : ''
            }`}
            onClick={changePage(activePage + 1)}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
      </div>
    </div>
  );
};
