import * as React from "react";
import * as style from "./inputPassword.module.scss";
import { Input, InputProps } from "src/components/Input/Input";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

interface InputPasswordProps extends InputProps {}

export const InputPassword: React.FC<InputPasswordProps> = props => {
  const [showValue, setShowValue] = useState<boolean>();

  return (
    <Input
      {...props}
      rightAppendAddon={
        <div
          className={style.eyeIcon}
          onMouseDown={() => setShowValue(true)}
          onMouseUp={() => setShowValue(false)}
          onMouseOut={() => setShowValue(false)}
          onTouchStart={() => setShowValue(true)}
          onTouchEnd={() => setShowValue(false)}
        >
          {showValue ? (
            <FontAwesomeIcon icon={faEyeSlash} />
          ) : (
            <FontAwesomeIcon icon={faEye} />
          )}
        </div>
      }
      type={showValue ? "text" : "password"}
    />
  );
};
