import * as React from "react";
import * as style from "./projectTitle.module.scss";

interface ProjectTitleProps {
  title?: string;
  subtitle?: string;
  className?: string;
}

export const ProjectTitle: React.FC<ProjectTitleProps> = props => {
  return (
    <div className={`d-flex  ${style.projectTitle} ${props.className}`}>
      <div>
        <div
          className={`${style.circle} d-flex align-items-center justify-content-center`}
        >
          {props.title[0]}
        </div>
      </div>
      <div
        className={`${style.textSection} ml-4 d-flex flex-column justify-content-center align-items-start`}
      >
        <div>{props.title}</div>
        <div className={style.subtitle}>{props.subtitle}</div>
      </div>
    </div>
  );
};
