import * as React from "react";
import * as style from "./reportModalHeader.module.scss";
import {
  ProfileIcon,
  ProfileIconProps
} from "src/components/ProfileName/ProfileIcon";
import { TotalScore } from "src/components/TotalScore/TotalScore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch, faTimes } from "@fortawesome/free-solid-svg-icons";

interface ReportModalHeaderProps {
  name: string;
  primaryNumber: number;
  secondaryNumber: number;
  isOpen?: boolean;
  profileIcon?: ProfileIconProps;
  toggle?: () => void;
}

export const ReportModalHeader: React.FC<ReportModalHeaderProps> = props => {
  const { name, primaryNumber, secondaryNumber, isOpen, profileIcon } = props;

  return (
    <>
      <div className={`d-flex justify-content-center`}>
        <div className={style.reportModalHeader}>
          <div className={`${style.header}`}>
            <div className={style.headerText}>{isOpen ? "Details Scores" : "Score Report"}</div>
            <div className={style.icon} onClick={props.toggle}>
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </div>
          <div className={`${style.textCenter} py-3`}>
            <ProfileIcon size={120} {...profileIcon} />
          </div>
          <div className={`${style.textCenter} ${style.name}`}>{name}</div>
          <div>
            <div className={`${style.textCenter} ${style.overall}`}>
              Overall Score
            </div>
            <div className={`${style.textCenter}`}>
              <TotalScore
                primaryNumber={primaryNumber}
                primarySize={45}
                secondaryNumber={secondaryNumber}
                secondarySize={20}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
