import * as React from "react";
import * as style from "./businessUserPageBase.module.scss";
import { Switch } from "react-router-dom";
import { PageBase } from "src/components/PageBase/PageBase";
import { Route, RouteInstance } from "src/components/WithRouterStore/Route";
import {
  BUSINESS_USER_DASHBOARD,
  BUSINESS_USER_PROJECT,
  BUSINESS_USER_REGISTER,
  BUSINESS_USER_PROJECT_CANDIDATE
} from "src/container/BusinessUser/constants/businessUserRoutes";
import { NavigationMenuData } from "src/components/SideNavigation/NavigationMenu";
import { BusinessUserRegistration } from "src/container/BusinessUser/components/RegistrationPage/BusinessUserRegistration";
import { isCurrentRouteWindow } from "src/utils";
import { DashboardPage } from "src/container/BusinessUser/components/DashboardPage/DashboardPage";
import { CandidatePage } from "src/container/BusinessUser/components/CandidatePage/CandidatePage";
import { ProtectedRoutes } from "src/components/Login/ProtectedRoutes";
import { permissionApiStore } from "src/components/Login/stores/permissionApiStore";
import { commonNavigation } from "src/constants/commonNavigation";
import { permissionStore } from "src/stores/PermissionStore";
import { routerStore } from "src/stores/RouterStore";
import { CANDIDATE_DASHBOARD } from "src/container/Candidate/constants/candidateRoutes";
import * as _ from "lodash";
import { observer } from "mobx-react";

interface BusinessUserPageBaseProps {}

export const BusinessUserPageBase: React.FC<BusinessUserPageBaseProps> = observer(
  props => {
    const menuItems: NavigationMenuData[] = [
      {
        title: `Home`,
        path: BUSINESS_USER_DASHBOARD
      }
    ];

    const bottomItems: NavigationMenuData[] = [
      (permissionStore.isCandidate || permissionStore.isSuperAdmin) && {
        title: `Switch to Candidate`,
        onClick: () => routerStore.push(CANDIDATE_DASHBOARD)
      }
    ].filter(item => item);

    return (
      <PageBase
        menuItems={menuItems}
        showSidebar={!isCurrentRouteWindow([BUSINESS_USER_REGISTER])}
        bottomMenuItems={bottomItems.concat(commonNavigation)}
        profileData={{
          name: _.get(permissionApiStore.data, "user.full_name", ""),
          imageUrl: _.get(
            permissionApiStore.data,
            "user.company.logo_path",
            undefined
          )
        }}
        onLogoClick={permissionStore.redirectAccordingToRole}
      >
        <Switch>
          <Route
            path={[BUSINESS_USER_REGISTER]}
            component={BusinessUserRegistration}
            exact
          />
          <ProtectedRoutes>
            <RouteInstance
              path={[BUSINESS_USER_DASHBOARD]}
              component={DashboardPage}
              exact
            />
            <RouteInstance
              path={[BUSINESS_USER_PROJECT_CANDIDATE]}
              component={CandidatePage}
              exact
            />
          </ProtectedRoutes>
        </Switch>
      </PageBase>
    );
  }
);
