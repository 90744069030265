import * as React from 'react';
import * as style from './tooltip.module.scss';
import { Tooltip as ReactTooltip, TooltipProps as ReactTooltipProps } from 'reactstrap';

interface TooltipProps extends ReactTooltipProps {}

export const Tooltip: React.FC<TooltipProps> = props => {
  return (
    <ReactTooltip {...props} className={style.tooltip}>
      {props.children}
    </ReactTooltip>
  );
};
