import * as React from 'react';
import { Route as RrdRoute, RouteProps } from 'react-router-dom';
import {
  withRouterStore,
  withRouterStoreReturnInstance
} from 'src/components/WithRouterStore/WithRouterStore';

export const Route: React.FC<RouteProps> = props => {
  const { component, ...otherProps } = props;

  return <RrdRoute component={withRouterStore(component)} {...otherProps} />;
};

export const RouteInstance: React.FC<RouteProps> = props => {
  const { component, ...otherProps } = props;

  return (
    <RrdRoute
      render={props => withRouterStoreReturnInstance(component, props)}
      {...otherProps}
    />
  );
};
