import { FormStore } from "src/stores/FormStore";
import {
  addIndividualCandidateApiStore,
  AddIndividualCandidateApiStorePayload
} from "src/container/BusinessUser/components/CandidatePage/AddCandidate/stores/addIndividualCandidateApiStore";
import { routerStore } from "src/stores/RouterStore";
import { candidateProjectOverviewApiStore } from "src/container/BusinessUser/components/CandidatePage/stores/candidateProjectOverviewApiStore";
import { projectCandidatesApiStore } from "src/container/BusinessUser/components/CandidatePage/stores/projectCandidatesApiStore";

interface AddCandidateFormStoreData
  extends AddIndividualCandidateApiStorePayload {}

export const addCandidateFormStore = new FormStore<AddCandidateFormStoreData>(
  {
    first_name: {
      label: "First Name",
      placeholder: "Enter Candidate's First Name",
      rules: "required",
      bindings: "extended"
    },
    last_name: {
      label: "Last Name",
      placeholder: "Enter Candidate's Last Name",
      rules: "required",
      bindings: "extended"
    },
    email: {
      label: "Email",
      placeholder: "Email Address",
      rules: "required|email",
      type: "email",
      bindings: "extended"
    }
  },
  {
    options: {
      validateOnChange: true
    },
    hooks: {
      onSuccess: async (form: FormStore) => {
        const values: AddCandidateFormStoreData = form.values();
        try {
          await addIndividualCandidateApiStore.callApi({
            ...routerStore.params,
            ...values
          });
          projectCandidatesApiStore.callApiWithPreviousPayload({
            silentLoading: true
          });
          form.reInit();
        } catch (e) {
          console.error(`AddCandidateFormStore Api Failed: `, e);
          return Promise.reject(`Error calling api`);
        }

        return Promise.resolve();
      },
      onError: form => {
        return Promise.reject(`Incorrect form information`);
      }
    }
  }
);
