import * as React from "react";
import * as style from "./businessUserLogin.module.scss";
import { observer } from "mobx-react";
import { Login } from "src/components/Login/Login";
import { PageBase } from "src/components/PageBase/PageBase";
import { useEffectAsync } from "src/utils/hooks";
import { authStore } from "src/stores/AuthStore";
import { permissionStore } from "src/stores/PermissionStore";

interface BusinessUserLoginProps {}

export const LoginPage: React.FC<BusinessUserLoginProps> = observer(props => {
  useEffectAsync(() => {
    if (authStore.getToken) {
      permissionStore.redirectAccordingToRole();
    }
  }, []);
  return (
    <PageBase showSidebar={false}>
      <Login />
    </PageBase>
  );
});
