export enum Datatype {
  STRING = 'STRING',
  NUMBER = 'NUMBER',
  FLOAT = 'FLOAT',
  PERCENTAGE = 'PERCENTAGE'
}

const defaultColumns = [
  {
    name: 'id',
    type: Datatype.NUMBER
  },
  {
    name: 'name',
    type: Datatype.STRING
  },
  {
    name: 'roles',
    type: Datatype.NUMBER
  }
];

export const generateFakeData = ({ amount = 100, columns = defaultColumns } = {}) => {
  const data = [];
  for (let i = 1; i <= amount; i++) {
    const row = columns.reduce((prev, current, key) => {
      let generatedValue;
      switch (current.type) {
        case Datatype.NUMBER:
          generatedValue = i;
          break;
        case Datatype.STRING:
          generatedValue = `${current.name} ${i}`;
          break;
      }
      return {
        ...prev,
        [current.name]: generatedValue
      };
    }, {});
    data.push(row);
  }
  return data;
};

export const convertToAccessor = (text: string) => {
  return text.replace(/\s+/g, '-').toLowerCase();
};
