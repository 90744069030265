import * as React from 'react';
import * as style from './logo.module.scss';

interface LogoProps {
  onClick?: () => any;
}

export const Logo: React.FC<LogoProps> = props => {
  return (
    <div className={style.logo} onClick={props.onClick}>
      <svg
        id="Group_5581"
        data-name="Group 5581"
        xmlns="http://www.w3.org/2000/svg"
        width="67.043"
        height="31"
        viewBox="0 0 67.043 20"
      >
        <g id="Group_5580" data-name="Group 5580" transform="translate(46.094 6.813)">
          <path
            id="Path_426"
            data-name="Path 426"
            d="M15.57,0h5.379L5.379,16.346H0Z"
            transform="translate(0 0)"
            fill="#fff"
          />
          <path
            id="Path_427"
            data-name="Path 427"
            d="M5.379,0H0L7.38,7.748,10.1,4.953Z"
            transform="translate(0 0)"
            fill="#fff"
          />
          <path
            id="Path_428"
            data-name="Path 428"
            d="M308.023,318.817l4.67,5h5.379l-7.419-7.75Z"
            transform="translate(-297.123 -307.472)"
            fill="#fff"
          />
        </g>
        <text
          id="EVA"
          transform="translate(0 23)"
          fill="#fff"
          fontSize="23"
          fontFamily="NunitoSans-Regular, Nunito Sans"
        >
          <tspan x="0" y="0">
            EVA
          </tspan>
        </text>
      </svg>
    </div>
  );
};
