import * as React from "react";
import * as style from "./uploadImageLarge.module.scss";
import { observer } from "mobx-react-lite";
import { ChangeEvent } from "react";
import { isImageFile } from "src/utils/form";
import { ProfileIcon } from "src/components/ProfileName/ProfileIcon";
import { Field } from "mobx-react-form";
import * as _ from "lodash";
import { CustomLabelButton } from "src/components/UploadImage/CustomLabelButton";

interface UploadImageLargeProps {
  formField: Field;
  uploadText?: string;
  resetText?: string;
  className?: string;
  disableOnlyImage?: boolean;
  icon?: Element | React.ReactNode;
}

export const UploadImageLarge: React.FC<UploadImageLargeProps> = observer(
  props => {
    const {
      uploadText = "Upload",
      resetText = "Reset",
      disableOnlyImage = false
    } = props;

    const picture = props.formField.value;
    const profilePicture = _.isObject(picture)
      ? URL.createObjectURL(picture)
      : picture;

    const handleOnFileUploadChange = async (
      e: ChangeEvent<HTMLInputElement>
    ) => {
      e.persist();

      if (!disableOnlyImage && !isImageFile(e.target.files[0].name)) {
        // Need to change to Message
        return console.error("Please choose a valid image file.");
      }

      const imgFile = e.target.files ? e.target.files[0] : undefined;

      /**
       * update form field
       */
      props.formField.set("value", imgFile);
    };

    const handleClear = e => {
      e.preventDefault();

      props.formField.clear();
      return;
    };

    return (
      <div
        className={`d-flex flex-column w-100 justify-content-center align-items-center ${style.uploadImageMain} ${props.className}`}
      >
        {props.icon ? (
          props.icon
        ) : (
          <ProfileIcon size={120} imageUrl={profilePicture} />
        )}
        <label
          className={`d-flex align-items-center `}
          htmlFor={!picture && `uploadBtn`}
          onClick={e => picture && handleClear(e)}
        >
          <div
            className={`d-flex flex-column justify-content-center align-items-center mt-3`}
          >
            <CustomLabelButton>
              {picture ? resetText : uploadText}
            </CustomLabelButton>

            <span className={style.fileSelectedText}>
              {props.formField.value &&
                `File Selected: ${props.formField.value.name as any}`}
            </span>
          </div>
          <input
            className="d-none"
            type="file"
            accept={!disableOnlyImage && "image/*"}
            onChange={handleOnFileUploadChange}
            value=""
            id="uploadBtn"
          />
        </label>
      </div>
    );
  }
);
