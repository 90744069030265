import * as React from "react";
import ReactSelect from "react-select";
import makeAnimated from "react-select/animated";
import * as style from "./select.module.scss";
import { Props } from "react-select";
import { Label } from "src/components/Label/Label";
import { ErrorText } from "src/components/ErrorText/ErrorText";
import { checkRequiredByRules, useShowAsterisk } from "src/utils/form";
import { Asterisk } from "src/components/Asterisk/Asterisk";
import { useRef } from "react";
import { useEffect } from "react";

interface SelectProps extends Props {
  label?: string;
  isAnimated?: boolean;
  error?: string;
  showError?: boolean;
  borderLess?: boolean;
  containerClassName?: string;
  rules?: string;
}

export const Select: React.FC<SelectProps> = props => {
  const {
    label,
    isAnimated,
    showError = true,
    error,
    borderLess,
    className,
    ...otherProps
  } = props;

  const selectRef = useRef<HTMLInputElement | any>();
  const isRequired = checkRequiredByRules(props.rules);

  if (isAnimated) {
    otherProps.components = makeAnimated();
  }

  const showAsterisk = useShowAsterisk(
    selectRef.current && selectRef.current.state.value
  );

  return (
    <div className={`${style.select} ${props.containerClassName}`}>
      {label && (
        <Label showAsterisk={isRequired && showAsterisk}>{label}</Label>
      )}
      {isRequired && showAsterisk && <Asterisk />}
      <ReactSelect
        className={`${style.select} ${borderLess && style.borderLess} ${error &&
          style.borderError} ${props.className} `}
        classNamePrefix={"select"}
        {...otherProps}
        ref={selectRef as any}
      />
      {showError && <ErrorText>{error}</ErrorText>}
    </div>
  );
};
