import * as React from "react";
import { Slide, toast, ToastPosition } from "react-toastify";
import * as _ from "lodash";
import * as style from "./message.module.scss";

interface MessageComponentProps {
  message: string;
  type?: "success" | "info" | "error" | "warning";
}

toast.configure({
  autoClose: 3000,
  draggable: false,
  position: ToastPosition.TOP_CENTER,
  hideProgressBar: true,
  newestOnTop: true,
  closeOnClick: true,
  closeButton: false,
  transition: Slide
});

const MessageComponent: React.FC<MessageComponentProps> = props => {
  return <div className={style.messageText}>{props.message}</div>;
};

const success = (message: string) => {
  toast(<MessageComponent  message={message} type="success" />, {
    className: `${style.successBG} ${style.messageBase}`
  });
};

function info(message: string) {
  toast(<MessageComponent message={message} type="info" />, {
    className: `${style.infoBG} ${style.messageBase}`
  });
}

function warning(message: string) {
  toast(<MessageComponent message={message} type="warning" />, {
    className: `${style.warningBG} ${style.messageBase}`
  });
}

function error(message: string, fallbackMessage?: string) {
  toast(
    <MessageComponent
      message={_.isEmpty(message) ? fallbackMessage : message}
      type="error"
    />,
    {
      className: `${style.errorBG} ${style.messageBase}`
    }
  );
}

export const Message = {
  success,
  info,
  warning,
  error
};
