import { ApiStore } from "src/stores/ApiStore";

interface PermissionApiStorePayload {}

export interface LastSeen {
  date: string;
  timezone_type: number;
  timezone: string;
}

export interface Company {
  id: number;
  name: string;
  logo_path?: any;
  manager_user_id: number;
  created_at: string;
  updated_at: string;
}

export interface User2 {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  is_completed: boolean;
  verified: boolean;
  last_seen: string;
  created_at: string;
  updated_at: string;
  full_name: string;
}

export interface CandidateProfile {
  id: number;
  user_id: number;
  profile_image_url: string;
  resume_path?: any;
  created_at: string;
  updated_at: string;
  full_name: string;
  user: User2;
}

export interface User {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  is_completed: boolean;
  verified: boolean;
  last_seen: LastSeen;
  created_at: string;
  updated_at: string;
  full_name: string;
  company: Company;
  candidate_profile: CandidateProfile;
  roles: any[];
}

export interface PermissionApiStoreData {
  user: User;
  roles: string[];
}

class PermissionApiStore extends ApiStore<
  PermissionApiStorePayload,
  PermissionApiStoreData
> {
  constructor() {
    super("api/auth/permissions");
  }
}

export const permissionApiStore = new PermissionApiStore();
