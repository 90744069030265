import * as React from "react";
import { observer } from "mobx-react";
import { profilePhotoFormStore } from "src/container/Candidate/stores/profilePhotoFormStore";
import { UploadImageLarge } from "src/components/UploadImage/UploadImageLarge";
import {HeaderWithSubHeader} from "src/container/Candidate/components/RegistrationPage/HeaderWithSubHeader";

interface ProfilePhotoUploadStepProps {}

export const ProfilePhotoUploadStep: React.FC<ProfilePhotoUploadStepProps> = observer(
  props => {
    const pictureField = profilePhotoFormStore.$("profile_image");
    return (
      <>
        <HeaderWithSubHeader title={`Upload Your Photo`} subTitle={`to help the employers recognise you`} className={`my-3 mb-4`}/>
        <UploadImageLarge formField={pictureField} />
      </>
    );
  }
);
