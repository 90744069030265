import * as React from "react";
import * as style from "../../../../components/Login/stepsForm.module.scss";
import { MiscellaneousStep } from "src/container/BusinessUser/components/RegistrationPage/MiscellaneousStep";
import { CompanyDetailStep } from "src/container/BusinessUser/components/RegistrationPage/CompanyDetailStep";
import { observer } from "mobx-react";
import {
  businessRegistrationStore,
  Steps
} from "src/container/BusinessUser/components/RegistrationPage/store/businessRegistrationStore";
import { UserDetailsStep } from "src/container/BusinessUser/components/RegistrationPage/UserDetailsStep";
import { LoginContainer } from "src/components/Login/LoginContainer";
import { useEffectAsync } from "src/utils/hooks";
import { ResponsiveSize, useResolution } from "src/utils/responsive";

interface BusinessUserRegistrationProps {}

export const BusinessUserRegistration: React.FC<BusinessUserRegistrationProps> = observer(
  props => {
    const isPhone = useResolution(ResponsiveSize.PHONE_ONLY);

    useEffectAsync(() => {
      if (isPhone) {
        window.scrollTo(0, 0);
      }
    }, [businessRegistrationStore.step]);

    const getContent = () => {
      switch (businessRegistrationStore.step) {
        case Steps.STEP1:
          return <UserDetailsStep />;
        case Steps.STEP2:
          return <CompanyDetailStep />;
        case Steps.STEP3:
          return <MiscellaneousStep />;
      }
    };

    return (
      <LoginContainer
        title={`Welcome to EVA X, create your account`}
        progressText={`Step ${businessRegistrationStore.step} of 3`}
        progressValue={businessRegistrationStore.getProgressPercentage}
        isLoading={businessRegistrationStore.isCompleted}
        primaryText={
          businessRegistrationStore.step === Steps.STEP3 ? "Complete" : "Next"
        }
        primaryBtnOnProps={{
          onClick: businessRegistrationStore.onClickNext,
          disabled: !businessRegistrationStore.isStepValidated
        }}
        secondaryText={
          businessRegistrationStore.step === Steps.STEP1 ? "Cancel" : "Back"
        }
        secondaryBtnOnProps={{
          onClick: businessRegistrationStore.onClickBack
        }}
      >
        <div
          className={`${style.content}`}
          key={businessRegistrationStore.step}
        >
          {getContent()}
        </div>
      </LoginContainer>
    );
  }
);
