import * as React from "react";
import * as style from "./search.module.scss";
import { InputGroup, InputGroupAddon, InputProps } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Input } from "src/components/Input/Input";

interface SearchProps extends InputProps {
  onClickIcon?: () => void;
  isOpen: boolean;
}

export const Search: React.FC<SearchProps> = props => {
  const { onClickIcon, placeholder, isOpen, ...otherProps } = props;
  return (
    <div className={`d-flex px-2`}>
      <InputGroup>
        <Input
          placeholder={
            placeholder !== undefined ? placeholder : "Search here.."
          }
          showError={false}
          inputContainerClassName={style.input}
          getInput={input => isOpen && input.focus()}
          {...otherProps}
        />
        <InputGroupAddon addonType="append" className={style.icon}>
          <FontAwesomeIcon
            icon={faSearch}
            className={style.searchIcon}
            onClick={onClickIcon}
          />
        </InputGroupAddon>
      </InputGroup>
    </div>
  );
};
