import * as React from "react";
import { SectionHeader } from "src/components/SectionHeader/SectionHeader";
import { Input } from "src/components/Input/Input";
import { Select } from "src/components/Select/Select";
import { observer } from "mobx-react";
import { miscellaneousDetailsFormStore } from "src/container/BusinessUser/components/RegistrationPage/store/miscellaneousDetailsFormStore";
import { SelectOption } from "src/_declare/select";

interface MiscellaneousStepProps {}

export enum Sources {
  OTHER_SOURCE = "Other Source"
}

export const MiscellaneousStep: React.FC<MiscellaneousStepProps> = observer(
  props => {
    const aboutUsSelectField = miscellaneousDetailsFormStore.$(
      "about_us_select"
    );
    const aboutUsField = miscellaneousDetailsFormStore.$("about_us");

    const signUpMotivationSelectField = miscellaneousDetailsFormStore.$(
      "signup_motivation_select"
    );

    const signUpMotivationField = miscellaneousDetailsFormStore.$(
      "signup_motivation"
    );

    const aboutUsOptions: SelectOption[] = [
      {
        value: "Accendo",
        label: "Accendo"
      },
      {
        value: "Broadcast",
        label: "Broadcast"
      },
      {
        value: "Online News and Review Sites",
        label: "Online News and Review Sites"
      },
      {
        value: "Our Website",
        label: "Our Website"
      },
      {
        value: "Printed Media",
        label: "Printed Media"
      },
      {
        value: "Search engine",
        label: "Search engine"
      },
      {
        value: "Social media",
        label: "Social media"
      },
      {
        value: "Web Advertisements",
        label: "Web Advertisements"
      },
      {
        value: "Word-of-mouth",
        label: "Word-of-mouth"
      },

      {
        value: Sources.OTHER_SOURCE,
        label: Sources.OTHER_SOURCE
      }
    ];

    const motivationOptions: SelectOption[] = [
      {
        value: "To explore and learn more about work assessment",
        label: "To explore and learn more about work assessment"
      },
      {
        value: "To assess individuals for internal recruitment",
        label: "To assess individuals for internal recruitment"
      },
      {
        value: "To assess individuals for development",
        label: "To assess individuals for development"
      },
      {
        value: "To recruit for other companies",
        label: "To recruit for other companies"
      },
      {
        value: "To provide consultation services to other companies",
        label: "To provide consultation services to other companies"
      },
      {
        value: Sources.OTHER_SOURCE,
        label: Sources.OTHER_SOURCE
      }
    ];

    const onChangeAboutUsSelect = (selected: SelectOption) => {
      aboutUsSelectField.set("value", selected);
      aboutUsField.set(
        "value",
        selected.value !== Sources.OTHER_SOURCE ? selected.value : ""
      );
    };

    const onChangeMotivationSelect = (selected: SelectOption) => {
      signUpMotivationSelectField.set("value", selected);
      signUpMotivationField.set(
        "value",
        selected.value !== Sources.OTHER_SOURCE ? selected.value : ""
      );
    };

    return (
      <>
        <SectionHeader title={`How did you hear about us?`}>
          <Select
            {...aboutUsSelectField.bind()}
            placeholder={`Select`}
            options={aboutUsOptions}
            onChange={onChangeAboutUsSelect}
          />
          {aboutUsSelectField.value.value === Sources.OTHER_SOURCE && (
            <Input
              {...aboutUsField.bind()}
              disabled={
                !(aboutUsSelectField.value.value === Sources.OTHER_SOURCE)
              }
              value={
                aboutUsSelectField.value.value === Sources.OTHER_SOURCE
                  ? aboutUsField.value
                  : ""
              }
            />
          )}
        </SectionHeader>

        <SectionHeader title={`What motivated you to sign up?`}>
          <Select
            {...signUpMotivationSelectField.bind()}
            placeholder={`Select`}
            options={motivationOptions}
            onChange={onChangeMotivationSelect}
          />
          {signUpMotivationSelectField.value.value === Sources.OTHER_SOURCE && (
            <Input
              {...signUpMotivationField.bind()}
              disabled={
                !(
                  signUpMotivationSelectField.value.value ===
                  Sources.OTHER_SOURCE
                )
              }
              value={
                signUpMotivationSelectField.value.value === Sources.OTHER_SOURCE
                  ? signUpMotivationField.value
                  : ""
              }
            />
          )}
        </SectionHeader>
      </>
    );
  }
);
