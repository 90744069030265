import * as React from "react";
import * as style from "./testListIcon.module.scss";
import {TimeSvgIcon} from "src/components/TestListTable/TimeSvgIcon";
import {CompletedSvgIcon} from "src/components/TestListTable/CompletedSvgIcon";

interface TestListIconProps {
  name: string;
  status?: string;
  isCompleted?: boolean;
}

export const TestListIcon: React.FC<TestListIconProps> = props => {
  return (
    <div className={`d-flex ${style.testList}`}>
      <div className={style.icon}>{props.isCompleted ? <CompletedSvgIcon/> : <TimeSvgIcon/>}</div>
      <div className={`ml-2  d-flex flex-column justify-content-center`}>
        <div className={style.name}>{props.name}</div>
        <div className={style.status}>{props.status}</div>
      </div>
    </div>
  );
};
