import * as React from "react";
import * as style from "./profileName.module.scss";
import {ProfileIcon, ProfileIconProps} from "src/components/ProfileName/ProfileIcon";
import {TextOverflowTooltip} from "src/components/TextOverflowTooltip/TextOverflowTooltip";

interface ProfileNameProps extends ProfileIconProps{
  name?: string;
  className?: string;
  classProfileName?: string
}

export const ProfileName: React.FC<ProfileNameProps> = props => {
  const {className, classProfileName, name, ...otherProps} = props;
  return (
    <div className={`align-items-center d-flex ${className}`}>
      <ProfileIcon {...otherProps} />
      <TextOverflowTooltip className={`${style.users} ${classProfileName} `}>{name}</TextOverflowTooltip>
    </div>
  );
};
