import { FormStore } from "src/stores/FormStore";
import { SelectOption } from "src/_declare/select";
import validator from "validator";
import { isEmptyAndIsMin, shouldBeEqualTo } from "src/utils/form";

interface UserDetailsFormStore {
  logo: File;
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  password_confirmation: string;
  country_code: SelectOption;
  contact_no: string;
}

export const userDetailsFormStore = new FormStore<UserDetailsFormStore>(
  {
    logo: {},
    first_name: {
      placeholder: "First Name",
      rules: "required|string",
      bindings: "extended"
    },
    last_name: {
      placeholder: "Last Name",
      rules: "required|string",
      bindings: "extended"
    },
    email: {
      placeholder: "Email address",
      rules: "required|email",
      bindings: "extended"
    },
    password: {
      name: "Password",
      placeholder: "Password",
      rules: "required|string|min:6",
      type: "password",
      bindings: "extended"
    },
    password_confirmation: {
      name: "Re-enter Password",
      placeholder: "Re-enter Password",
      rules: "required",
      type: "password",
      bindings: "extended",
      validators: [isEmptyAndIsMin, shouldBeEqualTo("password")]
    },
    country_code: {
      placeholder: "Code",
      rules: "",
      bindings: "extended"
    },
    contact_no: {
      placeholder: "Phone number",
      rules: "numeric",
      type: "tel",
      bindings: "extended"
    }
  },
  {
    options: {
      validateOnChange: true
    },
    hooks: {
      onSuccess: async (form: FormStore) => {
        const values: UserDetailsFormStore = form.values();
        try {
          form.reInit();
        } catch (e) {
          console.error(`RegistrationFormStore Api Failed: `, e);
          return Promise.reject(`Error calling api`);
        }

        return Promise.resolve();
      },
      onError: form => {
        return Promise.reject(`Incorrect form information`);
      }
    }
  }
);
