import * as React from 'react';
import * as style from './sectionHeader.module.scss';

export interface SectionHeaderProps {
  title?: string;
  rightSection?: Element | React.ReactNode;
  titleClassName?: string;
  className?: string;
}

export const SectionHeader: React.FC<SectionHeaderProps> = (props) => {
  return (
    <div className={`${style.sectionHeader} ${props.className}`}>
      <div className={`d-flex justify-content-between align-items-center`}>
        <div className={`${style.title} ${props.titleClassName}`}>
          {props.title}
        </div>
        <div>
          {props.rightSection}
        </div>
      </div>
      <div>
        {props.children}
      </div>
    </div>
  );
};
