import * as React from "react";
import * as style from "./label.module.scss";
import { Asterisk } from "src/components/Asterisk/Asterisk";

interface LabelProps {
  showAsterisk?: boolean;
}

export const Label: React.FC<LabelProps> = props => {
  return (
    <div
      className={`${style.inputLabel} ${props.showAsterisk &&
        style.inputLabelPadding}`}
    >
      {props.showAsterisk && <Asterisk className={style.asteriskLabel} />}
      {`${props.children}`}
    </div>
  );
};
