import { ApiStore } from "src/stores/ApiStore";

export enum CandidateToolStatus {
  NEW = "NEW",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED"
}

interface CandidateProjectOverviewApiStorePayload {
  company_project_id: number | string;
  project_candidate_id: number | string;
}

export interface CandidateTool {
  tool_name: string;
  estimation: number;
  candidate_tool_id: number;
  candidate_tool_status: CandidateToolStatus;
  tool_url: string;
}

export interface CandidateProjectOverviewApiStoreData {
  project_name: string;
  description: string;
  company_name: string;
  invited_date: Date;
  deadline: string;
  is_report_generated: boolean;
  extension_enabled: boolean;
  project_tools_count: number;
  completed_count: number;
  completed_percentage: number;
  overall_estimation: number;
  candidate_tools: CandidateTool[];
}

class CandidateProjectOverviewApiStore extends ApiStore<
  CandidateProjectOverviewApiStorePayload,
  CandidateProjectOverviewApiStoreData
> {
  constructor() {
    super(
      "api/project/:company_project_id/candidateManagement/candidateProjectOverview"
    );
  }
}

export const candidateProjectOverviewApiStore = new CandidateProjectOverviewApiStore();
