import { ApiStore } from "src/stores/ApiStore";
import { RestMethods } from "src/stores/api/baseApi";


export interface CreateCandidateByTokenApiStorePayload {
  token: string;
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  password_confirmation: string;
  country_code: string | number;
  contact_no: string;
  profile_image: File;
  resume: File;
}

export interface CreateCandidateByTokenApiStoreData {
  token: string;
}

class CreateCandidateByTokenApiStore extends ApiStore<
  CreateCandidateByTokenApiStorePayload,
  CreateCandidateByTokenApiStoreData
> {
  constructor() {
    super("api/auth/register", {
      requestType: RestMethods.POST
    });
  }
}

export const createCandidateApiByTokenStore = new CreateCandidateByTokenApiStore();
