import * as React from 'react';
import * as style from './dashboardLayout.module.scss';

interface DashboardLayoutProps {

}

export const DashboardLayout: React.FC<DashboardLayoutProps> = (props) => {
  return (
    <div className={style.dashboardPage}>
      {props.children}
    </div>
  );
};
