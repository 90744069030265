import * as React from "react";
import * as style from "./addCandidate.module.scss";
import { Card } from "src/components/Card/Card";
import { animated, useSpring } from "react-spring";
import { Input } from "src/components/Input/Input";
import { addCandidateFormStore } from "src/container/BusinessUser/components/CandidatePage/AddCandidate/stores/addCandidateFormStore";
import { Button } from "src/components/Button/Button";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons/faUserPlus";
import { ResponsiveSize, useResolution } from "src/utils/responsive";
import { useRef } from "react";
import { candidateStore } from "src/container/BusinessUser/components/CandidatePage/stores/candidateStore";
import { PreviewBulkCandidateUploadModal } from "src/container/BusinessUser/components/CandidatePage/PreviewBulkCSVModal/PreviewBulkCandidateUploadModal";
import {
  DropdownBase,
  DropdownItemData
} from "src/components/Dropdown/DropdownBase";
import { observer } from "mobx-react";
import { Message } from "src/components/Message/Message";
import { projectCandidatesApiStore } from "src/container/BusinessUser/components/CandidatePage/stores/projectCandidatesApiStore";
import { useEnterKeyPress } from "src/utils/form";

interface AddCandidateProps {
  isOpen?: boolean;
  backOnClick?: () => void;
}

export const AddCandidate: React.FC<AddCandidateProps> = observer(props => {
  const { isOpen, backOnClick } = props;

  let inputRef: any = useRef<any>();

  const firstNameField = addCandidateFormStore.$("first_name");
  const lastNameField = addCandidateFormStore.$("last_name");
  const emailField = addCandidateFormStore.$("email");

  const openAnimation = useSpring({
    right: isOpen ? `-5px` : `-370px`
  });

  const dropdownItems: DropdownItemData[] = [
    {
      name: "Upload CSV",
      onClick: () => {
        inputRef.click();
      }
    },
    {
      name: "Download CSV",
      onClick: async () => {
        await candidateStore.downloadCandidateBulkUploadTemplate();
      }
    }
  ];

  const handleOnSubmit = async () => {
    try {
      await addCandidateFormStore.submit();
      projectCandidatesApiStore.callApiWithPreviousPayload({
        silentLoading: true
      });
      await backOnClick();
      Message.success("Successfully add candidate.");
    } catch (e) {
      Message.error("Failed to add candidate.");
    }
  };

  useEnterKeyPress(handleOnSubmit);

  return (
    <animated.div style={openAnimation} className={style.animatedDiv}>
      <Card className={`d-flex flex-column ${style.addCandidateContainer}`}>
        <div className={`${style.header} d-flex justify-content-between`}>
          <div>
            <FontAwesomeIcon icon={faUserPlus} />
            <span className={style.sideFont}>Add Candidate</span>
          </div>
          <div>
            <DropdownBase
              dropDownClassName={`${style.dropdown}`}
              dropdownItem={dropdownItems}
            >
              <FontAwesomeIcon icon={faChevronDown} />
              <span className={style.uploadCandidate}>Download/Upload</span>
            </DropdownBase>
          </div>
        </div>
        <div className={style.addCandidateForm}>
          <Input {...firstNameField.bind()} />
          <Input {...lastNameField.bind()} />
          <Input {...emailField.bind()} />
          <div className={`d-flex justify-content-around py-2`}>
            <Button
              onClick={backOnClick}
              color={"secondary"}
              className={`${style.buttonWidth} pr-2`}
            >
              Back
            </Button>
            <Button
              className={`${style.buttonWidth} pl-2`}
              onClick={handleOnSubmit}
              disabled={!addCandidateFormStore.isValid}
            >
              Add
            </Button>
          </div>
        </div>
      </Card>
      <Input
        getInput={input => (inputRef = input)}
        type="file"
        id="file"
        accept=".csv"
        value=""
        onChange={candidateStore.uploadCandidateBulkUpload}
        style={{ display: "none" }}
      />
      <PreviewBulkCandidateUploadModal
        isOpen={candidateStore.candidateUploadBulkPreviewModalIsOpen}
        toggle={() =>
          candidateStore.setCandidateUploadBulkPreviewModalIsOpen(
            !candidateStore.candidateUploadBulkPreviewModalIsOpen
          )
        }
        apiStore={() =>
          projectCandidatesApiStore.callApiWithPreviousPayload({
            silentLoading: true
          })
        }
        addCandidateSideBarIsOpen={backOnClick}
      />
    </animated.div>
  );
});
