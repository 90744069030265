import { action, computed, observable } from "mobx";
import { routerStore } from "src/stores/RouterStore";
import { APP_LOGIN } from "src/constants/routes";
import { permissionApiStore } from "src/components/Login/stores/permissionApiStore";
import {permissionStore} from "src/stores/PermissionStore";

export enum TOKEN_NAME {
  ACCESS_TOKEN = "ACCESS_TOKEN",
  REFRESH_TOKEN = "REFRESH_TOKEN"
}

class AuthStore {
  @observable isAuthenticated: boolean = false;

  @computed
  get getToken() {
    return window.localStorage.getItem(TOKEN_NAME.ACCESS_TOKEN);
  }

  removeToken = () => {
    return window.localStorage.removeItem(TOKEN_NAME.ACCESS_TOKEN);
  };

  @action setToken = (accessToken, refreshToken: string = undefined) => {
    window.localStorage.setItem(TOKEN_NAME.ACCESS_TOKEN, accessToken);
  };

  @computed
  get hasToken() {
    return !!this.getToken;
  }
}

export const authStore = new AuthStore();
