import * as React from "react";
import * as style from "./progressBarDescription.module.scss";
import { ProgressBar } from "src/components/ProgressBar/ProgressBar";

export interface ProgressBarDescriptionProps {
  title: string;
  primaryNumber: number;
  secondaryNumber: number;
}

export const ProgressBarDescription: React.FC<ProgressBarDescriptionProps> = props => {
  const { title, primaryNumber, secondaryNumber, children } = props;
  return (
    <div className={style.progressBarDescription}>
      <div className={style.title}>{title}</div>
      <div className={`d-flex justify-content-center align-items-center`}>
        <ProgressBar
          className={style.progressBar}
          value={primaryNumber * secondaryNumber}
        />
        <div className={style.progressValue}>
          <span>
            {primaryNumber}/{secondaryNumber}
          </span>
        </div>
      </div>
      <div className={style.description}>{children}</div>
    </div>
  );
};
