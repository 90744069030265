import * as React from "react";
import * as style from "./input.module.scss";
import { Label } from "src/components/Label/Label";
import { ErrorText } from "src/components/ErrorText/ErrorText";
import { useEffect, useRef, useState } from "react";
import { checkRequiredByRules, useShowAsterisk } from "src/utils/form";
import { useEffectAsync } from "src/utils/hooks";
import { Asterisk } from "src/components/Asterisk/Asterisk";

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  showError?: boolean;
  error?: string;
  rightAppendAddon?: Element | React.ReactNode;
  rightAppendAddonClassName?: string;
  className?: string;
  inputContainerClassName?: string;
  getInput?: (input: HTMLInputElement) => void;
  rules?: string;
  showAsterisk?: boolean;
}

export const Input: React.FC<InputProps> = props => {
  const { showAsterisk = true } = props;
  const inputRef = useRef<HTMLInputElement>();
  const isRequired = checkRequiredByRules(props.rules);

  const asteriskState = useShowAsterisk(
    inputRef.current && inputRef.current.value
  );

  props.getInput && props.getInput(inputRef.current);

  const {
    label,
    showError = "true",
    error,
    rightAppendAddon,
    rightAppendAddonClassName,
    className,
    inputContainerClassName,
    ...otherProps
  } = props;
  return (
    <div className={`${style.inputMain} ${className} `}>
      {label && (
        <Label showAsterisk={isRequired && showAsterisk}>
          {label}
        </Label>
      )}
      <div className={style.inputContainer}>
        {!label && showAsterisk && isRequired && asteriskState && <Asterisk />}
        <input
          className={`${inputContainerClassName} ${style.input} ${error &&
            style.inputError}`}
          ref={inputRef}
          {...otherProps}
        />
        <div
          className={`${style.rightAppendAddon} ${rightAppendAddonClassName}`}
        >
          {rightAppendAddon && rightAppendAddon}
        </div>
      </div>
      {showError && <ErrorText>{error}</ErrorText>}
    </div>
  );
};
