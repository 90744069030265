import { FormStore } from 'src/stores/FormStore';

interface UploadImageSampleFormStoreData {

}

export const uploadImageSampleFormStore = new FormStore<UploadImageSampleFormStoreData>(
  {
    picture: {
      name: 'picture',
      rules: 'required'
    }
  },
  {
    hooks: {
      onSuccess: async (form: FormStore) => {
        const values: UploadImageSampleFormStoreData = form.values();
        try {

          form.reInit();
        } catch (e) {
          console.error(`UploadImageSampleFormStore Api Failed: `, e);
          return Promise.reject(`Error calling api`);
        }

        return Promise.resolve();
      },
      onError: form => {
        return Promise.reject(`Incorrect form information`);
      }
    }
  }
);
