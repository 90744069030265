import * as React from "react";
import * as style from "./protectedRoutes.module.scss";
import { observer } from "mobx-react-lite";
import { useEffectAsync, useInterval } from "src/utils/hooks";
import { permissionApiStore } from "src/components/Login/stores/permissionApiStore";
import { Message } from "src/components/Message/Message";
import { routerStore } from "src/stores/RouterStore";
import { APP_LOGIN } from "src/constants/routes";
import {Loading} from "src/components/Loading/Loading";

interface ProtectedRoutesProps {}

export const ProtectedRoutes: React.FC<ProtectedRoutesProps> = observer(
  (props: any) => {
    useEffectAsync(async () => {
      if (!permissionApiStore.hasList) {
        try {
          await permissionApiStore.callApiIfEmpty();
        } catch (e) {
          Message.error("Authentication error.");
          console.error(e);
          routerStore.push(APP_LOGIN);
        }
      }
    }, []);

    return !permissionApiStore.hasList ? <Loading isLoading={true}/> : props.children;
  }
);
