import { ApiStore } from "src/stores/ApiStore";

interface ProjectOverviewApiStorePayload {
  company_project_id: number | string;
}

interface ProjectOverviewApiStoreData {
  company_project_id: number,
  name: string,
  description: string,
  purchased_slots: number,
  invited_candidates: number,
  completed_candidates: number,
  in_progress_candidates: number,
  completed_percentage: number,
  deadline_days: number,
  auto_remind_days: number,
  extension_enabled: true
}

class CandidateProjectOverviewApiStore extends ApiStore<
  ProjectOverviewApiStorePayload,
  ProjectOverviewApiStoreData
> {
  constructor() {
    super("api/projectOverview");
  }
}

export const candidateProjectOverviewApiStore = new CandidateProjectOverviewApiStore();
