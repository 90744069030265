import { FormStore } from "src/stores/FormStore";
import { SelectOption } from "src/_declare/select";

interface MiscellaneousDetailsFormStoreData {
  about_us_select: SelectOption;
  about_us: string;
  signup_motivation_select: SelectOption;
  signup_motivation: string;
}

export const miscellaneousDetailsFormStore = new FormStore<
  MiscellaneousDetailsFormStoreData
>(
  {
    about_us_select: {
      rules: "",
      bindings: "extended"
    },
    about_us: {
      placeholder: "Please specify",
      rules: "",
      bindings: "extended"
    },
    signup_motivation_select: {
      rules: "",
      bindings: "extended"
    },
    signup_motivation: {
      placeholder: "Please specify",
      rules: "string",
      bindings: "extended"
    }
  },
  {
    options: {
      validateOnChange: true
    },
    hooks: {
      onSuccess: async (form: FormStore) => {
        const values: MiscellaneousDetailsFormStoreData = form.values();
        try {
          form.reInit();
        } catch (e) {
          console.error(`MiscellaneousDetailsFormStore Api Failed: `, e);
          return Promise.reject(`Error calling api`);
        }

        return Promise.resolve();
      },
      onError: form => {
        return Promise.reject(`Incorrect form information`);
      }
    }
  }
);
