import * as React from 'react';
import * as style from './completedSvgIcon.module.scss';

interface CompletedSvgIconProps {

}

export const CompletedSvgIcon: React.FC<CompletedSvgIconProps> = (props) => {
  return (
   <div>
     <svg id="Group_6172" data-name="Group 6172" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
       <g id="Ellipse_1350" data-name="Ellipse 1350" fill="#fff" stroke="#5bd700" strokeWidth="1">
         <circle cx="24" cy="24" r="24" stroke="none"/>
         <circle cx="24" cy="24" r="23.5" fill="none"/>
       </g>
       <path id="check" d="M5.435,76.8l-5.2-5.2a.8.8,0,0,1,0-1.131l1.131-1.131a.8.8,0,0,1,1.131,0l3.5,3.5,7.5-7.5a.8.8,0,0,1,1.131,0l1.131,1.131a.8.8,0,0,1,0,1.131l-9.2,9.2a.8.8,0,0,1-1.131,0Z" transform="translate(16 -47.099)" fill="#60d522"/>
       <g id="clock-circular-outline" transform="translate(16 16)" opacity="0">
         <g id="Group_5925" data-name="Group 5925">
           <path id="Path_2197" data-name="Path 2197" d="M8.42,0a8.42,8.42,0,1,0,8.42,8.42A8.43,8.43,0,0,0,8.42,0Zm0,15.049A6.629,6.629,0,1,1,15.049,8.42,6.636,6.636,0,0,1,8.42,15.049Z" fill="#e5b61c"/>
           <path id="Path_2198" data-name="Path 2198" d="M49.237,22.073H45.453v-4.55a.693.693,0,1,0-1.387,0v5.243a.693.693,0,0,0,.693.693h4.477a.693.693,0,1,0,0-1.387Z" transform="translate(-36.428 -13.913)" fill="#e5b61c"/>
         </g>
       </g>
     </svg>

   </div>
  );
};
