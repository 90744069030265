import { FormStore } from "src/stores/FormStore";

interface ProfilePhotoFormStoreData {
  profile_image: any;
}

export const profilePhotoFormStore = new FormStore<ProfilePhotoFormStoreData>(
  {
    profile_image: {
      rules: "",
      bindings: "extended"
    }
  },
  {
    options: {
      validateOnChange: true
    },
    hooks: {
      onSuccess: async (form: FormStore) => {
        const values: ProfilePhotoFormStoreData = form.values();
        try {
          form.reInit();
        } catch (e) {
          console.error(`CompanyDetailsFormStore Api Failed: `, e);
          return Promise.reject(`Error calling api`);
        }

        return Promise.resolve();
      },
      onError: form => {
        return Promise.reject(`Incorrect form information`);
      }
    }
  }
);
