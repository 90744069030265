import * as React from 'react';
import * as style from './tableBase.module.scss';
import {Datatype, generateFakeData} from "src/utils/table";
import {DropdownItemData} from "src/components/Dropdown/DropdownBase";
import {TableBase} from "src/components/Table/TableBase";

export const TableDemo: React.FC = () => {
  const defaultColumns = [
    {
      Header: 'Id',
      accessor: 'id'
    },
    {
      Header: 'Last Name',
      id: 'lastName',
      accessor: 'lastName'
    },
    {
      Header: 'Start Date',
      id: 'startDate',
      accessor: 'startDate'
    },
    {
      Header: 'End Date',
      id: 'endDate',
      accessor: 'endDate'
    },
    {
      Header: 'Counts',
      id: 'counts',
      accessor: 'counts'
    }
  ];

  const defaultColumnsStructure = [
    {
      name: 'id',
      type: Datatype.NUMBER
    },
    {
      name: 'firstName',
      type: Datatype.STRING
    },
    {
      name: 'lastName',
      type: Datatype.STRING
    },
    {
      name: 'startDate',
      type: Datatype.STRING
    },
    {
      name: 'endDate',
      type: Datatype.STRING
    },
    {
      name: 'counts',
      type: Datatype.NUMBER
    }
  ];

  const dropdownItems = originalRow => {
    return [
      {
        name: 'Item 1 - OnClick',
        onClick: () => {
          alert('Hello! I am an alert box!!');
        }
      },
      {
        name: 'Item 2 - Get Row',
        onClick: () => {
          console.log('originalRow: ', originalRow);
        }
      },
      {
        name: 'Item 3 - Disabled',
        disabled: true
      }
    ] as unknown as DropdownItemData[];
  };

  return (
    <div className={style.reactTable}>
      <TableBase
        data={generateFakeData({
          columns: defaultColumnsStructure,
          amount: 1000
        })}
        columns={defaultColumns}
        actionButtonItems={dropdownItems}
        tableName={'Candidate'}
        tableTotalNumber={1230}
      />
    </div>
  );
};
