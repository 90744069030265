import * as React from "react";
import * as style from "./uploadImage.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { ProfileIcon } from "src/components/ProfileName/ProfileIcon";
import { ChangeEvent } from "react";
import { Field } from "mobx-react-form";
import { isImageFile } from "src/utils/form";
import * as _ from "lodash";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";

interface UploadImageProps {
  formField: Field;
  uploadText?: string;
  resetText?: string;
}

export const UploadImage: React.FC<UploadImageProps> = observer(props => {
  const { uploadText = "Upload Image", resetText = "Reset Image" } = props;

  const picture = props.formField.value;
  const profilePicture = _.isObject(picture)
    ? URL.createObjectURL(picture)
    : picture;

  const handleOnFileUploadChange = async (e: ChangeEvent<HTMLInputElement>) => {
    e.persist();

    if (!isImageFile(e.target.files[0].name)) {
      // Need to change to Message
      return console.error("Please choose a valid image file.");
    }

    const imgFile = e.target.files ? e.target.files[0] : undefined;

    /**
     * update form field
     */
    props.formField.set("value", imgFile);
  };

  const handleClear = e => {
    e.preventDefault();

    props.formField.clear();
    return;
  };

  return (
    <div className={`${style.uploadImageMain}`}>
      <label
        className={`d-flex align-items-center `}
        htmlFor={!picture && `uploadBtn`}
        onClick={e => picture && handleClear(e)}
      >
        <div
          className={`d-flex justify-content-center align-items-center ${style.uploadImage}`}
        >
          {picture ? (
            <ProfileIcon size={40} imageUrl={profilePicture} />
          ) : (
            <FontAwesomeIcon icon={faUpload} />
          )}
        </div>
        <div className={style.uploadText}>
          {picture ? resetText : uploadText}
        </div>
      </label>

      <input
        className="d-none"
        type="file"
        accept="image/*"
        onChange={handleOnFileUploadChange}
        value=""
        id="uploadBtn"
      />
    </div>
  );
});
