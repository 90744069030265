import React from "react";
import { Router, Redirect } from "react-router";
import { Switch } from "react-router-dom";
import { Helmet } from "react-helmet";
import { createBrowserHistory } from "history";
import { BusinessUserPageBase } from "src/container/BusinessUser/BusinessUserPageBase";
import { Route, RouteInstance } from "src/components/WithRouterStore/Route";
import {
  APP_BUSINESS_DOMAIN,
  APP_CANDIDATE_DOMAIN,
  APP_LOGIN,
  APP_MIDDLEWARE,
  APP_RECOVERY,
  APP_SET_NEW_PASSWORD,
  COMMON_COMPONENT
} from "src/constants/routes";
import { ComponentsHomepage } from "src/components/ComponentsHomepage";
import {} from "src/container/BusinessUser/constants/businessUserRoutes";
import { LoginPage } from "src/container/Authentication/LoginPage/LoginPage";
import { RecoveryPage } from "src/container/Authentication/RecoveryPage/RecoveryPage";
import { CandidatePageBase } from "src/container/Candidate/CandidateUserPageBase";
import { EnterNewPasswordPage } from "src/container/Authentication/RecoveryPage/EnterNewPasswordPage";
import { MiddlewarePage } from "src/container/Authentication/MiddlewarePage/MiddlewarePage";

const App: React.FC = () => {
  const browserHistory = createBrowserHistory();
  return (
    <Router key={Math.random()} history={browserHistory}>
      <Helmet titleTemplate={`%s Eva X App`} defaultTitle={`Eva X`}>
        <meta name="description" content="" />
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Helmet>
      <Switch>
        <Redirect path={"/"} exact={true} to={APP_LOGIN} />
        <RouteInstance
          path={APP_BUSINESS_DOMAIN}
          component={BusinessUserPageBase}
        />
        <RouteInstance path={APP_LOGIN} component={LoginPage} exact />
        <RouteInstance path={[APP_RECOVERY]} component={RecoveryPage} exact />
        <RouteInstance
          path={[APP_SET_NEW_PASSWORD]}
          component={EnterNewPasswordPage}
          exact
        />
        <RouteInstance
          path={[APP_MIDDLEWARE]}
          component={MiddlewarePage}
          exact
        />
        <RouteInstance
          path={APP_CANDIDATE_DOMAIN}
          component={CandidatePageBase}
        />
        {process.env.REACT_APP_SHOW_COMPONENTS_PAGE && (
          <RouteInstance
            path={COMMON_COMPONENT}
            component={ComponentsHomepage}
          />
        )}
      </Switch>
    </Router>
  );
};

export default App;
