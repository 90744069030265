import * as React from "react";
import * as style from "./multiCountColumns.module.scss";
import { Card } from "src/components/Card/Card";
import {CountColumn, CountColumnProps} from "src/components/MultiCountColumn/CountColumn";

interface MultiCountColumnsProps {
  data: CountColumnProps[];
}

export const MultiCountColumns: React.FC<MultiCountColumnsProps> = props => {
  return (
    <Card className={style.card}>
      <div className={`w-100 d-flex justify-content-around align-items-center`}>
        {props.data &&
          props.data.map(item => (
            <CountColumn title={item.title} count={item.count} />
          ))}
      </div>
    </Card>
  );
};
