import * as React from 'react';
import * as _ from 'lodash';
import {TableWithCheckbox, TableWithCheckboxProps} from "src/components/Table/TableWithCheckBox";
import {BackendMeta} from "src/_declare/api";
import {routerStore} from "src/stores/RouterStore";
import {TableDefault} from "src/_declare/table";

interface TableServerSidePaginationProps extends TableWithCheckboxProps {
  loading: boolean;
  data: any[];
  meta: BackendMeta;
}

export const TableServerSidePagination: React.FC<TableServerSidePaginationProps> = props => {
  const fetchData = page => {
    routerStore.pushWithQueryString(routerStore.match.path, {
      params: { ...routerStore.params },
      queryString: {
        ...routerStore.queryString,
        page: page + 1
      }
    });
  };

  const sortData = sortData => {
    routerStore.pushWithQueryString(routerStore.match.path, {
      params: { ...routerStore.params },
      queryString: {
        ...routerStore.queryString,
        sort_by: sortData[0] && sortData[0].id,
        sort_order: sortData[0] && sortData[0].desc === true ? 'DESC' : 'ASC'
      }
    });
  };

  const { data, loading, meta, ...otherProps } = props;

  return (
    <div>
      <TableWithCheckbox
        data={props.data}
        loading={props.loading}
        manual={true}
        onPageChange={fetchData}
        onSortedChange={sortData}
        pageSize={props.data.length < TableDefault.TABLE_PAGE_SIZE ? props.data.length : TableDefault.TABLE_PAGE_SIZE}
        pages={_.get(props.meta, 'last_page', -1)}
        page={props.meta && props.meta.current_page - 1}
        tableTotalNumber={_.get(props.meta, 'total')}
        {...otherProps}
      />
    </div>
  );
};
