import * as React from "react";
import { observer } from "mobx-react";
import { resumeUploadFormStore } from "src/container/Candidate/stores/resumeUploadFormStore";
import { HeaderWithSubHeader } from "src/container/Candidate/components/RegistrationPage/HeaderWithSubHeader";
import { UploadImageLarge } from "src/components/UploadImage/UploadImageLarge";
import { ResumeSvg } from "src/container/Candidate/components/RegistrationPage/ResumeSvg";

interface ResumeUploadStepProps {}

export const ResumeUploadStep: React.FC<ResumeUploadStepProps> = observer(
  props => {
    const resumeField = resumeUploadFormStore.$("resume");
    return (
      <>
        <HeaderWithSubHeader
          title={`Upload Your Resume`}
          subTitle={`Make sure to upload your updated resume`}
          className={`my-3 mb-4`}
        />
        <UploadImageLarge
          formField={resumeField}
          disableOnlyImage={true}
          icon={<ResumeSvg />}
        />
      </>
    );
  }
);
