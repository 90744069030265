import {ApiStore} from 'src/stores/ApiStore';

interface GetForgotPasswordApiStorePayload {
  email: string;
}

interface GetForgotPasswordApiStoreData {

}

class GetForgotPasswordApiStore extends ApiStore<GetForgotPasswordApiStorePayload,
  GetForgotPasswordApiStoreData> {
  constructor() {
    super(
      'api/userManagement/forgotPassword'
    );
  }
}

export const getForgotPasswordApiStore = new GetForgotPasswordApiStore();
