import { NavigationMenuData } from "src/components/SideNavigation/NavigationMenu";
import { authStore } from "src/stores/AuthStore";
import { permissionStore } from "src/stores/PermissionStore";
import { routerStore } from "src/stores/RouterStore";
import { APP_LOGIN } from "src/constants/routes";

export const commonNavigation: NavigationMenuData[] = [
  {
    title: "Logout",
    onClick: () => {
      authStore.removeToken();
      permissionStore.clearStore();
      routerStore.push(APP_LOGIN);
    }
  }
];
