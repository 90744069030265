import * as React from "react";
import * as style from "./checkBox.module.scss";
import { Input, InputProps } from "reactstrap";

export interface CheckBoxProps extends InputProps {
  indeterminate?: boolean;
  label?: string;
  labelClassName?: string;
}

export const CheckBox: React.FC<CheckBoxProps> = props => {
  const {
    indeterminate,
    label,
    labelClassName = "",
    className = "",
    ...inputProps
  } = props;

  return (
    <label className={`${style.container} ${className}`}>
      <Input type="checkbox" {...inputProps} />
      <span
        className={`${style.checkmark} ${
          indeterminate ? style.indeterminate : ""
        }`}
      />
      {label && (
        <div className={`${style.label} ${labelClassName}`}>{label}</div>
      )}
    </label>
  );
};
