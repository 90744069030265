import * as React from "react";
import * as style from "./bottomButton.module.scss";
import {Button, ButtonProps} from "src/components/Button/Button";

interface BottomButtonProps {
  primaryText?: string;
  secondaryText?: string;
  primaryBtnOnProps?: ButtonProps;
  secondaryBtnOnProps?:ButtonProps;
}

export const BottomButton: React.FC<BottomButtonProps> = props => {
  const { primaryText = "Next", secondaryText = "Cancel" } = props;
  return (
    <div className={style.bottomButton}>
      <Button className={style.mainBtn} {...props.primaryBtnOnProps}>
        {primaryText}
      </Button>
      <Button
        classNameContainer={style.secondaryBtn}
        className={style.secondaryBtn}
        color={"transparent"}
        {...props.secondaryBtnOnProps}
      >
        {secondaryText}
      </Button>
    </div>
  );
};
