import {ApiStore} from 'src/stores/ApiStore';

interface CandidateCompanyProjectsApiStorePayload {
  company_id?: number;
}

interface CandidateCompanyProjectsApiStoreData {
  company_project_id: number;
  project_candidate_id: number;
  project_name: string;
  company_name: string;
  invited_date?: any;
  project_tools_count: number;
  completed_count: number;
  completed_percentage: number;
}

class CandidateCompanyProjectsApiStore extends ApiStore<
  CandidateCompanyProjectsApiStorePayload,
  CandidateCompanyProjectsApiStoreData[]
  > {
  constructor() {
    super(
      'api/candidateCompanyProjects'
    );
  }
}

export const candidateCompanyProjectsApiStore = new CandidateCompanyProjectsApiStore();
