import * as React from "react";
import * as style from "../../../../components/Login/stepsForm.module.scss";
import { companyDetailsFormStore } from "src/container/BusinessUser/components/RegistrationPage/store/companyDetailsFormStore";
import { SectionHeader } from "src/components/SectionHeader/SectionHeader";
import { Input } from "src/components/Input/Input";
import { Slider } from "src/components/Slider/Slider";
import { Select } from "src/components/Select/Select";
import { useState } from "react";
import { observer } from "mobx-react";
import { SelectOption } from "src/_declare/select";
import {
  cityFieldOptions,
  countryFieldOptions,
  stateFieldOptions
} from "src/utils/countries";
import * as _ from "lodash";

interface CompanyDetailStepProps {}

export const CompanyDetailStep: React.FC<CompanyDetailStepProps> = observer(
  props => {
    const nameField = companyDetailsFormStore.$("name");
    const companyTypeField = companyDetailsFormStore.$("company_type");
    const industryField = companyDetailsFormStore.$("industry");
    const websiteUrlField = companyDetailsFormStore.$("website_url");
    const companySizeField = companyDetailsFormStore.$("company_size");
    const addressOneField = companyDetailsFormStore.$("address_1");
    const addressTwoField = companyDetailsFormStore.$("address_2");
    const cityField = companyDetailsFormStore.$("city");
    const stateField = companyDetailsFormStore.$("state");
    const countryField = companyDetailsFormStore.$("country");
    const postcodeField = companyDetailsFormStore.$("postcode");

    const companyTypeOptions: SelectOption[] = [
      {
        value: "local",
        label: "Local"
      },
      {
        value: "national",
        label: "National"
      },
      {
        value: "international",
        label: "International"
      }
    ];

    const industryFieldOptions: SelectOption[] = [
      { value: "Aerospace", label: "Aerospace" },
      { value: "Agriculture", label: "Agriculture" },
      { value: "Education", label: "Education" },
      { value: "Energy & Utilities", label: "Energy & Utilities" },
      { value: "Financial Services", label: "Financial Services" },
      { value: "FMCG", label: "FMCG" },
      { value: "Food and Beverages", label: "Food and Beverages" },
      { value: "Healthcare", label: "Healthcare" },
      { value: "Hospitality & Leisure", label: "Hospitality & Leisure" },
      { value: "Industrials", label: "Industrials" },
      { value: "Life Sciences", label: "Life Sciences" },
      {
        value: "Media & Creative Industries",
        label: "Media & Creative Industries"
      },
      { value: "Professional Services", label: "Professional Services" },
      { value: "Public & Social Service", label: "Public & Social Service" },
      { value: "Retail & Ecommerce", label: "Retail & Ecommerce" },
      { value: "Transport & Logistics", label: "Transport & Logistics" },
      { value: "Others", label: "Others" }
    ];

    const [sliderValue, setSliderValue] = useState<number | string>();

    const getSliderValue = (value: number) => {
      companySizeField.set("value", value);
      setSliderValue(value === 500 ? "> 500" : value);
    };

    const checkIfCityEmpty = (selection: SelectOption) => {
      if (stateField.value) {
        const cities: SelectOption[] = cityFieldOptions(
          stateField.value.value,
          countryField.value.shortName
        );

        if (_.isEmpty(cities)) {
          cityField.value = selection;
        } else if (cities.length === 1) {
          cityField.value = cities[0];
        }
      }
    };

    const checkIfStateEmpty = (selection: SelectOption) => {
      const states: SelectOption[] = stateFieldOptions(
        countryField.value.shortName
      );

      if (_.isEmpty(states)) {
        stateField.value = selection;
      } else if (states.length === 1) {
        stateField.value = states[0];
      }
    };

    const onChangeCountry = (selection: SelectOption) => {
      countryField.value = selection;
      stateField.value = undefined;
      cityField.value = undefined;

      checkIfStateEmpty(selection);
      checkIfCityEmpty(selection);
    };

    const onChangeState = (selection: SelectOption) => {
      stateField.value = selection;
      cityField.value = undefined;

      checkIfCityEmpty(selection);
    };

    return (
      <>
        <SectionHeader title={`Company Details`}>
          <Input {...nameField.bind()} />
          <div className={`${style.splitSection}`}>
            <Select
              containerClassName={style.selectContainerStyle}
              {...companyTypeField.bind()}
              options={companyTypeOptions}
            />
            <Select
              containerClassName={style.selectContainerStyle}
              {...industryField.bind()}
              options={industryFieldOptions}
            />
          </div>
          <Input {...websiteUrlField.bind()} />
        </SectionHeader>

        <SectionHeader
          title={`Company Size`}
          rightSection={<div className={style.sliderValue}>{sliderValue}</div>}
        >
          <Slider
            className={style.slider}
            getValue={getSliderValue}
            domain={[10, 500]}
            values={[0]}
            step={10}
          />
        </SectionHeader>

        <SectionHeader title={`Company Address`}>
          <Input {...addressOneField.bind()} />
          <Input {...addressTwoField.bind()} />
          <div className={`${style.splitSection}`}>
            <Select
              containerClassName={style.selectContainerStyle}
              {...countryField.bind()}
              options={countryFieldOptions()}
              onChange={onChangeCountry}
            />
            <Input className={style.inputStyle} {...postcodeField.bind()} />
          </div>
          <div className={`${style.splitSection}`}>
            <Select
              containerClassName={style.selectContainerStyle}
              {...stateField.bind()}
              options={
                countryField.value
                  ? stateFieldOptions(countryField.value.shortName)
                  : []
              }
              onChange={onChangeState}
              isDisabled={!countryField.value}
            />
            <Select
              containerClassName={style.selectContainerStyle}
              {...cityField.bind()}
              options={
                countryField.value &&
                stateField.value &&
                cityFieldOptions(
                  stateField.value.value,
                  countryField.value.shortName
                )
              }
              isDisabled={!countryField.value && !stateField.value}
            />
          </div>
        </SectionHeader>
      </>
    );
  }
);
