import * as React from "react";
import * as style from "./projectCard.module.scss";
import { Card } from "src/components/Card/Card";
import { ProfileIcon } from "src/components/ProfileName/ProfileIcon";
import { ProjectTitle } from "src/components/ProjectCard/ProjectTitle";
import { SectionHeader } from "src/components/SectionHeader/SectionHeader";
import { WaveProgressBar } from "src/components/WaveProgressBar/WaveProgressBar";
import { Button, ButtonProps } from "src/components/Button/Button";
import * as _ from "lodash";
import { ResponsiveSize, useResolution } from "src/utils/responsive";

interface WaveProps {
  activeNumber?: number;
  totalNumber?: number;
  progressText?: string;
}

interface UserDetails {
  name: string;
  imageUrl: string;
}

interface ProjectCardProps {
  title: string;
  subtitle?: string;
  userDetails?: UserDetails[];
  primaryWaveDetails?: WaveProps;
  secondaryWaveDetails?: WaveProps;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  onClickPrimaryButton?: () => void;
  onClickSecondaryButton?: () => void;
  primaryButtonProps?: Partial<ButtonProps>;
  secondaryButtonProps?: Partial<ButtonProps>;
  showAllOnClick?: () => void;
  hideUserSection?: boolean;
  additionalContent?: Element | React.ReactNode;
}

export const ProjectCard: React.FC<ProjectCardProps> = props => {
  const users = props.userDetails;
  const isPhone = useResolution(ResponsiveSize.PHONE_ONLY);

  return (
    <Card className={`${style.projectCard} d-flex flex-column`}>
      <div>
        <ProjectTitle title={props.title} subtitle={props.subtitle} />
      </div>
      <Card className={`${style.innerCard} d-flex flex-column`}>
        <SectionHeader
          title={`Quick Overview`}
          className={`w-100`}
          titleClassName={style.sectionHeader}
        >
          <div
            className={`d-flex ${
              props.primaryWaveDetails && props.secondaryWaveDetails
                ? `justify-content-between`
                : `justify-content-center`
            }  ${style.container}`}
          >
            {props.primaryWaveDetails && (
              <div
                className={`d-flex flex-column justify-content-center align-items-center`}
              >
                <WaveProgressBar
                  showProgress={true}
                  color={"secondary"}
                  size={130}
                  progressText={props.primaryWaveDetails.progressText}
                  activeNumber={props.primaryWaveDetails.activeNumber}
                  secondaryNumber={props.primaryWaveDetails.totalNumber}
                />
                <Button
                  className={style.button}
                  onClick={props.onClickPrimaryButton}
                  {...props.primaryButtonProps}
                >
                  {props.primaryButtonText}
                </Button>
              </div>
            )}
            {props.secondaryWaveDetails && (
              <div
                className={`d-flex flex-column justify-content-center align-items-center`}
              >
                <WaveProgressBar
                  showProgress={true}
                  size={130}
                  progressText={props.secondaryWaveDetails.progressText}
                  activeNumber={props.secondaryWaveDetails.activeNumber}
                  secondaryNumber={props.secondaryWaveDetails.totalNumber}
                />
                <Button
                  className={style.button}
                  color={"secondary"}
                  onClick={props.onClickSecondaryButton}
                  {...props.secondaryButtonProps}
                >
                  {props.secondaryButtonText}
                </Button>
              </div>
            )}
          </div>
          {props.additionalContent && <div>{props.additionalContent}</div>}
        </SectionHeader>
        {!props.hideUserSection && (
          <SectionHeader
            title={`Active Candidate`}
            className={`d-flex flex-column pt-3`}
            titleClassName={style.sectionHeader}
            rightSection={
              <div className={style.showAll} onClick={props.showAllOnClick}>
                Show All
              </div>
            }
          >
            <div
              className={`d-flex justify-content-start pt-2 ${style.profileSection}`}
            >
              {!_.isEmpty(users) ? (
                users.map(item => (
                  <ProfileIcon
                    className={`mr-3`}
                    size={isPhone ? 42.5 : 50}
                    tooltipText={_.get(item, "full_name", undefined)}
                    imageUrl={_.get(item, "image_url", undefined)}
                  />
                ))
              ) : (
                <div className={style.noCandidate}>No candidate found</div>
              )}
            </div>
          </SectionHeader>
        )}
      </Card>
    </Card>
  );
};
