import * as React from "react";
import * as style from "./middlewarePage.module.scss";
import { useEffect } from "react";
import { routerStore } from "src/stores/RouterStore";
import { APP_LOGIN, APP_SET_NEW_PASSWORD } from "src/constants/routes";
import { Message } from "src/components/Message/Message";
import {
  CANDIDATE_DASHBOARD,
  CANDIDATE_REGISTER
} from "src/container/Candidate/constants/candidateRoutes";
import { isCompletedApiStore } from "src/container/Authentication/MiddlewarePage/stores/isCompletedApiStore";
import { useEffectAsync } from "src/utils/hooks";
import { candidateTestCompletionApiStore } from "src/container/Authentication/MiddlewarePage/stores/candidateTestCompletionApiStore";
import { candidateTestStore } from "src/container/stores/candidateTestStore";

interface MiddlewarePageProps {}

export enum STATUS_TYPE {
  SUCCESS = "SUCCESS",
  FAILURE = "FAIL"
}

export enum LINK_TYPE {
  RESET_PASSWORD = "reset_password",
  REGISTRATION = "registration",
  CANDIDATE_TOOL_COMPLETION = "candidate_tool_completion"
}

export const MiddlewarePage: React.FC<MiddlewarePageProps> = props => {
  useEffectAsync(async () => {
    const queryString = routerStore.queryString;
    const type: LINK_TYPE = queryString.type;

    switch (type) {
      case LINK_TYPE.RESET_PASSWORD:
        routerStore.pushWithQueryString(APP_SET_NEW_PASSWORD, {
          queryString: { ...queryString }
        });
        break;
      case LINK_TYPE.REGISTRATION:
        await isCompletedApiStore.callApi({
          token: queryString.token
        });

        if (isCompletedApiStore.data.is_completed) {
          routerStore.push(APP_LOGIN);
        } else {
          routerStore.pushWithQueryString(CANDIDATE_REGISTER, {
            queryString: { ...queryString }
          });
        }

        break;
      case LINK_TYPE.CANDIDATE_TOOL_COMPLETION:
        const status = queryString.status;
        if (status === STATUS_TYPE.SUCCESS) {
          await candidateTestCompletionApiStore.callApi({
            candidate_tool_id: candidateTestStore.candidateToolId
          });
          candidateTestStore.clearDataAndRedirect(true);
        } else {
          candidateTestStore.clearDataAndRedirect(false);
        }

        break;
      default:
        Message.error("No type available in the email.");
        routerStore.push(APP_LOGIN);
    }
  }, []);

  return null;
};
