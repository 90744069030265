import {ApiStore} from 'src/stores/ApiStore';

interface CandidateCompaniesApiStorePayload {

}

interface CandidateCompaniesApiStoreData {
  company_id: number;
  company_name: string;
}

class CandidateCompaniesApiStore extends ApiStore<
  CandidateCompaniesApiStorePayload,
  CandidateCompaniesApiStoreData[]
  > {
  constructor() {
    super(
      'api/candidateCompanies'
    );
  }
}

export const candidateCompaniesApiStore = new CandidateCompaniesApiStore();
