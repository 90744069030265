import { ApiStore } from "src/stores/ApiStore";
import { RestMethods } from "src/stores/api/baseApi";

interface InviteCandidateApiStorePayload {
  company_project_id: number | string;
  candidates: number[];
}

interface InviteCandidateApiStoreData {}

class InviteCandidateApiStore extends ApiStore<
  InviteCandidateApiStorePayload,
  InviteCandidateApiStoreData
  > {
  constructor() {
    super(
      "api/project/:company_project_id/candidateManagement/inviteCandidates",
      {
        requestType: RestMethods.POST
      }
    );
  }
}

export const inviteCandidateApiStore = new InviteCandidateApiStore();
