import {RestMethods} from "src/stores/api/baseApi";
import {ApiStore} from "src/stores/ApiStore";

interface UploadCsvCandidateBulkApiStorePayload {
  company_project_id: number | string;
  file: Blob;
}

interface UploadCsvCandidateBulkApiStoreData {}

class UploadCsvCandidateBulkApiStore extends ApiStore<
  UploadCsvCandidateBulkApiStorePayload,
  UploadCsvCandidateBulkApiStoreData
  > {
  constructor() {
    super('api/project/:company_project_id/candidateManagement/uploadCandidates', {
      requestType: RestMethods.POST
    });
  }
}

export const uploadCsvCandidateBulkApiStore = new UploadCsvCandidateBulkApiStore();
