import * as React from "react";
import * as style from "./candidateTable.module.scss";
import { DropdownItemData } from "src/components/Dropdown/DropdownBase";
import { CandidateProgressBar } from "src/components/CandidateProgressBar/CandidateProgressBar";
import { ResponsiveSize, useResolution } from "src/utils/responsive";
import { ProfileName } from "src/components/ProfileName/ProfileName";
import { useEffectAsync } from "src/utils/hooks";
import {
  projectCandidatesApiStore,
  ProjectCandidatesApiStoreData
} from "src/container/BusinessUser/components/CandidatePage/stores/projectCandidatesApiStore";
import { toJS } from "mobx";
import Moment from "react-moment";
import { routerStore } from "src/stores/RouterStore";
import { inviteCandidateApiStore } from "src/container/BusinessUser/components/CandidatePage/stores/inviteCandidateApiStore";
import { TableServerSidePagination } from "src/components/Table/TableServerSidePagination";
import { observer } from "mobx-react";
import { Message } from "src/components/Message/Message";
import { deleteCandidateApiStore } from "src/container/BusinessUser/components/CandidatePage/stores/deleteCandidateApiStore";
import * as _ from "lodash";
import { candidateStore } from "src/container/BusinessUser/components/CandidatePage/stores/candidateStore";
import { ConfirmationBox } from "src/components/ConfirmationBox/ConfirmationBox";
import { neutralizeWord } from "src/utils";
import { ReportModal } from "src/components/ReportModal/ReportModal";
import { TotalScore } from "src/components/TotalScore/TotalScore";
import { candidateProjectOverviewApiStore } from "src/container/BusinessUser/components/CandidatePage/stores/candidateProjectOverviewApiStore";

enum CandidateStatus {
  NEW = "NEW",
  INVITED = "INVITED",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED"
}

interface CandidateTableProps {}

export const CandidateTable: React.FC<CandidateTableProps> = observer(props => {
  const isPhone = useResolution(ResponsiveSize.PHONE_ONLY);
  const [reportModalIsOpen, toggleReportModalIsOpen] = React.useState(false);
  const [candidateID, getCandidateID] = React.useState(null);

  useEffectAsync(async () => {
    await projectCandidatesApiStore.callApi({
      page: 1,
      ...routerStore.params,
      ...routerStore.queryString,
      per_page: 20
    });
  }, [routerStore.queryString]);

  //Need to refactor this code to make it more minimize
  const getCandidateStatusChecking = row => {
    switch (row.original.candidate_status) {
      case CandidateStatus.NEW:
        return [
          { status: "Invited", complete: false },
          { status: "In Progress", complete: false },
          { status: "Completed", complete: false }
        ];
      case CandidateStatus.INVITED:
        return [
          { status: "Invited", complete: true },
          { status: "In Progress", complete: false },
          { status: "Completed", complete: false }
        ];
      case CandidateStatus.IN_PROGRESS:
        return [
          { status: "Invited", complete: true },
          { status: "In Progress", complete: true },
          { status: "Completed", complete: false }
        ];
      case CandidateStatus.COMPLETED:
        return [
          { status: "Invited", complete: true },
          { status: "In Progress", complete: true },
          { status: "Completed", complete: true }
        ];
    }
  };

  const modalOpen = (candidate_id: number) => {
    toggleReportModalIsOpen(true);
    getCandidateID(candidate_id);
  };

  const defaultColumns = [
    {
      Header: "Candidate Name",
      accessor: "first_name",
      width: isPhone ? 150 : `100%`,
      Cell: row => {
        return (
          <ProfileName
            name={row.original.first_name.concat(" ", row.original.last_name)}
            classProfileName={style.profileName}
            size={32}
            imageUrl={row.original.profile_image_url}
          />
        );
      }
    },
    {
      Header: () => (
        <div
          style={{
            textAlign: "center"
          }}
        >
          Progress Status
        </div>
      ),
      accessor: "candidate_status",
      width: 290,
      show: !isPhone,
      Cell: row => {
        return (
          <CandidateProgressBar
            candidateStatus={getCandidateStatusChecking(row)}
          />
        );
      }
    },
    {
      Header: () => (
        <div
          style={{
            textAlign: "center"
          }}
        >
          Score
        </div>
      ),
      accessor: "overall_score",
      width: 100,
      Cell: row => {
        return (
          <div
            className={`${row.original.overall_score &&
              style.cursor} d-flex justify-content-center`}
            onClick={() => {
              row.original.overall_score &&
                modalOpen(row.original.candidate_id);
            }}
          >
            {row.original.overall_score ? (
              <TotalScore
                primaryNumber={row.original.overall_score}
                secondaryNumber={10}
                primarySize={20}
                secondarySize={15}
              />
            ) : (
              "-"
            )}
          </div>
        );
      }
    },
    {
      Header: "Status",
      accessor: "candidate_status",
      show: isPhone,
      Cell: row => {
        return <div>{neutralizeWord(row.original.candidate_status)}</div>;
      }
    },
    {
      Header: () => (
        <div
          style={{
            textAlign: "center"
          }}
        >
          Last Seen
        </div>
      ),
      accessor: "last_seen",
      width: 100,
      show: !isPhone,
      Cell: row => {
        return row.original.last_seen ? (
          <Moment fromNow>{row.original.last_seen}</Moment>
        ) : (
          <span className={`d-flex justify-content-center`}>-</span>
        );
      }
    }
  ];

  const dropdownItems = (originalRow: ProjectCandidatesApiStoreData) => {
    return (_.compact([
      originalRow.candidate_status ===
        (CandidateStatus.NEW || CandidateStatus.INVITED) && {
        name: "Invite",
        onClick: async () => {
          try {
            await inviteCandidateApiStore.callApi({
              company_project_id: originalRow.company_project_id,
              candidates: [originalRow.candidate_id]
            });
            Message.success("Successfully invited candidate.");
            projectCandidatesApiStore.callApiWithPreviousPayload({
              silentLoading: true
            });
            candidateProjectOverviewApiStore.callApiWithPreviousPayload({
              silentLoading: true
            });
          } catch (e) {
            Message.error("Failed to invite candidate.");
          }
        }
      },
      originalRow.candidate_status ===
        (CandidateStatus.NEW || CandidateStatus.INVITED) && {
        name: "Delete",
        onClick: async () => {
          try {
            await ConfirmationBox.alert(
              `Are you sure you want to delete this candidate ?`,
              {
                confirmBtnOnClick: async () => {
                  await deleteCandidateApiStore.callApi({
                    company_project_id: originalRow.company_project_id,
                    candidates: [originalRow.candidate_id]
                  });
                  Message.success("Successfully delete candidate.");
                  projectCandidatesApiStore.callApiWithPreviousPayload({
                    silentLoading: true
                  });
                },
                confirmBtnText: "Confirm",
                modalHeaderText: "Delete Candidate"
              }
            );
          } catch (e) {
            Message.error("Failed to delete candidate.");
          }
        }
      },
      {
        name: "View Report",
        disabled: !originalRow.is_report_generated,
        onClick: () => {
          modalOpen(originalRow.candidate_id);
        }
      }
    ]) as unknown) as DropdownItemData[];
  };

  const getCheckedValues = (checkedValues: ProjectCandidatesApiStoreData[]) => {
    candidateStore.setCandidateCheck(checkedValues);
  };

  return (
    <div className={style.table}>
      <TableServerSidePagination
        meta={projectCandidatesApiStore.meta}
        loading={projectCandidatesApiStore.isLoading}
        data={toJS(projectCandidatesApiStore.data)}
        columns={defaultColumns}
        actionButtonItems={dropdownItems}
        checkAccessor={"candidate_id"}
        getCheckedValues={getCheckedValues}
        resizable={false}
      />
      <ReportModal
        toggle={() => toggleReportModalIsOpen(false)}
        isOpen={reportModalIsOpen}
        candidate_id={candidateID}
      />
    </div>
  );
});
