import * as React from 'react';
import * as style from './progressBar.module.scss';
import { Progress, ProgressProps } from 'reactstrap';

export interface ProgressBarProps extends ProgressProps {
  value: number;
  className?: string;
}

export const ProgressBar: React.FC<ProgressBarProps> = props => {
  return (
    <div className={`${style.progressBarMain} ${props.className}`}>
      <Progress {...props}>{props.children}</Progress>
    </div>
  );
};
