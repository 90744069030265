import { ApiStore } from "src/stores/ApiStore";
import { RestMethods } from "src/stores/api/baseApi";

export interface CompanyDetails {
  name: string;
  company_type: string;
  industry: string;
  website_url: string;
  company_size: string;
  address_1: string;
  address_2: string;
  city: string;
  state: string;
  country: string;
  postcode: string;
  about_us: string;
  signup_motivation: string;
}

export interface CreateBusinessUserApiStorePayload {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  password_confirmation: string;
  country_code: string | number;
  contact_no: string;
  company_details: CompanyDetails;
}


export interface CompanyDetail {
  id: number;
  company_id: number;
  company_type: string;
  industry: string;
  website_url: string;
  company_size: number;
  address_1: string;
  address_2: string;
  city: string;
  state: string;
  country: string;
  postcode: string;
  about_us: string;
  signup_motivation: string;
  created_at: string;
  updated_at: string;
}

export interface Company {
  id: number;
  name: string;
  logo_path?: any;
  manager_user_id: number;
  created_at: string;
  updated_at: string;
  company_detail: CompanyDetail;
}

export interface User {
  email: string;
  first_name: string;
  last_name: string;
  verified: boolean;
  updated_at: string;
  created_at: string;
  id: number;
  full_name: string;
  company: Company;
}

export interface CreateBusinessUserApiStoreData {
  token: string;
  user: User;
}

class CreateBusinessUserApiStore extends ApiStore<
  CreateBusinessUserApiStorePayload,
  CreateBusinessUserApiStoreData
> {
  constructor() {
    super("api/userManagement/createBusinessUser", {
      requestType: RestMethods.POST
    });
  }
}

export const createBusinessUserApiStore = new CreateBusinessUserApiStore();
