import {ApiStore} from "src/stores/ApiStore";


interface DownloadStandardCsvTemplateApiStorePayload {
  company_project_id: number | string;
}

interface DownloadStandardCsvTemplateApiStoreData {}

class DownloadStandardCsvTemplateApiStore extends ApiStore<
  DownloadStandardCsvTemplateApiStorePayload,
  string
  > {
  constructor() {
    super('api/project/:company_project_id/candidateManagement/downloadCandidateCsvTemplate');
  }
}

export const downloadStandardCsvTemplateApiStore = new DownloadStandardCsvTemplateApiStore();
