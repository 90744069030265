import * as React from "react";
import * as style from "./waveProgressBar.module.scss";
import {
  CircleSvg,
  CircleSvgProps
} from "src/components/WaveProgressBar/CircleSvg";
import { CircleWave } from "src/components/WaveProgressBar/CircleWave";
import * as _ from "lodash";

export interface WaveProgressBarProps extends CircleSvgProps {
  innerClassName?: string;
  outerClassName?: string;
  secondaryNumber?: number;
  activeNumber?: number;
  showProgress?: boolean;
  progressText?: string;
}

export const WaveProgressBar: React.FC<WaveProgressBarProps> = props => {
  const {
    innerClassName,
    outerClassName,
    activeNumber = 0,
    secondaryNumber = 0,
    showProgress,
    ...otherProps
  } = props;
  const percentage = (activeNumber / secondaryNumber) * 100;
  return (
    <div>
      <CircleSvg
        {...otherProps}
        className={outerClassName}
        percentage={percentage}
      >
        <CircleWave
          {...otherProps}
          className={innerClassName}
          percentage={percentage}
        >
          <div className={style.content}>
            <span
              className={`${style.primaryText} ${props.color === "secondary" &&
                style.secondaryColor}`}
            >
              {activeNumber}
            </span>
            <span className={style.separator}>/</span>
            <span>{secondaryNumber}</span>
          </div>
        </CircleWave>
      </CircleSvg>
      {showProgress && (
        <div className={`${style.progressContent} text-center w-100`}>
          <span className={style.progressFont}>
            {props.progressText ? (
              props.progressText
            ) : (
              <>
                Progress:
                <span className={style.progress}>
                  {` ${_.round(percentage ? percentage : 0, 0)}`}%
                </span>
              </>
            )}
          </span>
        </div>
      )}
    </div>
  );
};
