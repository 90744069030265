import * as React from 'react';
import * as style from './dropdownSplitButton.module.scss';

import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import {DropdownBase, DropdownBaseProps} from "src/components/Dropdown/DropdownBase";
import {Button, ButtonProps} from "src/components/Button/Button";

interface DropdownSplitButtonProps extends DropdownBaseProps {
  buttonProps?: ButtonProps;
  classNameDropdown?: string;
}

export const DropdownSplitButton: React.FC<DropdownSplitButtonProps> = props => {
  const [dropdownSplitIsOpen, setDropdownSplitIsOpen] = useState<boolean>(false);
  const { children, ...otherProps } = props;
  return (
    <div className={style.dropdownButton}>
      <DropdownBase
        {...otherProps}
        dropdownProps={{
          isOpen: dropdownSplitIsOpen,
          toggle: () => setDropdownSplitIsOpen(!dropdownSplitIsOpen)
        }}
        additionalContent={
          <Button className={style.button} id={'caret'} color={'primary'} {...props.buttonProps}>
            {children}
          </Button>
        }
      >
        <Button color={'primary'} className={`${style.caretBtn} ${props.classNameDropdown}`}>
          <FontAwesomeIcon icon={faChevronDown} className={style.chevronDownIcon} />
        </Button>
      </DropdownBase>
    </div>
  );
};
