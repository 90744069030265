import * as React from "react";
import * as style from "./loading.module.scss";
import { faCircleNotch} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface LoadingProps {
  isLoading: boolean;
}

export const Loading: React.FC<LoadingProps> = (props: any) => {
  return props.isLoading ? (
    <div className={`w-100 h-100 ${style.loading}`}>
      <FontAwesomeIcon icon={faCircleNotch} spin={true} />
    </div>
  ) : (
    props.children
  );
};
