import * as React from "react";
import * as style from "./candidateProgressBar.module.scss";

export interface CandidateProgressBarSvgProps {
  complete?: boolean
  status?: string
}

export const CandidateProgressBarSvg: React.FC<CandidateProgressBarSvgProps> = props => {
  const {status, complete} = props;
  return (
    <div className={style.candidateProgressBar}>
      <span className={style.text}>{status}</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="66.84"
        height="3"
        viewBox="0 0 66.84 3"
      >
        <rect
          id="Rectangle_4362"
          data-name="Rectangle 4362"
          width="66.84"
          height="3"
          rx="1.5"
          className={complete ? style.fillComplete : style.fillIncomplete}
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        className={style.circle}
      >
        <path
          id="Path_492"
          data-name="Path 492"
          d="M6,0A6,6,0,1,1,0,6,6,6,0,0,1,6,0Z"
          transform="translate(1 1)"
          fill="none"
          className={complete ? style.strokeComplete : style.strokeIncomplete }
          stroke-width="2"
        />
      </svg>
    </div>
  );
};
