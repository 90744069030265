import { action, observable } from "mobx";
import { routerStore } from "src/stores/RouterStore";
import { Message } from "src/components/Message/Message";
import { ChangeEvent } from "react";
import * as Papa from "papaparse";
import { uploadCSVFormStore } from "src/container/BusinessUser/components/CandidatePage/stores/uploadCSVFormStore";
import { readFileAsText } from "src/utils";
import { downloadStandardCsvTemplateApiStore } from "src/container/BusinessUser/components/CandidatePage/AddCandidate/stores/downloadStandardCSVTemplateApiStore";
import { saveAs } from "file-saver";
import {
  projectCandidatesApiStore,
  ProjectCandidatesApiStoreData
} from "src/container/BusinessUser/components/CandidatePage/stores/projectCandidatesApiStore";
import { inviteCandidateApiStore } from "src/container/BusinessUser/components/CandidatePage/stores/inviteCandidateApiStore";

class CandidateStore {
  @observable
  candidateUploadBulkPreviewModalIsOpen: boolean = false;

  @observable
  candidateChecked: ProjectCandidatesApiStoreData[];

  @action
  setCandidateUploadBulkPreviewModalIsOpen = (bool: boolean) => {
    this.candidateUploadBulkPreviewModalIsOpen = bool;
  };

  @action
  setCandidateCheck = (checkValues: ProjectCandidatesApiStoreData[]) => {
    this.candidateChecked = checkValues;
  };

  inviteMultipleCandidate = async () => {
    const candidateID = this.candidateChecked.map(
      (item: ProjectCandidatesApiStoreData) => {
        return item.candidate_id;
      }
    );

    try {
      await inviteCandidateApiStore.callApi({
        ...routerStore.params,
        candidates: candidateID
      });
      Message.success("Successfully invited candidates.");
      projectCandidatesApiStore.callApiWithPreviousPayload({
        silentLoading: true
      });
    } catch (e) {
      Message.error("Failed to invite candidates.");
    }
  };

  downloadCandidateBulkUploadTemplate = async () => {
    try {
      await downloadStandardCsvTemplateApiStore.callApi({
        ...routerStore.params
      });
      if (downloadStandardCsvTemplateApiStore.successResponse) {
        const blob = new Blob(
          [downloadStandardCsvTemplateApiStore.successResponse],
          {
            type: "text/csv;charset=utf-8"
          }
        );
        saveAs(blob, "candidateBulkUploadTemplate.csv");
        Message.success("Successfully downloaded CSV template");
      }
    } catch (error) {
      Message.error(downloadStandardCsvTemplateApiStore.failureResponse);
    }
  };

  uploadCandidateBulkUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    e.persist();

    const csvFile = e.target.files ? e.target.files[0] : undefined;

    // Update form fields
    uploadCSVFormStore.$("csv").set("value", csvFile);

    if (csvFile) {
      const fileContent = (await readFileAsText(csvFile)) as string;
      const parsedCsv = Papa.parse(fileContent, {
        header: true,
        skipEmptyLines: true
      });

      // Update parsed content to show in table in preview modal
      await uploadCSVFormStore.updateParsedContent(parsedCsv);

      // Show preview modal
      candidateStore.setCandidateUploadBulkPreviewModalIsOpen(true);
    }
  };
}

export const candidateStore = new CandidateStore();
