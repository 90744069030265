import * as React from "react";
import * as style from "./navigationMenu.module.scss";
import { routerStore } from "src/stores/RouterStore";
import { isCurrentRoute, isCurrentRouteWindow } from "src/utils";
import { observer } from "mobx-react";

export interface NavigationMenuData {
  title: string;
  path?: string;
  onClick?: () => void;
  icon?: Element | React.ReactNode;
}

interface NavigationMenuProps {
  data: NavigationMenuData[];
}

export const NavigationMenu: React.FC<NavigationMenuProps> = observer(props => {
  return (
    <div className={style.navigationMenu}>
      {props.data &&
        props.data.map((item, index) => (
          <div
            key={index}
            onClick={() => {
              item.onClick && item.onClick();
              item.path && routerStore.push(item.path);
            }}
            className={`${style.navigationItem} ${isCurrentRoute(item.path) &&
              style.active}`}
          >
            {item.icon} {item.title}
          </div>
        ))}
    </div>
  );
});
