import * as React from "react";
import * as style from "./testList.module.scss";
import { TestListIcon } from "src/components/TestListTable/TestListIcon";
import { Button, ButtonProps } from "src/components/Button/Button";
import { CandidateToolStatus } from "src/container/Candidate/components/MyTestPage/stores/candidateProjectOverviewApiStore";

export interface TestListData {
  title?: string;
  status?: CandidateToolStatus;
  estimation_time?: string;
  buttonText?: string;
  buttonProps?: ButtonProps;
  isCompleted?: boolean;
}

interface TestListProps {
  isLast?: boolean;
  data: TestListData;
}

export const TestList: React.FC<TestListProps> = props => {
  return (
    <div
      className={`${style.testList} ${props.data.isCompleted &&
        style.isCompleted}`}
    >
      <div className={style.firstContent}>
        <TestListIcon
          name={props.data.title}
          status={props.data.status}
          isCompleted={props.data.isCompleted}
        />
      </div>
      <div className={style.secondContent}>{props.data.estimation_time}</div>
      {!props.data.isCompleted && (
        <div className={style.thirdContent}>
          <Button className={style.button} {...props.data.buttonProps}>
            {props.data.buttonText}
          </Button>
        </div>
      )}
      {!props.isLast && <div className={style.line} />}
    </div>
  );
};
