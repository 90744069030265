import { ApiStore } from "src/stores/ApiStore";
import { RestMethods } from "src/stores/api/baseApi";

interface DeleteCandidateApiStorePayload {
  company_project_id: number | string;
  candidates: number[];
}

interface DeleteCandidateApiStoreData {}

class DeleteCandidateApiStore extends ApiStore<
  DeleteCandidateApiStorePayload,
  DeleteCandidateApiStoreData
> {
  constructor() {
    super(
      "api/project/:company_project_id/candidateManagement/deleteCandidates",
      {
        requestType: RestMethods.POST
      }
    );
  }
}

export const deleteCandidateApiStore = new DeleteCandidateApiStore();
