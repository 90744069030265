import * as React from "react";
import * as style from "./resumeSvg.module.scss";

interface ResumeSvgProps {}

export const ResumeSvg: React.FC<ResumeSvgProps> = props => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="120"
        height="120"
        viewBox="0 0 120 120"
      >
        <g
          id="Group_6183"
          data-name="Group 6183"
          transform="translate(1015 17686)"
        >
          <g
            id="Group_6182"
            data-name="Group 6182"
            transform="translate(-296 -13688)"
          >
            <g id="Group_6181" data-name="Group 6181">
              <g
                id="Ellipse_1516"
                data-name="Ellipse 1516"
                transform="translate(-719 -3998)"
                fill="none"
                stroke="#687a92"
                strokeWidth="2"
              >
                <circle cx="60" cy="60" r="60" stroke="none" />
                <circle cx="60" cy="60" r="59" fill="none" />
              </g>
              <g
                id="professional-profile-with-image"
                transform="translate(-693.492 -3962)"
              >
                <g
                  id="Group_6180"
                  data-name="Group 6180"
                  transform="translate(14.492)"
                >
                  <path
                    id="Path_2236"
                    data-name="Path 2236"
                    d="M49.954,0H19.285a4.8,4.8,0,0,0-4.793,4.792V43.131a4.8,4.8,0,0,0,4.793,4.791H49.954a4.8,4.8,0,0,0,4.793-4.791V4.793A4.8,4.8,0,0,0,49.954,0Zm2.877,43.131a2.879,2.879,0,0,1-2.877,2.876H19.285a2.878,2.878,0,0,1-2.875-2.876V4.793a2.878,2.878,0,0,1,2.875-2.875H49.954a2.878,2.878,0,0,1,2.875,2.875V43.131h0Z"
                    transform="translate(-14.492)"
                    fill="#687b91"
                  />
                  <path
                    id="Path_2237"
                    data-name="Path 2237"
                    d="M70.151,79.212H49.939a.881.881,0,0,0,0,1.762H70.15a.881.881,0,0,0,0-1.762Z"
                    transform="translate(-39.916 -58.259)"
                    fill="#687b91"
                  />
                  <path
                    id="Path_2238"
                    data-name="Path 2238"
                    d="M70.151,94.386H49.939a.881.881,0,0,0,0,1.761H70.15a.881.881,0,0,0,0-1.761Z"
                    transform="translate(-39.916 -69.419)"
                    fill="#687b91"
                  />
                  <path
                    id="Path_2239"
                    data-name="Path 2239"
                    d="M70.151,109.555H49.939a.881.881,0,0,0,0,1.761H70.15a.881.881,0,0,0,0-1.761Z"
                    transform="translate(-39.916 -80.576)"
                    fill="#687b91"
                  />
                  <path
                    id="Path_2240"
                    data-name="Path 2240"
                    d="M98.244,124.728H88.137a.881.881,0,0,0,0,1.762H98.244a.881.881,0,0,0,0-1.762Z"
                    transform="translate(-68.009 -91.735)"
                    fill="#687b91"
                  />
                  <path
                    id="Path_2241"
                    data-name="Path 2241"
                    d="M106.674,154.693H99.6a.88.88,0,0,0,0,1.76h7.075a.88.88,0,0,0,0-1.76Z"
                    transform="translate(-76.439 -113.774)"
                    fill="#687b91"
                  />
                  <path
                    id="Path_2242"
                    data-name="Path 2242"
                    d="M61.02,33.073c0-1.666-1.918-3.913-4.507-4.57a4.4,4.4,0,0,0,1.827-3.458,3.3,3.3,0,1,0-6.608,0A4.413,4.413,0,0,0,53.557,28.5c-2.585.656-4.5,2.9-4.5,4.57C49.054,35.041,61.02,35.041,61.02,33.073Zm-6.25-3.611h-.015l-.547-.628a2.336,2.336,0,0,0,1.658,0l-.549.627H55.3l1.412,3.411-1.679,1.675-1.677-1.675Z"
                    transform="translate(-39.91 -15.989)"
                    fill="#687b91"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};
