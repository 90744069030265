import * as React from 'react';
import {observer} from "mobx-react";
import {LoginContainer} from "src/components/Login/LoginContainer";
import {candidateRegistrationStore, Steps} from "src/container/Candidate/stores/candidateRegistrationStore";
import * as style from "../../../../components/Login/stepsForm.module.scss";
import {CandidateDetailStep} from "src/container/Candidate/components/RegistrationPage/CandidateDetailStep";
import {ProfilePhotoUploadStep} from "src/container/Candidate/components/RegistrationPage/ProfilePhotoUploadStep";
import {ResumeUploadStep} from "src/container/Candidate/components/RegistrationPage/ResumeUploadStep";

interface CandidateRegistrationProps {

}

export const CandidateRegistration: React.FC<CandidateRegistrationProps> = observer((props) => {

  const getContent = () => {
    switch (candidateRegistrationStore.step) {
      case Steps.STEP1:
        return <ProfilePhotoUploadStep />;
      case Steps.STEP2:
        return <CandidateDetailStep />;
      case Steps.STEP3:
        return <ResumeUploadStep />;
    }
  };


  return (
    <LoginContainer
      progressText={`Step ${candidateRegistrationStore.step} of 3`}
      progressValue={candidateRegistrationStore.getProgressPercentage}
      isLoading={candidateRegistrationStore.isCompleted}
      primaryText={
        candidateRegistrationStore.step === Steps.STEP3 ? "Complete" : "Next"
      }
      primaryBtnOnProps={{
        onClick: candidateRegistrationStore.onClickNext,
        disabled: !candidateRegistrationStore.isStepValidated
      }}
      secondaryText={
        candidateRegistrationStore.step === Steps.STEP1 ? "Cancel" : "Back"
      }
      secondaryBtnOnProps={{
        onClick: candidateRegistrationStore.onClickBack
      }}
    >
      <div
        className={`${style.content}`}
        key={candidateRegistrationStore.step}
      >
        {getContent()}
      </div>
    </LoginContainer>
  );
});
