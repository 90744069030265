import {ApiStore} from 'src/stores/ApiStore';
import {RestMethods} from "src/stores/api/baseApi";

interface UpdateCandidateToolToInProgressApiStorePayload {
  candidate_tool_id: string | number
}

interface UpdateCandidateToolToInProgressApiStoreData {

}

class UpdateCandidateToolToInProgressApiStore extends ApiStore<
  UpdateCandidateToolToInProgressApiStorePayload,
  UpdateCandidateToolToInProgressApiStoreData[]
  > {
  constructor() {
    super(
      'api/candidateManagement/updateCandidateToolToInProgress', {
        requestType: RestMethods.POST
      }
    );
  }
}

export const updateCandidateToolToInProgressApiStore = new UpdateCandidateToolToInProgressApiStore();
