import { action, computed, observable } from "mobx";
import { userDetailsFormStore } from "src/container/BusinessUser/components/RegistrationPage/store/userDetailsFormStore";
import { companyDetailsFormStore } from "src/container/BusinessUser/components/RegistrationPage/store/companyDetailsFormStore";
import { miscellaneousDetailsFormStore } from "src/container/BusinessUser/components/RegistrationPage/store/miscellaneousDetailsFormStore";
import { routerStore } from "src/stores/RouterStore";
import {
  BUSINESS_USER_DASHBOARD,
  BUSINESS_USER_PROJECT
} from "src/container/BusinessUser/constants/businessUserRoutes";
import { createBusinessUserApiStore } from "src/container/BusinessUser/components/RegistrationPage/store/createBusinessUserApiStore";
import { Message } from "src/components/Message/Message";
import * as _ from "lodash";
import { APP_LOGIN } from "src/constants/routes";
import { updateCompanyDetailsApiStore } from "src/container/BusinessUser/components/RegistrationPage/store/updateCompanyDetailsApiStore";
import { authStore } from "src/stores/AuthStore";
import { getApiStoreError } from "src/utils";

export enum Steps {
  STEP1 = 1,
  STEP2 = 2,
  STEP3 = 3
}

class BusinessRegistrationStore {
  @observable
  step: Steps = 1;

  @observable
  isCompleted: boolean = false;

  @action
  setStep = (step: Steps) => {
    this.step = step;
  };

  @action
  setIsCompleted = (bool: boolean) => {
    this.isCompleted = bool;
  };

  @computed
  get isStepValidated() {
    switch (this.step) {
      case Steps.STEP1:
        return userDetailsFormStore.isValid;
      case Steps.STEP2:
        return companyDetailsFormStore.isValid;
      case Steps.STEP3:
        return miscellaneousDetailsFormStore.isValid;
    }
  }

  @action
  onClickNext = () => {
    if (this.step !== Steps.STEP3) {
      this.setStep(this.step + 1);
    } else {
      this.submitRegister();
    }
  };

  @action
  onClickBack = () => {
    if (this.step === Steps.STEP1) {
      routerStore.push(APP_LOGIN);
    } else {
      this.setStep(this.step - 1);
    }
  };

  @computed
  get getProgressPercentage() {
    return this.isCompleted ? 100 : Math.floor((this.step - 1) * (100 / 3));
  }

  submitRegister = async () => {
    this.setIsCompleted(true);

    await _.delay(async () => {
      try {
        const userDetailsFormValues = userDetailsFormStore.values();
        const companyDetailsFormValues = companyDetailsFormStore.values();
        const miscellaneousDetailsFormValues = miscellaneousDetailsFormStore.values();

        await createBusinessUserApiStore.callApi({
          first_name: userDetailsFormValues.first_name,
          last_name: userDetailsFormValues.last_name,
          email: userDetailsFormValues.email,
          password: userDetailsFormValues.password,
          password_confirmation: userDetailsFormValues.password_confirmation,
          country_code: userDetailsFormValues.country_code.value,
          contact_no: userDetailsFormValues.contact_no,
          company_details: {
            name: companyDetailsFormValues.name,
            company_type: companyDetailsFormValues.company_type.value as string,
            industry: companyDetailsFormValues.industry.value as string,
            website_url: companyDetailsFormValues.website_url,
            company_size: companyDetailsFormValues.company_size,
            address_1: companyDetailsFormValues.address_1,
            address_2: companyDetailsFormValues.address_2,
            city: companyDetailsFormValues.city.value as string,
            postcode: companyDetailsFormValues.postcode,
            state: companyDetailsFormValues.state.value as string,
            country: companyDetailsFormValues.country.value as string,
            about_us: miscellaneousDetailsFormValues.about_us,
            signup_motivation: miscellaneousDetailsFormValues.signup_motivation
          }
        });

        authStore.setToken(createBusinessUserApiStore.data.token);

        await updateCompanyDetailsApiStore.callApiWithFormData({
          company_id: createBusinessUserApiStore.data.user.company.id,
          logo: userDetailsFormValues.logo
        });

        routerStore.push(BUSINESS_USER_DASHBOARD);

        // Succeed registering user
        Message.success("Successfully registered");
      } catch (e) {
        console.error("Error registering: ", e);

        Message.error(
          getApiStoreError(
            createBusinessUserApiStore,
            "Error registering business user."
          )
        );

        this.setIsCompleted(false);
      }
    }, 600);
  };
}

export const businessRegistrationStore = new BusinessRegistrationStore();
