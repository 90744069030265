import * as React from "react";
import * as style from "../../../../components/DashboardLayout/dashboardLayout.module.scss";
import { SectionHeaderLarge } from "src/components/SectionHeader/SectionHeaderLarge";
import { PromoSection } from "src/components/PromoSection/PromoSection";
import { CountColumnProps } from "src/components/MultiCountColumn/CountColumn";
import { MultiCountColumns } from "src/components/MultiCountColumn/MultiCountColumns";
import { DashboardLayout } from "src/components/DashboardLayout/DashboardLayout";
import { ProjectCard } from "src/components/ProjectCard/ProjectCard";
import { GridLayout } from "src/components/GridLayout/GridLayout";
import * as _ from "lodash";
import { useEffectAsync } from "src/utils/hooks";
import { companyOverviewApiStore } from "src/container/BusinessUser/stores/companyOverviewApiStore";
import { observer } from "mobx-react";
import {
  companyProjectsApiStore,
  CompanyProjectsApiStoreData
} from "src/container/BusinessUser/stores/companyProjectsApiStore";
import { routerStore } from "src/stores/RouterStore";
import {
  BUSINESS_USER_DASHBOARD,
  BUSINESS_USER_PROJECT_CANDIDATE
} from "src/container/BusinessUser/constants/businessUserRoutes";
import { Loading } from "src/components/Loading/Loading";
import { NoData } from "src/components/NoData/NoData";
import * as candidateStyle from "src/container/Candidate/components/CandidateDashboardPage/candidateDashboardPage.module.scss";
import { ResponsiveSize, useResolution } from "src/utils/responsive";

interface DashboardPageProps {}

export const DashboardPage: React.FC<DashboardPageProps> = observer(props => {
  const isPhone = useResolution(ResponsiveSize.PHONE_ONLY);

  useEffectAsync(async () => {
    await companyOverviewApiStore.callApi();
    await companyProjectsApiStore.callApi();
  }, []);

  const overviewColumn: CountColumnProps[] = [
    {
      title: `Projects`,
      count: companyOverviewApiStore.data.projects_count || 0
    },
    {
      title: `Purchased`,
      count: companyOverviewApiStore.data.purchased_slots || 0
    },
    {
      title: `Invited`,
      count: companyOverviewApiStore.data.invited_candidates || 0
    },
    {
      title: `Completed`,
      count: companyOverviewApiStore.data.completed_candidates || 0
    }
  ];

  return (
    <DashboardLayout>
      <div className={`w-100 d-flex justify-content-between`}>
        <SectionHeaderLarge
          title={`Usage Overview`}
          className={style.overviewLeftSection}
        >
          <MultiCountColumns data={overviewColumn} />
        </SectionHeaderLarge>
        <SectionHeaderLarge
          title={`What's New`}
          className={style.overviewRightSection}
        >
          <PromoSection
            promoImageSource={`https://yingcomms.com/wp-content/uploads/2019/03/0dada2ef-shutterstock_506137132.jpg`}
          />
        </SectionHeaderLarge>
      </div>
      <div className={`mt-4`}>
        <SectionHeaderLarge title={`Active Project`}>
          <Loading isLoading={companyProjectsApiStore.isLoading}>
            {companyProjectsApiStore.isSuccess &&
              companyProjectsApiStore.hasList && (
                <GridLayout>
                  {companyProjectsApiStore.data.map(
                    (item: CompanyProjectsApiStoreData) => {
                      return (
                        <ProjectCard
                          title={item.project_name}
                          primaryWaveDetails={{
                            totalNumber: item.allocated_slots,
                            activeNumber: item.invited_candidates,
                            progressText: "Available Slot"
                          }}
                          secondaryWaveDetails={{
                            totalNumber: item.invited_candidates,
                            activeNumber: item.completed_candidates
                          }}
                          primaryButtonText={`Top Up`}
                          secondaryButtonText={`View Details`}
                          userDetails={item.active_candidates}
                          primaryButtonProps={{ disabled: true }}
                          showAllOnClick={() =>
                            routerStore.push(BUSINESS_USER_PROJECT_CANDIDATE, {
                              company_project_id: item.company_project_id
                            })
                          }
                          onClickSecondaryButton={() =>
                            routerStore.push(BUSINESS_USER_PROJECT_CANDIDATE, {
                              company_project_id: item.company_project_id
                            })
                          }
                        />
                      );
                    }
                  )}
                </GridLayout>
              )}
            {companyProjectsApiStore.isSuccess &&
              !companyProjectsApiStore.hasList && (
                <NoData className={candidateStyle.noData} showCard={true} />
              )}
          </Loading>
        </SectionHeaderLarge>
      </div>
    </DashboardLayout>
  );
});
