import * as React from "react";
import * as style from "./sideNavigationUsage.module.scss";
import { PageBase } from "src/components/PageBase/PageBase";
import { SideNavigation } from "src/components/SideNavigation/SideNavigation";
import { NavigationMenuData } from "src/components/SideNavigation/NavigationMenu";
import { APP_BUSINESS_DOMAIN } from "src/constants/routes";
import { BUSINESS_USER_PROJECT } from "src/container/BusinessUser/constants/businessUserRoutes";

interface SideNavigationUsageProps {}

export const SideNavigationUsage: React.FC<SideNavigationUsageProps> = props => {
  const menuItems: NavigationMenuData[] = [
    {
      title: `Home`,
      path: window.location.pathname
    },
    {
      title: `My Project`,
      path: '#'
    }
  ];

  return <SideNavigation menuItems={menuItems}/>;
};
