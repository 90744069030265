import * as React from "react";
import * as style from "./breadcrumb.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import * as _ from "lodash";

export interface BreadcrumbItem {
  text: string;
  onClick?: () => void;
}

interface BreadcrumbProps {
  contents?: BreadcrumbItem[];
  separator?: BreadcrumbItem;
  className?: string;
}

export const Breadcrumb: React.FC<BreadcrumbProps> = ({
  contents,
  className,
  separator
}) => {
  return (
    <div className={`${className} ${style.breadcrumb}`}>
      {contents &&
        _.map(contents, (content, index) => {
          const isLast = index === contents.length - 1;

          return (
            <div key={index} className={style.breadcrumbItem}>
              {index !== 0 &&
                (separator || (
                  <FontAwesomeIcon icon={faChevronRight} className={"mx-2"} />
                ))}
              <div
                className={`${isLast && style.last} ${content.onClick && style.clickable}`}
                onClick={content.onClick}
              >
                {content.text}
              </div>
            </div>
          );
        })}
    </div>
  );
};
