import { ApiStore } from "src/stores/ApiStore";
import { RestMethods } from "src/stores/api/baseApi";

interface UpdateCompanyDetailsApiStorePayload {
  logo: File;
  company_id: number | string;
}

interface UpdateCompanyDetailsApiStoreData {}

class UpdateCompanyDetailsApiStore extends ApiStore<
  UpdateCompanyDetailsApiStorePayload,
  UpdateCompanyDetailsApiStoreData
> {
  constructor() {
    super("api/updateCompanyDetails", {
      requestType: RestMethods.POST
    });
  }
}

export const updateCompanyDetailsApiStore = new UpdateCompanyDetailsApiStore();
