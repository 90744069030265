import * as React from "react";
import * as style from "./dropdownButton.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import {
  DropdownBase,
  DropdownBaseProps
} from "src/components/Dropdown/DropdownBase";
import { Button, ButtonProps } from "src/components/Button/Button";

interface DropdownButtonProps extends DropdownBaseProps {
  buttonProps?: ButtonProps;
}

export const DropdownButton: React.FC<DropdownButtonProps> = props => {
  return (
    <div className={style.dropdownButton}>
      <DropdownBase {...props}>
        <Button color={"secondary"} {...props.buttonProps}>
          {props.children}
          <FontAwesomeIcon
            icon={faChevronDown}
            className={style.chevronDownIcon}
          />
        </Button>
      </DropdownBase>
    </div>
  );
};
