import { ApiStore } from "src/stores/ApiStore";

interface CompanyOverviewApiStorePayload {

}

interface CompanyOverviewApiStoreData {
  id: number;
  name: string;
  projects_count: number;
  purchased_slots: number;
  invited_candidates: number;
  completed_candidates: number;
  in_progress_candidates: number;
}

class CompanyOverviewApiStore extends ApiStore<
  CompanyOverviewApiStorePayload,
  CompanyOverviewApiStoreData
  > {
  constructor() {
    super("api/companyOverview");
  }
}

export const companyOverviewApiStore = new CompanyOverviewApiStore();
