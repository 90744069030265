import * as React from "react";
import * as style from "./projectOverviewCarousel.module.scss";
import {
  ProjectOverview,
  ProjectOverviewProps
} from "src/container/BusinessUser/components/CandidatePage/ProjectOverviewCarousel/ProjectOverview";
import { Loading } from "src/components/Loading/Loading";

interface ProjectOverviewCarouselProps extends Partial<ProjectOverviewProps> {
  className?: string;
  showHeader?: boolean;
  header?: string;
}

export const ProjectOverviewCarousel: React.FC<ProjectOverviewCarouselProps> = props => {
  const {
    className,
    showHeader = true,
    header = "Overview",
    ...otherProps
  } = props;
  return (
    <div className={className}>
      {showHeader && <span className={`${style.title} pl-2`}>{header}</span>}
      <div className={`${style.container}`}>
          <ProjectOverview {...otherProps} />
      </div>
    </div>
  );
};
