import * as React from 'react';
import * as style from './wave.module.scss';

interface WaveProps {
  waveBottom?: boolean;
}

export const Wave: React.FC<WaveProps> = props => {
  const { waveBottom } = props;
  return (
    <div className={`${style.wavePosition} ${waveBottom && style.moveBottom}`}>
      <svg aria-hidden="true" focusable="false">
        <linearGradient id="wave-gradient" x2="0" y2="1">
          <stop offset="0%" stopColor="rgba(255,255,255,.2)" />
          <stop offset="10%" stopColor="rgba(255,255,255,.1)" />
          <stop offset="20%" stopColor="rgba(255,255,255,.05)" />
          <stop offset="40%" stopColor="rgba(36,26,171,.0)" />
        </linearGradient>
      </svg>
      <svg className="editorial" viewBox="0 24 150 40" preserveAspectRatio="none">
        <defs>
          <path
            id="gentle-wave"
            d="M-160 44c30 0
    58-6 88-6s
    58 6 88 6
    58-6 88-6
    58 6 88 6
    v44h-352z"
          />
        </defs>
        <g className={style.parallax}>
          <use xlinkHref="#gentle-wave" x="50" y="0" fill="url(#wave-gradient)" />
          <use xlinkHref="#gentle-wave" x="50" y="3" fill="url(#wave-gradient)" />
          <use xlinkHref="#gentle-wave" x="50" y="5" fill="url(#wave-gradient)" />
        </g>
      </svg>
    </div>
  );
};
