import * as React from 'react';
import * as style from './dropdownActionButton.module.scss';
import { DropdownBase, DropdownBaseProps } from 'src/components/Dropdown/DropdownBase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';

interface DropdownActionButtonProps extends DropdownBaseProps {}

export const DropdownActionButton: React.FC<DropdownActionButtonProps> = props => {
  return (
    <div className={style.dropdownActionButton}>
      <DropdownBase {...props}>
        <FontAwesomeIcon icon={faEllipsisH} className={`d-flex ${style.ellipsisIcon}`} />
      </DropdownBase>
    </div>
  );
};
