import * as React from "react";
import * as style from "./loginContainer.module.scss";
import {
  CardWithProgressBar,
  CardWithProgressBarProps
} from "src/components/Card/CardWithProgressBar";
import { ButtonProps } from "src/components/Button/Button";
import { BottomButton } from "src/components/Login/BottomButton";
import { observer } from "mobx-react";
import { useEnterKeyPress } from "src/utils/form";

interface LoginContainerProps extends CardWithProgressBarProps {
  title?: string;
  primaryText?: string;
  secondaryText?: string;
  primaryBtnOnProps?: ButtonProps;
  secondaryBtnOnProps?: ButtonProps;
}

export const LoginContainer: React.FC<LoginContainerProps> = observer(props => {
  useEnterKeyPress(props.primaryBtnOnProps.onClick);

  const {
    title = "Welcome to EVA X",
    primaryText,
    secondaryText,
    primaryBtnOnProps,
    secondaryBtnOnProps
  } = props;
  return (
    <div
      className={`${style.loginContainer} d-flex flex-column justify-content-center`}
    >
      <div className={`text-center ${style.mainText} mb-2`}>{title}</div>
      <div
        className={`d-flex flex-column justify-content-center align-items-center`}
      >
        <CardWithProgressBar className={style.card} {...props}>
          {props.children}
          <BottomButton
            primaryText={primaryText}
            primaryBtnOnProps={primaryBtnOnProps}
            secondaryText={secondaryText}
            secondaryBtnOnProps={secondaryBtnOnProps}
          />
        </CardWithProgressBar>
      </div>
    </div>
  );
});
