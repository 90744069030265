import * as React from "react";
import * as style from "./circleSvg.module.scss";

export interface CircleSvgProps {
  percentage?: number;
  size?: number;
  strokeWidth?: number;
  className?: string;
  color?: 'primary' | 'secondary';
}

export const CircleSvg: React.FC<CircleSvgProps> = props => {
  const { size = 100, strokeWidth = 1 } = props;
  return (
    <div className={style.circleSvgMain}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="-1 -1 34 34"
        className={`${style.circleSvg} ${props.className}`}
        style={{
          width: size
        }}
      >
        <circle
          cx="16"
          cy="16"
          r="15.9155"
          className={`${style.progressBar} ${props.color === "secondary" && style.secondaryColor}`}
          style={{
            strokeDashoffset: 100 - props.percentage,
            strokeWidth: strokeWidth,
          }}
        />
      </svg>
      <div className={style.content}>{props.children}</div>
    </div>
  );
};
