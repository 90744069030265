import * as _ from 'lodash';
import { Form, Field } from 'mobx-react-form';
import dvr from 'mobx-react-form/lib/validators/DVR';
import * as validatorJs from 'validatorjs';
import vjf from 'mobx-react-form/lib/validators/VJF';

interface Handlers<FormData> {
  onSubmit: (form: FormStore) => (values: FormData) => any;
}

interface FormOptions<FormData> {
  options?: any;
  plugins?: any;
  bindings?: any;
  hooks?: any;
  handlers?: Handlers<FormData>;
}

export class FormStore<FormData = any> extends Form<FormData> {
  constructor(fields, options: FormOptions<FormData>) {
    super({ fields }, _.merge({}, options));
  }

  reInit = () => {
    this.reset();
    this.showErrors(false);
  };

  /**
   * update Initials - update initial values
   */
  updateInitials = (formData: Partial<FormData>) => {
    this.update(formData);
    this.validate();
  };

  /**
   * Default options that can be overridden entirely by options
   */
  // Validation Plugins
  plugins() {
    return {
      dvr: dvr(validatorJs),
      vjf: vjf()
    };
  }

  // Custom bindings. Please add more according to use case
  bindings() {
    return {
      extended: {
        id: 'id',
        name: 'name',
        type: 'type',
        value: 'value',
        label: 'label',
        placeholder: 'placeholder',
        disabled: 'disabled',
        onChange: 'onChange',
        onBlur: 'onBlur',
        onFocus: 'onFocus',
        autoFocus: 'autoFocus',
        error: 'error',
        rules: 'rules'
      }
    };
  }

  options() {
    return {};
  }
}
