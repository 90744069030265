import * as React from "react";
import * as style from "./cardWithProgressBar.module.scss";
import { Card, CardProps } from "src/components/Card/Card";
import {
  ProgressBar,
  ProgressBarProps
} from "src/components/ProgressBar/ProgressBar";

export interface CardWithProgressBarProps extends CardProps {
  progressText?: string;
  progressValue?: number;
  isLoading?: boolean;
}

export const CardWithProgressBar: React.FC<CardWithProgressBarProps> = props => {
  return (
    <Card
      className={`${style.card} ${props.isLoading && style.loading} ${
        props.className
      }`}
    >
      {props.children}
      <ProgressBar
        value={props.progressValue}
        className={`w-100 ${style.progressBar}`}
        animated={props.isLoading}
      />
      <div className={style.progressText}>
        {props.isLoading ? "Loading.." : props.progressText}
      </div>

      {props.isLoading && <div className={style.overlay}/>}
    </Card>
  );
};
