import * as React from 'react';
import * as style from './asterisk.module.scss';

interface AsteriskProps {
  className?: string;
}

export const Asterisk: React.FC<AsteriskProps> = (props) => {
  return (
    <span className={`${style.asterisk} ${props.className}`}>*</span>
  );
};
