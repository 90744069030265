import * as React from "react";
import * as style from "./profileIcon.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons/faUserCircle";
import { useRef, useState } from "react";
import { UncontrolledTooltip } from "reactstrap";
import { Tooltip } from "src/components/Tooltip/Tooltip";

export interface ProfileIconProps {
  imageUrl?: string;
  size?: number;
  className?: string;
  tooltipText?: string;
}

export const ProfileIcon: React.FC<ProfileIconProps> = props => {
  const { size = 22, imageUrl, tooltipText } = props;
  const [imageError, setImageError] = useState<boolean>(false);
  const [tooltipIsOpen, setTooltipIsOpen] = useState<boolean>(false);
  const iconRef = useRef<any>(false);

  return (
    <>
      <div
        className={`${style.profileIcon} ${props.className}`}
        style={{
          height: size,
          width: size
        }}
        ref={iconRef}
      >
        {imageUrl && !imageError ? (
          <img
            src={imageUrl}
            alt={"Profile Image"}
            className={style.imageSrc}
            style={{
              borderRadius: size
            }}
            onMouseEnter={() => setTooltipIsOpen(true)}
            onMouseOut={() => setTooltipIsOpen(false)}
            onError={() => setImageError(true)}
          />
        ) : (
          <FontAwesomeIcon
            icon={faUserCircle}
            style={{
              fontSize: size
            }}
          />
        )}
        {iconRef.current && tooltipText && (
          <Tooltip target={iconRef.current} isOpen={tooltipIsOpen}>
            {tooltipText}
          </Tooltip>
        )}
      </div>
    </>
  );
};
