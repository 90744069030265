import * as React from 'react';
import * as style from './dropdownBase.module.scss';
import {
  ButtonDropdown as ReactDropdown,
  DropdownItem,
  DropdownItemProps,
  DropdownMenu,
  DropdownMenuProps,
  DropdownProps,
  DropdownToggle,
  DropdownToggleProps
} from 'reactstrap';
import { ReactNode, useState } from 'react';
import * as _ from 'lodash';
import { Link } from 'react-router-dom';

export interface DropdownItemData extends DropdownItemProps {
  name?: Element | ReactNode | string;
  to?: string;
  className?: string;
}

export interface DropdownBaseProps<T = DropdownItemData[]> extends DropdownMenuProps {
  dropdownItem: T;
  dropdownToggleProps?: DropdownToggleProps;
  dropdownProps?: DropdownProps;
  additionalContent?: Element | ReactNode | string;
  renderMenuItems?: (items: T) => Element | ReactNode | Element[] | ReactNode[];
  className?: string;
  dropDownClassName?: string
}

export const DropdownBase: React.FC<DropdownBaseProps> = props => {
  const { dropdownItem, className, additionalContent, renderMenuItems, dropDownClassName, ...otherProps } = props;
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);

  const toggleDropdownIsOpen = () => {
    setDropdownIsOpen(!dropdownIsOpen);
  };

  const renderDropdownItems = () => {
    return _.map(props.dropdownItem, ({ name, to, ...itemProps }, i: number) => {
      if (to) {
        return (
          <Link key={i} tabIndex={0} to={to} className={style.dropdownItemLink}>
            <DropdownItem {...itemProps}>{itemProps.children || name}</DropdownItem>
          </Link>
        );
      } else {
        return (
          <DropdownItem {...itemProps} key={i}>
            {name}
          </DropdownItem>
        );
      }
    });
  };

  return (
    <div className={`${dropDownClassName} ${style.dropdown}`}>
      <ReactDropdown isOpen={dropdownIsOpen} toggle={toggleDropdownIsOpen} {...props.dropdownProps}>
        {additionalContent}
        <DropdownToggle tag={'div'} {...props.dropdownToggleProps}>
          <div className={props.className}>{props.children}</div>
        </DropdownToggle>
        <DropdownMenu {...otherProps}>
          {renderMenuItems ? renderMenuItems(dropdownItem) : renderDropdownItems()}
        </DropdownMenu>
      </ReactDropdown>
    </div>
  );
};
