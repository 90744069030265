import * as React from "react";
import * as style from "./totalScore.module.scss";

interface TotalScoreProps {
  primaryNumber: number;
  secondaryNumber: number;
  primarySize?: number;
  secondarySize?: number;
}

export const TotalScore: React.FC<TotalScoreProps> = props => {
  const { primaryNumber, secondaryNumber, primarySize, secondarySize } = props;
  return (
    <div className={`${style.totalScore} d-flex align-items-center`}>
      <div style={{ fontSize: primarySize }} className={style.primaryNumber}>
        {primaryNumber}
      </div>
      <div style={{ fontSize: secondarySize }} className={`px-1`}>
        /
      </div>
      <div style={{ fontSize: secondarySize }}>{secondaryNumber}</div>
    </div>
  );
};
