import * as React from 'react';
import {DropdownItemData} from "src/components/Dropdown/DropdownBase";
import {COMMON_COMPONENT} from "src/constants/routes";
import {DropdownButton} from "src/components/Dropdown/DropdownButton";
import {DropdownActionButton} from "src/components/Dropdown/DropdownActionButton";


interface DropdownDemoProps {}

export const DropdownDemo: React.FC<DropdownDemoProps> = props => {
  const dropdownItems: DropdownItemData[] = [
    {
      name: 'Item 1 - OnClick',
      onClick: () => {
        alert('Hello! I am an alert box!!');
      }
    },
    {
      name: 'Item 2 - Link',
      to: COMMON_COMPONENT
    },
    {
      name: 'Item 3 - Disabled',
      disabled: true
    }
  ];

  return (
    <div className={`d-flex flex-row`}>
      <div>
        <DropdownButton dropdownItem={dropdownItems}>Dropdown Button </DropdownButton>
      </div>
      <div className={`ml-5`}>
        <DropdownActionButton dropdownItem={dropdownItems} />
      </div>
    </div>
  );
};
