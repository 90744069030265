import * as React from "react";
import * as style from "./tableBase.module.scss";
import ReactTable from "react-table";
import { TableProps as ReactTableProps } from "react-table";
import { DropdownItemData } from "src/components/Dropdown/DropdownBase";
import { DropdownActionButton } from "src/components/Dropdown/DropdownActionButton";
import { CheckBox } from "src/components/CheckBox/CheckBox";
import {PaginationWithFooter} from "src/components/Pagination/PaginationWithFooter";

export interface TableProps extends Partial<ReactTableProps> {
  data: any[];
  columns: any[];
  /*
  The types below are needed if you want to customized the checkbox in the Table.
  Instead you can use TableWithCheckbox.tsx component for preconfigured table with checkbox
  */
  showCheckbox?: boolean;
  checkboxRowChecked?: (originalItem: any) => boolean;
  checkboxRowOnChange?: (originalItem: any) => void;
  checkboxSelectAllOnChange?: () => void;
  checkboxSelectAllChecked?: boolean;
  checkboxSelectAllIndeterminate?: boolean;
  actionButtonItems?: (originalItem: any) => DropdownItemData[];
  tableName?: string;
  tableTotalNumber?: number;
  tableFooter?: Element | React.ReactNode;
  activePage?: number;
  checkedCount?: number;
  ref?: any;
  minRows?: number;
  containerClassName?: string;
}
export const TableProps = undefined; // Haxx https://stackoverflow.com/a/52260432/6319503

export const TableBase: React.FC<TableProps> = props => {
  const defaultColumns = [
    props.showCheckbox && {
      id: "checkbox",
      accessor: "",
      Cell: ({ original }) => {
        return (
          <CheckBox
            checked={props.checkboxRowChecked(original)}
            onChange={() => props.checkboxRowOnChange(original)}
            className={`mt-1`}
          />
        );
      },
      Header: x => {
        return (
          <CheckBox
            checked={props.checkboxSelectAllChecked}
            onChange={() => props.checkboxSelectAllOnChange()}
            indeterminate={props.checkboxSelectAllIndeterminate}
            className={`mt-1`}
          />
        );
      },
      sortable: false,
      width: 40
    },
    ...props.columns,
    props.actionButtonItems && {
      Header: <div className={style.actionHeader}>Action</div>,
      id: "action",
      accessor: "",
      maxWidth: 75,
      sortable: false,
      Cell: ({ original }) => {
        return (
          <div className={`d-flex justify-content-center h-100`}>
            <DropdownActionButton
              dropdownItem={props.actionButtonItems(original)}
            />
          </div>
        );
      }
    }
  ].filter(column => column);

  return (
    <div
      className={`${style.reactTable} ${props.containerClassName &&
        props.containerClassName}`}
    >
      <ReactTable
        {...props}
        PaginationComponent={PaginationWithFooter}
        columns={defaultColumns}
      />
    </div>
  );
};
