import * as React from "react";
import * as style from "./toggleSwitch.module.scss";
import { useEffect, useState } from "react";

export interface ToggleSwitchProps {
  checked?: boolean;
  onChange?: (e: boolean) => any; // this returns checked value
  onClick?: (e: boolean) => any;
  className?: string;
  disabled?: boolean;
  checkedClassName?: string;
  showTrueAlways?: boolean;
  enableCheckedTheme?: boolean;
}

export const ToggleSwitch: React.FC<ToggleSwitchProps> = props => {
  const [isChecked, setIsChecked] = useState<boolean>(props.checked || false);

  useEffect(() => {
    setIsChecked(props.checked);
  }, [props.checked]);

  const handleClick = () => {
    setIsChecked(t => !t);
    if (props.onChange) {
      props.onChange(!isChecked);
    }
  };
  return (
    <label className={`${style.switch} ${props.className}`}>
      <input
        type="checkbox"
        onChange={props.onChange && handleClick}
        disabled={props.disabled}
        checked={props.showTrueAlways ? true : isChecked}
        onClick={() => props.onClick && props.onClick(!isChecked)}
      />
      <span
        className={`${style.slider} ${
          isChecked
            ? props.checkedClassName
              ? props.checkedClassName
              : style.checked
            : props.enableCheckedTheme
            ? style.checked
            : style.unchecked
        } 
      ${style.round} ${props.disabled ? style.disabled : ""}
      `}
      />
    </label>
  );
};
