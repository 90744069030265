import * as React from 'react';
import * as style from './businessUserForgotPassword.module.scss';
import {ForgotPassword} from "src/components/Login/ForgotPassword";
import {PageBase} from "src/components/PageBase/PageBase";
import {EnterNewPassword} from "src/components/Login/EnterNewPassword";

interface BusinessUserRecoveryProps {

}

export const EnterNewPasswordPage: React.FC<BusinessUserRecoveryProps> = (props) => {
  return (
    <PageBase showSidebar={false}>
      <EnterNewPassword/>
    </PageBase>
  );
};
