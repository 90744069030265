import * as React from "react";
import * as style from "../../../../components/Login/stepsForm.module.scss";
import { SectionHeader } from "src/components/SectionHeader/SectionHeader";
import { Input } from "src/components/Input/Input";
import { Select } from "src/components/Select/Select";
import { observer } from "mobx-react";
import { candidateDetailsFormStore } from "src/container/Candidate/stores/candidateDetailsFormStore";
import { useEffect } from "react";
import { routerStore } from "src/stores/RouterStore";
import {
  countryCodeOptions,
  setCountryCodeAccordingToTz
} from "src/utils/countries";
import { InputPassword } from "src/components/Input/InputPassword";

interface CandidateDetailStepProps {}

export const CandidateDetailStep: React.FC<CandidateDetailStepProps> = observer(
  props => {
    const tokenField = candidateDetailsFormStore.$("token");
    const firstNameField = candidateDetailsFormStore.$("first_name");
    const lastNameField = candidateDetailsFormStore.$("last_name");
    const emailField = candidateDetailsFormStore.$("email");
    const countryField = candidateDetailsFormStore.$("country_code");
    const phoneNumberField = candidateDetailsFormStore.$("contact_no");
    const passwordField = candidateDetailsFormStore.$("password");
    const confirmPasswordField = candidateDetailsFormStore.$(
      "password_confirmation"
    );

    useEffect(() => {
      tokenField.set("value", routerStore.queryString.token);
      firstNameField.set("value", routerStore.queryString.first_name);
      lastNameField.set("value", routerStore.queryString.last_name);
      emailField.set("value", routerStore.queryString.email);

      // Setting the country code automatically through timezone
      setCountryCodeAccordingToTz(countryField);
    }, []);

    return (
      <>
        <SectionHeader title={`Create an account to view project`}>
          <div className={`${style.splitSection}`}>
            <Input className={style.inputStyle} {...firstNameField.bind()} />
            <Input className={style.inputStyle} {...lastNameField.bind()} />
          </div>

          <Input {...emailField.bind()} disabled={true} />

          <div className={`d-flex`}>
            <Select
              borderLess={true}
              className={style.countryCode}
              {...countryField.bind()}
              options={countryCodeOptions()}
            />
            <Input {...phoneNumberField.bind()} />
          </div>
        </SectionHeader>

        <SectionHeader title={`Create your account password`}>
          <InputPassword {...passwordField.bind()} />
          <InputPassword {...confirmPasswordField.bind()} />
        </SectionHeader>
      </>
    );
  }
);
