import * as React from "react";
import * as style from "./setupStatus.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons/faPencilAlt";

interface SetupStatusProps {
  title?: string;
  status?: string | Element | React.ReactNode;
  onClick?: () => void;
}

export const SetupStatus: React.FC<SetupStatusProps> = props => {
  const { title, status, onClick } = props;

  return (
    <div className={style.setupStatus}>
      <span className={style.statusTitle}>{title}</span>
      <div className={`${style.statusCondition} d-flex`}>
        {status}
        {props.onClick && (
          <FontAwesomeIcon
            className={style.icon}
            icon={faPencilAlt}
            onClick={onClick}
          />
        )}
      </div>
    </div>
  );
};
