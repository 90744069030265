import * as React from "react";
import * as style from "./testListTable.module.scss";
import { TestListIcon } from "src/components/TestListTable/TestListIcon";
import { Button } from "src/components/Button/Button";
import { TestList, TestListData } from "src/components/TestListTable/TestList";
import { ReportModal } from "src/components/ReportModal/ReportModal";
import { routerStore } from "src/stores/RouterStore";
import { candidateProjectOverviewApiStore } from "src/container/Candidate/components/MyTestPage/stores/candidateProjectOverviewApiStore";

interface TestListTableProps {
  className?: string;
  data?: TestListData[];
}

export const TestListTable: React.FC<TestListTableProps> = props => {
  const [reportModalIsOpen, toggleReportModalIsOpen] = React.useState(false);

  return (
    <div className={`${style.testListTable} ${props.className}`}>
      <div className={`d-flex justify-content-end w-100 ${style.header}`}>
        {candidateProjectOverviewApiStore.data.is_report_generated ? (
          <Button onClick={() => toggleReportModalIsOpen(true)}>
            View Report
          </Button>
        ) : (
          <>
            <div className={style.headerSecondContent}>Est. Duration</div>
            <div className={style.headerThirdContent}>Action</div>
          </>
        )}
      </div>
      {props.data &&
        props.data.map((item, key) => {
          return (
            <TestList data={item} isLast={key === props.data.length - 1} />
          );
        })}
      <ReportModal
        toggle={() => toggleReportModalIsOpen(false)}
        isOpen={reportModalIsOpen}
        candidate_id={routerStore.params.project_candidate_id}
      />
    </div>
  );
};
