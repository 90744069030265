import * as React from "react";
import * as style from "./stepsForm.module.scss";
import { routerStore } from "src/stores/RouterStore";
import { Input } from "src/components/Input/Input";
import { LoginContainer } from "src/components/Login/LoginContainer";
import { useEffect, useState } from "react";
import { Message } from "src/components/Message/Message";
import * as _ from "lodash";
import { observer } from "mobx-react";
import { APP_LOGIN } from "src/constants/routes";
import { newPasswordFormStore } from "src/components/Login/stores/newPasswordFormStore";

interface EnterNewPasswordProps {}

export const EnterNewPassword: React.FC<EnterNewPasswordProps> = observer(
  props => {
    const [submitCompleted, setSubmitCompleted] = useState<boolean>(false);

    const tokenField = newPasswordFormStore.$("token");
    const passwordField = newPasswordFormStore.$("password");
    const confirmPasswordField = newPasswordFormStore.$(
      "password_confirmation"
    );

    useEffect(() => {
      tokenField.set("value", routerStore.queryString.token);
    }, []);

    const submitChangePassword = async () => {
      setSubmitCompleted(true);
      await _.delay(async () => {
        try {
          await newPasswordFormStore.submit();
          Message.success("Successfully changed password.");
          setSubmitCompleted(false);
          routerStore.push(APP_LOGIN);
        } catch (e) {
          console.error(e);
          setSubmitCompleted(false);
          Message.error("Error sending password.");
        }
      }, 600);
    };

    return (
      <LoginContainer
        progressText={`Enter new password`}
        progressValue={submitCompleted && 100}
        isLoading={submitCompleted}
        primaryText={`Change Password`}
        primaryBtnOnProps={{
          disabled: !newPasswordFormStore.isValid,
          onClick: submitChangePassword
        }}
        secondaryText={`Cancel`}
        secondaryBtnOnProps={{
          onClick: () => routerStore.push(APP_LOGIN)
        }}
      >
        <div className={style.content}>
          <Input {...passwordField.bind()} />
          <Input
            {...confirmPasswordField.bind()}
          />
        </div>
      </LoginContainer>
    );
  }
);
