import * as React from 'react';
import * as style from './gridLayout.module.scss';

interface GridLayoutProps {
  cardWidth?: number; //gridCard width
  gridGap?: number; // gridGap is in rem
  className?: string;
}

export const GridLayout: React.FC<GridLayoutProps> = props => {
  return (
    <div
      className={`${style.grid} ${props.className}`}
      style={{
        gridTemplateColumns: `repeat(auto-fit, minmax(${props.cardWidth}px, 0.3fr))`,
        gridGap: `${props.gridGap}rem`
      }}
    >
      {props.children}
    </div>
  );
};
