import * as _ from "lodash";
import { action, computed, observable, ObservableMap, toJS } from "mobx";
import * as Papa from "papaparse";
import { ParseResult } from "papaparse";
import { FormStore } from "src/stores/FormStore";
import { Message } from "src/components/Message/Message";
import * as blobUtil from "blob-util";
import { uploadCsvCandidateBulkApiStore } from "src/container/BusinessUser/components/CandidatePage/AddCandidate/stores/uploadCSVCandidateBulkApiStore";
import { routerStore } from "src/stores/RouterStore";

export interface InviteCandidateFormData {
  email: string;
  name: string;
  journey_id?: number;
  client_id?: number;
}

interface UpdateCSVFormData {
  csv: File;
  client_id: number;
  journey_id: number;
}

export class UploadCSVFormStore extends FormStore<UpdateCSVFormData> {
  @observable
  parsedContent = {
    data: [],
    errors: [],
    meta: null
  } as any;

  @observable
  candidateSelection: ObservableMap<string, boolean> = observable.map();

  @computed
  get getParsedContentData() {
    if (this.parsedContent && this.parsedContent.data) {
      return toJS(this.parsedContent.data as any);
    }
    return [];
  }

  @computed
  get getSelectedCandidates() {
    const selectedCandidates = [];
    this.candidateSelection.forEach((value, key) => {
      if (value) {
        selectedCandidates.push(key);
      }
    });
    return selectedCandidates as any;
  }

  @action
  clearParsedContentDataAndSelected = () => {
    this.parsedContent.data = [];
    this.candidateSelection = observable.map();
  };

  @action
  updateParsedContent = async content => {
    const isValid = this.validateCSVContent(content);

    if (isValid) {
      this.parsedContent = content;
    } else {
      this.parsedContent = {};
    }

    await this.validate();
  };

  @action
  appendParsedContent = async (content: InviteCandidateFormData | any) => {
    this.parsedContent.data.push(content);
    this.parsedContent.data = _.uniqBy(this.parsedContent.data, "email");

    this.candidateSelection.set(content.email, true);

    /**
     * update form store
     */
    this.updateCSVField();
  };

  @action
  appendCSVContent = async (csvContent: ParseResult) => {
    const isValid = this.validateCSVContent(csvContent);

    if (isValid) {
      this.parsedContent.data = [
        ...this.parsedContent.data,
        ...csvContent.data
      ];
      this.parsedContent.data = _.uniqBy(this.parsedContent.data, "email");
      _.each(csvContent.data, value => {
        this.candidateSelection.set(value.email, true);
      });
    }

    this.updateCSVField();

    await this.validate();
  };

  updateCSVField = () => {
    const csvFile = Papa.unparse(this.parsedContent.data);
    const csv = blobUtil.createBlob([csvFile], { type: "text/csv" });
    /**
     * update form field
     */
    this.select("csv").onChange(csv);
  };

  validateCSVContent = (content: ParseResult) => {
    /**
     * show parse CSV error
     */
    if (content.errors.length > 0) {
      const firstError = content.errors[0];

      this.invalidate(firstError.message);
      Message.error("Please check your csv file. Found incorrect format");
      return false;
    }

    /**
     * check if list has item(s)
     */
    if (_.isArray(content.data) && content.data.length === 0) {
      this.invalidate("The list is empty.");
      Message.error("The list is empty.");
      return false;
    }

    _.each(content.data, (candidate, index) => {
      /**
       * check if candidate provided has email
       */
      if (!candidate.email) {
        this.invalidate(`candidate in line ${index + 1}, email is missing.`);
        Message.error(`candidate in line ${index + 1}, email is missing.`);
        return false;
      }
    });

    return true;
  };
}

export const uploadCSVFormStore = new UploadCSVFormStore(
  {
    csv: {}
  },
  {
    hooks: {
      onSuccess: async (form: FormStore) => {
        const values = form.values();
        try {
          await uploadCsvCandidateBulkApiStore.callApiWithFormData({
            company_project_id: routerStore.params.company_project_id,
            file: values.csv
          });
          form.reInit();
        } catch (e) {
          console.error("Failed to upload bulk candidates: ", e);
          return Promise.reject(
            _.get(
              uploadCsvCandidateBulkApiStore.failureResponse,
              "errors",
              `Failed to ${values.id ? "edit" : "add new"} candidate`
            )
          );
        }
        return Promise.resolve();
      },
      onError: form => {
        return Promise.reject("Incorrect form information");
      }
    }
  }
);
