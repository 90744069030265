import * as React from 'react';
import {Breadcrumb} from "src/components/Breadcrumb/Breadcrumb";

interface BreadcrumbDemoProps {}

export const BreadcrumbDemo: React.FC<BreadcrumbDemoProps> = props => {
  return (
    <>
      <Breadcrumb contents={[{
        text: `Content 1`
      },{
        text: `Content 2`
      },{
        text: `Content 3`,
        onClick: () => console.log(`this is it`)
      }]} />
    </>
  );
};
