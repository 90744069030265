import { ApiStore } from "src/stores/ApiStore";

interface CompanyProjectsApiStorePayload {
  company_id: number | string;
}

export interface CompanyProjectsApiStoreData {
  company_project_id: number;
  project_name: string;
  description: string;
  allocated_slots: number;
  invited_candidates: number;
  completed_candidates: number;
  completed_percentage: number;
  active_candidates: any[];
}

class CompanyProjectsApiStore extends ApiStore<
  CompanyProjectsApiStorePayload,
  CompanyProjectsApiStoreData[]
> {
  constructor() {
    super("api/companyProjects");
  }
}

export const companyProjectsApiStore = new CompanyProjectsApiStore();
