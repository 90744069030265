import * as React from 'react';
import { ReactNode } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { isAbsolute } from 'path';

import { Link } from 'react-router-dom';
import * as style from './tabs.module.scss';

export interface DataProps {
  key: string; // key must be unique, this is how the Tab set the which panel to be active
  tabTitle: string;
  tabContent?: any;
  disabled?: boolean;
  href?: string;
}

interface TabStates {
  activeTab: string;
  indicatorWidth: number;
  indicatorLeftPos: number;
}

interface TabProps {
  data: DataProps[];
  activeTab?: string;
  className?: string;
  navClassName?: string;
  contentClassName?: string;
  paneClassName?: string;
  onNavClick?: (tabName: string) => void;
  bottomLine?: boolean;
  hideContent?: boolean;
  otherNav?: ReactNode | Element;
}

export class Tabs extends React.Component<TabProps, TabStates> {
  private navLinkList = [];

  constructor(props) {
    super(props);

    this.state = {
      activeTab: '0',
      indicatorWidth: 0,
      indicatorLeftPos: 16
    };
  }

  componentDidMount() {
    if (this.props.activeTab) {
      this.setState({
        activeTab: this.props.activeTab
      });
    }

    setTimeout(() => {
      const ele = this.navLinkList[this.props.activeTab || this.props.data[0].key];

      if (!ele) {
        return false;
      }

      if (this.props.activeTab && this.props.activeTab !== '0') {
        this.setState({
          indicatorWidth: ele.offsetWidth,
          indicatorLeftPos: ele.offsetLeft
        });
      } else {
        this.setState({
          indicatorWidth: ele.offsetWidth
        });
      }
    }, 1000);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.activeTab !== this.props.activeTab) {
      const tabRef = this.navLinkList[nextProps.activeTab];

      if (!tabRef) {
        return false;
      }

      this.setState({
        activeTab: nextProps.activeTab,
        indicatorWidth: tabRef.offsetWidth,
        indicatorLeftPos: tabRef.offsetLeft
      });
    }
  }

  setActive = (tabKey: string) => () => {
    if (this.props.onNavClick) {
      this.props.onNavClick(tabKey);
    }

    const tabRef = this.navLinkList[this.props.activeTab || tabKey];

    if (!tabRef) {
      return false;
    }

    this.setState({
      activeTab: this.props.activeTab || tabKey,
      indicatorWidth: tabRef.offsetWidth,
      indicatorLeftPos: tabRef.offsetLeft
    });
  };

  private renderNavLink(item) {
    if (item.href && isAbsolute(item.href)) {
      return (
        <Link
          className="nav-link"
          to={item.href}
          innerRef={nav => (this.navLinkList[item.key] = nav)}
        >
          {item.tabTitle}
        </Link>
      );
    }

    return (
      <NavLink
        innerRef={nav => (this.navLinkList[item.key] = nav)}
        disabled={item.disabled}
        href={item.href}
        onClick={this.setActive(item.key)}
      >
        {item.tabTitle}
      </NavLink>
    );
  }

  render() {
    const { data } = this.props;

    return (
      data && (
        <div className={`${style.customTabs} ${this.props.className || ''}`}>
          <Nav
            className={`${this.props.navClassName} ${
              this.props.hideContent ? 'mb-0' : ''
            }`}
            tabs={true}
          >
            {data.map(item => (
              <NavItem
                key={`item-${item.key}`}
                active={item.key === this.state.activeTab}
              >
                {this.renderNavLink(item)}
              </NavItem>
            ))}
            {this.props.otherNav}
          </Nav>
          {!this.props.hideContent && (
            <TabContent
              className={this.props.contentClassName || ''}
              activeTab={this.state.activeTab}
            >
              {data.map(item => (
                <TabPane
                  key={item.key}
                  tabId={item.key}
                  className={this.props.paneClassName || ''}
                >
                  {item.tabContent}
                </TabPane>
              ))}
            </TabContent>
          )}
        </div>
      )
    );
  }
}
