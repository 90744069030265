import * as React from "react";
import * as style from "./setupPage.module.scss";
import { SetupStatus } from "src/container/BusinessUser/components/CandidatePage/SetupPage/SetupStatus";

export interface SetupSectionData {
  title: string;
  status: string | Element | React.ReactNode;
}

interface SetupSectionProps {
  className?: string;
  showTitle?: boolean;
  data: SetupSectionData[];
  header?: string
}

export const SetupSection: React.FC<SetupSectionProps> = props => {
  const { className, showTitle = true, header } = props;

  return (
    <div className={className}>
      {showTitle && <span className={`${style.title} pl-2`}>{header}</span>}
      <div className={`${style.container} d-flex`}>
        {props.data && props.data.map(item => {
          return (
            <SetupStatus
              status={item.status}
              title={item.title}
            />
          );
        })}
      </div>
    </div>
  );
};
