import * as React from 'react';
import * as style from './textOverflowTooltip.module.scss';
import {Tooltip} from "src/components/Tooltip/Tooltip";

interface TextOverflowTooltipProps {
  className?: string;
}

export const TextOverflowTooltip: React.FC<TextOverflowTooltipProps> = props => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const containerRef = React.useRef() as any;

  const toggle = () => {
    if (containerRef.current.offsetWidth < containerRef.current.scrollWidth) {
      setIsOpen(!isOpen);
    }
  };

  return (
    <>
      <div ref={containerRef} className={`${style.textWrapper} ${props.className}`}>
        {props.children}
      </div>
      {containerRef && (
        <Tooltip target={containerRef} isOpen={isOpen} toggle={() => toggle()}>
          {props.children}
        </Tooltip>
      )}
    </>
  );
};
