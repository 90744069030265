import * as _ from "lodash";
import { Ref, useEffect, useState } from "react";
import validator from "validator";

/**
 * to filter out values that is empty, etc: empty array, object and string
 * Caveat: number, File type won't be filtered out
 * Caveat: boolean will automatically turn to 1 or 0
 * @param {Dictionary<FormData>} formData
 * @returns {_.Dictionary<Partial<FormData>>}
 */
export const compactFormValues = <FormData = any>(formData: FormData) => {
  return _.transform<FormData, Partial<FormData>>(
    formData as any,
    (result, value, key) => {
      let isValid = true;
      const isObject = _.isObject(value) && !(value instanceof File);
      const isBoolean = _.isBoolean(value);
      const isInt = _.isNumber(value);
      const selectValue = _.get(value, "value", undefined);
      const isSelectObject = selectValue !== undefined;

      if (isBoolean || isInt) {
        isValid = true;
      } else if (!value) {
        isValid = false;
      } else if (_.isString(value) || isObject) {
        isValid = !_.isEmpty(value);
      }

      if (isValid && isBoolean) {
        result[key] = (value ? 1 : 0) as any;
      } else if (isValid) {
        result[key] = value;
      }
      if (isSelectObject) {
        result[key] = selectValue;
      }
    },
    {}
  );
};

export const isImageFile = fileName => {
  if (!fileName) {
    throw "cant resolve file type of unknown file.";
  }
  const idxDot = fileName.lastIndexOf(".") + 1;
  const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
  return extFile === "jpg" || extFile === "jpeg" || extFile === "png";
};

export const isNumberKey = evt => {
  const event = evt ? evt : window.event;
  let charCode = event.which ? event.which : event.keyCode;
  return !(charCode > 31 && (charCode < 48 || charCode > 57));
};

export const checkRequiredByRules = (rules: string) => {
  return rules && rules.split("|").indexOf("required") !== -1;
};

export const useShowAsterisk = (value: any) => {
  const [showAsterisk, setShowAsterisk] = useState<boolean>(true);

  useEffect(() => {
    setShowAsterisk(_.isEmpty(value));
  }, [value]);

  return showAsterisk;
};

export function shouldBeEqualTo(target) {
  return ({ field, form }) => {
    const fieldsAreEquals = form.$(target).value === field.value;
    return [
      fieldsAreEquals,
      `The ${field.name} field should be equal to ${form.$(target).name} field`
    ];
  };
}

export function isEmptyAndIsMin({ field }) {
  const isValid = validator.isLength(field.value, { min: 6 });
  return [isValid, `The ${field.name} field must be at least 6 characters.`];
}

export function useEnterKeyPress(submitFunc: any) {
  // Event handlers
  const onDown = (event: KeyboardEvent) => {
    const keyCode = event.keyCode || event.which;
    if (keyCode === 13) {
      submitFunc();
    }
  };

  // Bind and unbind events
  useEffect(() => {
    window.addEventListener("keydown", onDown);
    return () => {
      window.removeEventListener("keydown", onDown);
    };
  }, []);
}
