import * as React from "react";
import {
  Slider as CompoundSlider,
  Rail,
  Handles,
  Tracks,
  SliderProps
} from "react-compound-slider";
import * as style from "./slider.module.scss";

interface ICompoundSliderProps extends Partial<SliderProps> {
  width?: string;
  className?: string;
  valueClassName?: any;
  handleClassName?: any;
  getValue?: (value: any, percentage: any) => void;
  showHandleValue?: boolean;
}

export const Slider: React.FC<ICompoundSliderProps> = props => {
  const {showHandleValue = false} = props;
  const renderHandles = ({ id, value, percent }, getHandleProps) => {

    if (props.getValue) {
      props.getValue(value, percent);
    }

    return (
      <div
        className={`${style.handleStyle} ${style.opacity} ${props.handleClassName}`}
        {...getHandleProps(id)}
        style={{ left: `${percent}%` }}
        key={id}
      >
        {showHandleValue && (
          <div className={`${style.handleValueStyle}`}>{value}</div>
        )}
      </div>
    );
  };

  const renderTracks = ({ id, source, target }, getTrackProps) => {
    return (
      <div
        className={style.trackStyle}
        style={{
          left: `${source.percent}%`,
          width: `${target.percent - source.percent}%`
        }}
        key={id}
        {...getTrackProps()}
      />
    );
  };

  const { width, className, onChange, values, ...otherSliderProps } = props;
  return (
    <CompoundSlider
      rootStyle={{ width }}
      values={values}
      className={`${style.sliderStyle} ${className}`}
      onChange={onChange}
      {...otherSliderProps}
    >
      <Rail>
        {({ getRailProps }) => (
          <div className={style.railStyle} {...getRailProps()} />
        )}
      </Rail>
      <Handles>
        {({ handles, getHandleProps }) => (
          <div>
            {handles.map(handle => renderHandles(handle, getHandleProps))}
          </div>
        )}
      </Handles>
      <Tracks right={false} left={true}>
        {({ tracks, getTrackProps }) => (
          <>{tracks.map(track => renderTracks(track, getTrackProps))}</>
        )}
      </Tracks>
    </CompoundSlider>
  );
};
