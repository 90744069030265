import { ApiStore } from "src/stores/ApiStore";
import { RestMethods } from "src/stores/api/baseApi";

interface ResetPasswordApiStorePayload {
  token: string;
  password: string;
  password_confirmation: string;
}

interface ResetPasswordApiStoreData {}

class ResetPasswordApiStore extends ApiStore<
  ResetPasswordApiStorePayload,
  ResetPasswordApiStoreData[]
> {
  constructor() {
    super("api/userManagement/resetPassword", {
      requestType: RestMethods.POST
    });
  }
}

export const resetPasswordApiStore = new ResetPasswordApiStore();
