import * as React from "react";
import * as style from "./stepsForm.module.scss";
import { routerStore } from "src/stores/RouterStore";
import { Input } from "src/components/Input/Input";
import { LoginContainer } from "src/components/Login/LoginContainer";
import { useState } from "react";
import { Message } from "src/components/Message/Message";
import * as _ from "lodash";
import { observer } from "mobx-react";
import { forgotPasswordFormStore } from "src/components/Login/stores/forgotPasswordFormStore";
import { APP_LOGIN } from "src/constants/routes";

interface ForgotPasswordProps {}

export const ForgotPassword: React.FC<ForgotPasswordProps> = observer(props => {
  const [submitCompleted, setSubmitCompleted] = useState<boolean>(false);

  const emailField = forgotPasswordFormStore.$("email");

  const submitForgetPassword = async () => {
    setSubmitCompleted(true);
    await _.delay(async () => {
      try {
        await forgotPasswordFormStore.submit();
        Message.success(
          "Successfully sent a recovery email to your email address if exists."
        );
        setSubmitCompleted(false);
      } catch (e) {
        console.error(e);
        setSubmitCompleted(false);
        Message.error("Failed in sending the recovery email");
      }
    }, 600);
  };

  return (
    <LoginContainer
      progressText={`Recover your account`}
      progressValue={submitCompleted && 100}
      isLoading={submitCompleted}
      primaryText={`Recover`}
      primaryBtnOnProps={{
        disabled: !forgotPasswordFormStore.isValid,
        onClick: submitForgetPassword
      }}
      secondaryText={`Cancel`}
      secondaryBtnOnProps={{
        onClick: () => routerStore.push(APP_LOGIN)
      }}
    >
      <div className={style.content}>
        <Input
          {...emailField.bind()}
          showAsterisk={false}
        />
      </div>
    </LoginContainer>
  );
});
