import * as React from "react";
import * as style from "./navigationProfileSection.module.scss";
import {ProfileIcon, ProfileIconProps} from "src/components/ProfileName/ProfileIcon";

export interface NavigationProfileSectionProps extends ProfileIconProps {
  name?: string;
}

export const NavigationProfileSection: React.FC<NavigationProfileSectionProps> = props => {
  const {name, ...otherProps} = props;
  return (
    <div
      className={`d-flex flex-row align-items-center ${style.profileSection}`}
    >
      <ProfileIcon size={65} {...otherProps} />
      <div className={`px-3`}>
        <div>Hello,</div>
        <div className={style.name}>{name}</div>
      </div>
    </div>
  );
};
