import * as React from "react";
import * as style from "./promoSection.module.scss";
import { Card } from "src/components/Card/Card";

interface PromoSectionProps {
  promoImageSource?: string;
}

export const PromoSection: React.FC<PromoSectionProps> = props => {
  return (
    <Card
      className={style.promoCard}
      style={{
        backgroundImage: `url(${props.promoImageSource})`
      }}
    />
  );
};
