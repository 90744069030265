import * as React from 'react';
import * as style from './uploadImageSample.module.scss';
import {UploadImage} from "src/components/UploadImage/UploadImage";
import {ComponentPreview} from "src/components/ComponentPreview/ComponentPreview";
import {uploadImageSampleFormStore} from "src/components/UploadImage/UploadImageSample/uploadImageSampleFormStore";

interface UploadImageSampleProps {

}

export const UploadImageSample: React.FC<UploadImageSampleProps> = (props) => {
  const  pictureField = uploadImageSampleFormStore.$('picture');
  return (
    <UploadImage formField={pictureField}/>

  );
};
