import {ApiStore} from 'src/stores/ApiStore';

interface CandidateTestCompletionApiStorePayload {
  candidate_tool_id: string | number
}

interface CandidateTestCompletionApiStoreData {

}

class CandidateTestCompletionApiStore extends ApiStore<CandidateTestCompletionApiStorePayload,
  CandidateTestCompletionApiStoreData[]> {
  constructor() {
    super(
      'api/candidateManagement/candidateTestCompletion'
    );
  }
}

export const candidateTestCompletionApiStore = new CandidateTestCompletionApiStore();
