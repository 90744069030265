import { routerStore } from "src/stores/RouterStore";
import * as _ from "lodash";
import {ApiStore} from "src/stores/ApiStore";
import {createBusinessUserApiStore} from "src/container/BusinessUser/components/RegistrationPage/store/createBusinessUserApiStore";
import {Message} from "src/components/Message/Message";

export const isCurrentRoute = (route: string | string[]) => {
  const routes = typeof route === "string" ? [route] : route;
  return _.some(
    routes,
    item => routerStore.match && routerStore.match.path === item
  );
};

export const isCurrentRouteWindow = (route: string | string[]) => {
  const routes = typeof route === "string" ? [route] : route;
  return _.some(routes, item => window.location.pathname === item);
};

export const randomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min) ) + min;
};

export const neutralizeWord = (value: string) => {
  const removedDashUnderscore = value.replace(/_/g, ' ');
  return removedDashUnderscore.replace(/(?:^|\s)\S/g, function(a) {
    return a.toUpperCase();
  });
};

export const readFileAsText = (file: File) => {
  if (!file) {
    return Promise.reject('`file` is undefined.');
  }
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = e => {
      const fileReader = e.target as FileReader;
      const fileContent = fileReader.result;
      resolve(fileContent);
    };

    reader.onerror = error => {
      reject(error);
    };

    reader.readAsText(file);
  });
};


export const getApiStoreError = (apiStore: ApiStore, fallbackMessage?: string) => {
  const registrationError = _.get(
    apiStore.failureResponse,
    "errors"
  );

  return `${_.values(registrationError)[0]}` || fallbackMessage;
};
