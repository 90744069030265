import * as React from "react";
import * as style from "./circleWave.module.scss";
import { CircleSvgProps } from "src/components/WaveProgressBar/CircleSvg";
import {randomNumber} from "src/utils";

export interface CircleWaveProps extends CircleSvgProps {}

export const CircleWave: React.FC<CircleWaveProps> = props => {
  const { size = 100 } = props;
  const topPercentage = ((100 - props.percentage) / 100) * 110;
  const styleSize: any = {
    "--circle-progress-bar-size": `${size}px`};

  return (
    <div className={`${style.waveProgressWrapperOuter} ${props.className}`} style={styleSize}>
      <div className={style.waveProgressWrapperInner}>
        <div className={style.wrapper}>
          <div
            className={`${style.waveProgress} ${style.far}`}
            style={{
              top: `${topPercentage}%`,
            }}
          />
        </div>
        <div className={style.content}>
          {props.children}
        </div>
      </div>
    </div>
  );
};
