import * as React from 'react';
import * as style from './card.module.scss';
import {CSSProperties} from "react";

export interface CardProps {
  className?: string;
  style?: CSSProperties;
}

export const Card: React.FC<CardProps> = props => {
  return <div className={`${style.cardBox} ${props.className}`} style={props.style}>{props.children}</div>;
};
