import * as cityData from "countrycitystatejson";
import { SelectOption } from "../_declare/select";
import ct from "countries-and-timezones";
import * as _ from "lodash";
import Field from "mobx-react-form/Field";

export const cityFieldOptions: SelectOption[] | any = (
  stateValue: string,
  countryShortName: string
) =>
  stateValue &&
  _.sortBy(
    cityData.getCities(countryShortName, stateValue).map(item => ({
      label: `${item}`,
      value: item
    })),
    ["label"]
  );

export const stateFieldOptions: SelectOption[] | any = (
  countryShortName: string
) =>
  countryShortName &&
  _.sortBy(
    cityData.getStatesByShort(countryShortName).map(item => ({
      label: item,
      value: item
    })),
    ["label"]
  );

export const countryFieldOptions: SelectOption[] | any = () =>
  _.sortBy(
    cityData.getCountries().map(item => ({
      label: item.name,
      value: item.name,
      shortName: item.shortName
    })),
    ["label"]
  );

export const countryCodeOptions: SelectOption[] | any = () => {
  const sortedCountries = _.sortBy(cityData.getCountries(), ["name"]);

  return sortedCountries.map(item => ({
    label: `(+${item.phone}) ${item.name}`,
    value: item.phone
  }));
};

export const getCountryCodeByTimezone = () => {
  const timezone = ct.getTimezone(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const shortName = timezone.country;
  return cityData.getCountryByShort(shortName).phone;
};

export const setCountryCodeAccordingToTz = (countryField: Field) => {
  // Setting the country code automatically through timezone
  const phoneCode = getCountryCodeByTimezone();
  const getOption = _.find(countryCodeOptions(), { value: phoneCode });
  countryField.set("value", getOption);
};
