import { computed, observable } from "mobx";
import { permissionApiStore } from "src/components/Login/stores/permissionApiStore";
import { Message } from "src/components/Message/Message";
import { routerStore } from "src/stores/RouterStore";
import { APP_LOGIN } from "src/constants/routes";
import { BUSINESS_USER_DASHBOARD } from "src/container/BusinessUser/constants/businessUserRoutes";
import { CANDIDATE_DASHBOARD } from "src/container/Candidate/constants/candidateRoutes";

export enum Roles {
  SUPER_ADMIN = "SUPER_ADMIN",
  BUSINESS_USER = "BUSINESS_USER",
  CANDIDATE = "CANDIDATE"
}

class PermissionStore {
  @observable
  roles: string[] = Object.values(Roles);

  @computed
  get isBusinessUser() {
    return (
      permissionApiStore.hasList &&
      permissionApiStore.data.roles.includes(Roles.BUSINESS_USER)
    );
  }

  @computed
  get isCandidate() {
    return (
      permissionApiStore.hasList &&
      permissionApiStore.data.roles.includes(Roles.CANDIDATE)
    );
  }

  @computed
  get isSuperAdmin() {
    return (
      permissionApiStore.hasList &&
      permissionApiStore.data.roles.includes(Roles.SUPER_ADMIN)
    );
  }

  redirectAccordingToRole = async () => {
    try {
      await permissionApiStore.callApiIfEmpty();
      if (this.isBusinessUser || this.isSuperAdmin) {
        routerStore.push(BUSINESS_USER_DASHBOARD);
      } else if (this.isCandidate) {
        routerStore.push(CANDIDATE_DASHBOARD);
      } else {
        Message.error("No roles found for the user.");
        routerStore.push(APP_LOGIN);
      }
    } catch (e) {
      console.error(e);
      routerStore.push(APP_LOGIN);
    }
  };

  clearStore = () => {
    permissionApiStore.successResponse = [];
  };
}

export const permissionStore = new PermissionStore();
