import React from "react";
import ReactDOM from "react-dom";
import "./main.scss";
import * as serviceWorker from "./serviceWorker";
import App from "src/App";


const root = document.createElement("div");
document.body.appendChild(root);
document.body.classList.add("defaultLight");

ReactDOM.render(<App />, root);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
