import { action, computed, observable } from "mobx";
import { routerStore } from "src/stores/RouterStore";
import { Message } from "src/components/Message/Message";
import * as _ from "lodash";
import { APP_LOGIN } from "src/constants/routes";
import { profilePhotoFormStore } from "src/container/Candidate/stores/profilePhotoFormStore";
import { candidateDetailsFormStore } from "src/container/Candidate/stores/candidateDetailsFormStore";
import { resumeUploadFormStore } from "src/container/Candidate/stores/resumeUploadFormStore";
import { CANDIDATE_DASHBOARD } from "src/container/Candidate/constants/candidateRoutes";
import { createCandidateApiByTokenStore } from "src/container/Candidate/stores/createCandidateByTokenApiStore";
import { authStore } from "src/stores/AuthStore";

export enum Steps {
  STEP1 = 1,
  STEP2 = 2,
  STEP3 = 3
}

class CandidateRegistrationStore {
  @observable
  step: Steps = 1;

  @observable
  isCompleted: boolean = false;

  @action
  setStep = (step: Steps) => {
    this.step = step;
  };

  @action
  setIsCompleted = (bool: boolean) => {
    this.isCompleted = bool;
  };

  @computed
  get isStepValidated() {
    switch (this.step) {
      case Steps.STEP1:
        return profilePhotoFormStore.isValid;
      case Steps.STEP2:
        return candidateDetailsFormStore.isValid;
      case Steps.STEP3:
        return resumeUploadFormStore.isValid;
    }
  }

  @action
  onClickNext = () => {
    if (this.step !== Steps.STEP3) {
      this.setStep(this.step + 1);
    } else {
      this.submitRegister();
    }
  };

  @action
  onClickBack = () => {
    if (this.step === Steps.STEP1) {
      routerStore.push(APP_LOGIN);
    } else {
      this.setStep(this.step - 1);
    }
  };

  @computed
  get getProgressPercentage() {
    return this.isCompleted ? 100 : Math.floor((this.step - 1) * (100 / 3));
  }

  submitRegister = async () => {
    this.setIsCompleted(true);

    await _.delay(async () => {
      try {
        const profilePhotoFormValues = profilePhotoFormStore.values();
        const candidateDetailsFormValues = candidateDetailsFormStore.values();
        const resumeUploadFormValues = resumeUploadFormStore.values();

        await createCandidateApiByTokenStore.callApiWithFormData({
          first_name: candidateDetailsFormValues.first_name,
          last_name: candidateDetailsFormValues.last_name,
          email: candidateDetailsFormValues.email,
          password: candidateDetailsFormValues.password,
          password_confirmation:
            candidateDetailsFormValues.password_confirmation,
          country_code: candidateDetailsFormValues.country_code.value,
          contact_no: candidateDetailsFormValues.contact_no,
          profile_image:
            profilePhotoFormValues.profile_image &&
            profilePhotoFormValues.profile_image,
          token: candidateDetailsFormValues.token,
          resume: resumeUploadFormValues.resume && resumeUploadFormValues.resume
        });

        authStore.setToken(createCandidateApiByTokenStore.data.token);

        // Succeed registering user
        Message.success("Successfully registered");

        routerStore.push(CANDIDATE_DASHBOARD);
      } catch (e) {
        console.error("Error registering: ", e);
        Message.error("Error registering user.");
        this.setIsCompleted(false);
      }
    }, 600);
  };
}

export const candidateRegistrationStore = new CandidateRegistrationStore();
