import * as React from 'react';
import { ToggleSwitch } from './ToggleSwitch';

interface SwitchExampleProps {}

export const ToggleSwitchExample: React.FC<SwitchExampleProps> = props => {
  const onChange = e => {
    console.log(e);
  };
  return (
    <div className={'d-flex'}>
      <span className={'mr-5'}>
        <div>Uncheked</div>
        <ToggleSwitch onChange={onChange} />
      </span>
      <span className={'mr-5'}>
        <div>Disabled</div>
        <ToggleSwitch disabled={true} onChange={onChange} />
      </span>
      <span className={'mr-5'}>
        <div>Checked</div>
        <ToggleSwitch checked={true} onChange={onChange} />
      </span>
      <span className={'mr-5'}>
        <div>Always Checked Theme</div>
        <ToggleSwitch checked={true} onChange={onChange} enableCheckedTheme={true}/>
      </span>
    </div>
  );
};
