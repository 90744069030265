import * as React from "react";
import {
  CandidateProgressBarSvg,
  CandidateProgressBarSvgProps
} from "src/components/CandidateProgressBar/CandidateProgressBarSvg";

interface CandidateProgressBarProps {
  candidateStatus?: CandidateProgressBarSvgProps[];
}

export const CandidateProgressBar: React.FC<CandidateProgressBarProps> = props => {
  const { candidateStatus } = props;

  return (
    <div className={`d-flex`}>
      {candidateStatus &&
        candidateStatus.map(item => {
          return (
            <CandidateProgressBarSvg
              status={item.status}
              complete={item.complete}
            />
          );
        })}
    </div>
  );
};
