import * as React from 'react';
import * as style from './countColumn.module.scss';

export interface CountColumnProps {
  title?: string;
  count?: number;
}

export const CountColumn: React.FC<CountColumnProps> = (props) => {
  return (
   <div className={style.countColumn}>
     <div className={style.countText}>
       {props.count}
     </div>
     <div className={style.titleText}>
       {props.title}
     </div>
   </div>
  );
};
