import * as React from "react";
import * as style from "./noData.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxOpen } from "@fortawesome/free-solid-svg-icons/faBoxOpen";
import { Card } from "src/components/Card/Card";

interface NoDataProps {
  className?: string;
  title?: string;
  icon?: Element | React.ReactNode | any;
  classNameIcon?: string;
  classNameTitle?: string;
  showCard?: boolean;
}

export const NoData: React.FC<NoDataProps> = props => {
  const renderContent = () => {
    return (
      <div
        className={`d-flex flex-column justify-content-center align-items-center w-100 h-100 ${style.noData} ${props.className}`}
      >
        <FontAwesomeIcon
          icon={props.icon ? props.icon : faBoxOpen}
          fixedWidth
          className={`${style.boxIcon} ${props.classNameIcon &&
            props.classNameIcon}`}
        />
        <div
          className={`${style.title} ${props.classNameTitle &&
            props.classNameTitle}`}
        >
          {props.title ? props.title : `No data found..`}
        </div>
      </div>
    );
  };

  return props.showCard ? (
    <Card className={`w-100 h-100`}>{renderContent()}</Card>
  ) : (
    renderContent()
  );
};
