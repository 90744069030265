import * as React from 'react';
import * as style from './errorText.module.scss';

interface ErrorTextProps {

}

export const ErrorText: React.FC<ErrorTextProps> = (props) => {
  return (
   <div className={style.error}>
     {props.children}
   </div>
  );
};
