import * as React from "react";
import * as style from "./projectOverview.module.scss";
import {
  WaveProgressBar,
  WaveProgressBarProps
} from "src/components/WaveProgressBar/WaveProgressBar";
import { Loading } from "src/components/Loading/Loading";

export interface ProjectOverviewProps extends WaveProgressBarProps {
  title?: string;
  subtitle?: string;
  isLoading?: boolean;
}

export const ProjectOverview: React.FC<ProjectOverviewProps> = props => {
  const { title, subtitle, ...otherProps } = props;
  return (
    <div
      className={`${style.projectOverview} d-flex h-100 w-100 justify-content-between px-2 py-2`}
    >
      {props.isLoading ? (
        <Loading isLoading={true} />
      ) : (
        <>
          <div className={`d-flex align-items-center`}>
            <div className={style.firstLetterContainer}>
              <span className={style.letter}>{title && title[0]}</span>
            </div>
            <div className={style.titleContainer}>
              <div className={style.title}>{title}</div>
              <div className={style.subtitle}>{subtitle}</div>
            </div>
          </div>
          <div>
            <WaveProgressBar showProgress={true} size={80} {...otherProps} />
          </div>
        </>
      )}
    </div>
  );
};
