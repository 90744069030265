import * as React from "react";
import * as style from "./stepsForm.module.scss";
import { LoginContainer } from "src/components/Login/LoginContainer";
import { Input } from "src/components/Input/Input";
import { routerStore } from "src/stores/RouterStore";
import {
  BUSINESS_USER_DASHBOARD,
  BUSINESS_USER_REGISTER
} from "src/container/BusinessUser/constants/businessUserRoutes";
import { observer } from "mobx-react";
import { loginFormStore } from "src/components/Login/stores/loginFormStore";
import { useState } from "react";
import { Message } from "src/components/Message/Message";
import * as _ from "lodash";
import { APP_RECOVERY } from "src/constants/routes";
import { permissionStore } from "src/stores/PermissionStore";
import {useEnterKeyPress} from "src/utils/form";

interface LoginProps {}

export const Login: React.FC<LoginProps> = observer(props => {
  const [loginCompleted, setLoginCompleted] = useState<boolean>(false);

  const emailField = loginFormStore.$("email");
  const passwordField = loginFormStore.$("password");


  const submitLogin = async () => {
    setLoginCompleted(true);
    await _.delay(async () => {
      try {
        await loginFormStore.submit();
        await permissionStore.redirectAccordingToRole();
      } catch (e) {
        console.error(e);
        setLoginCompleted(false);
        Message.error("Failed to login.");
      }
    }, 600);
  };

  return (
    <LoginContainer
      progressText={`Please login to your account`}
      progressValue={loginCompleted && 100}
      isLoading={loginCompleted}
      primaryText={`Login`}
      primaryBtnOnProps={{
        disabled: !loginFormStore.isValid,
        onClick: submitLogin
      }}
      secondaryText={`Sign Up`}
      secondaryBtnOnProps={{
        onClick: () => routerStore.push(BUSINESS_USER_REGISTER)
      }}
    >
      <div className={style.content}>
        <Input {...emailField.bind()} showAsterisk={false}/>
        <Input
          {...passwordField.bind()}
          rightAppendAddon={
            <div
              className={style.forgotPasswordText}
              onClick={() => routerStore.push(APP_RECOVERY)}
            >
              Forgot Password?
            </div>
          }
          showAsterisk={false}
        />
      </div>
    </LoginContainer>
  );
});
