import * as React from 'react';
import * as style from './customLabelButton.module.scss';

interface CustomLabelButtonProps {

}

export const CustomLabelButton: React.FC<CustomLabelButtonProps> = (props) => {
  return (
   <div className={style.customLabelButton}>
     {props.children}
   </div>
  );
};
