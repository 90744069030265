import { action, computed, observable, toJS } from 'mobx';
import { generatePath, match } from 'react-router';
import { History, Location } from 'history';
import * as qs from 'qs';
import * as _ from 'lodash';

class RouterStore {
  @observable location: Location;
  @observable match: match;
  @observable history: History;
  @action setRoute = (location: Location, match: match, history: History) => {
    this.location = location;
    this.match = match;
    this.history = history;
  };

  @computed
  get params() {
    return _.get(toJS(this.match), 'params', null) as any;
  }

  @computed
  get queryString() {
    return qs.parse(this.location.search.replace('?', ''));
  }

  push = (path: string, params?: {}) => {
    const generatedPath = params ? generatePath(path, params) : path;
    this.history.push(generatedPath);
  };

  replace = (path: string, params?: {}) => {
    const generatedPath = params ? generatePath(path, params) : path;
    this.history.replace(generatedPath);
  };

  pushWithQueryString = (
    path: string,
    config: {
      params?: {};
      queryString?: {};
    }
  ) => {
    const generatedPath = config.params ? generatePath(path, config.params) : path;
    this.history.push({
      pathname: generatedPath,
      search: `?${qs.stringify(config.queryString)}`
    });
  };
}

export const routerStore = new RouterStore();
