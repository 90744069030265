import * as React from "react";
import * as style from "../../../../components/Login/stepsForm.module.scss";
import { SectionHeader } from "src/components/SectionHeader/SectionHeader";
import { UploadImage } from "src/components/UploadImage/UploadImage";
import { userDetailsFormStore } from "src/container/BusinessUser/components/RegistrationPage/store/userDetailsFormStore";
import { Input } from "src/components/Input/Input";
import { Select } from "src/components/Select/Select";
import { observer } from "mobx-react-lite";
import {
  countryCodeOptions,
  getCountryCodeByTimezone,
  setCountryCodeAccordingToTz
} from "src/utils/countries";
import { useEffectAsync } from "src/utils/hooks";
import { generateSelectOption } from "src/utils/select";
import * as _ from "lodash";
import { InputPassword } from "src/components/Input/InputPassword";
import { toJS } from "mobx";

interface UserDetailsStepsProps {}

export const UserDetailsStep: React.FC<UserDetailsStepsProps> = observer(
  props => {
    const clientLogoField = userDetailsFormStore.$("logo");
    const firstNameField = userDetailsFormStore.$("first_name");
    const lastNameField = userDetailsFormStore.$("last_name");
    const emailField = userDetailsFormStore.$("email");
    const countryField = userDetailsFormStore.$("country_code");
    const phoneNumberField = userDetailsFormStore.$("contact_no");
    const passwordField = userDetailsFormStore.$("password");
    const confirmPasswordField = userDetailsFormStore.$(
      "password_confirmation"
    );

    useEffectAsync(() => {
      // Setting the country code automatically through timezone
      setCountryCodeAccordingToTz(countryField);
    }, []);


    return (
      <>
        <UploadImage formField={clientLogoField} />

        <SectionHeader title={`Create an account to view project`}>
          <div className={`${style.splitSection}`}>
            <Input className={style.inputStyle} {...firstNameField.bind()} />
            <Input className={style.inputStyle} {...lastNameField.bind()} />
          </div>

          <Input {...emailField.bind()} />

          <div className={`d-flex`}>
            <Select
              borderLess={true}
              className={style.countryCode}
              {...countryField.bind()}
              options={countryCodeOptions()}
            />
            <Input {...phoneNumberField.bind()} />
          </div>
        </SectionHeader>

        <SectionHeader title={`Create your account password`}>
          <InputPassword {...passwordField.bind()} />
          <InputPassword {...confirmPasswordField.bind()} />
        </SectionHeader>
      </>
    );
  }
);
