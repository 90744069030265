import { FormStore } from 'src/stores/FormStore';
import {getForgotPasswordApiStore} from "src/components/Login/stores/getForgotPasswordApiStore";

interface ForgotPasswordFormStoreData {
email: string;
}

export const forgotPasswordFormStore = new FormStore<ForgotPasswordFormStoreData>(
  {
    email: {
      label: 'Email',
      placeholder: 'Please enter your existing email',
      rules: 'required|email',
      type: 'email',
      bindings: 'extended'
    },
  },
  {
    options: {
      validateOnChange: true
    },
    hooks: {
      onSuccess: async (form: FormStore) => {
        const values: ForgotPasswordFormStoreData = form.values();
        try {
          await getForgotPasswordApiStore.callApi({
            email: values.email
          });
          form.reInit();
        } catch (e) {
          console.error(`ForgotPasswordFormStore Api Failed: `, e);
          return Promise.reject(`Error calling api`);
        }

        return Promise.resolve();
      },
      onError: form => {
        return Promise.reject(`Incorrect form information`);
      }
    }
  }
);
